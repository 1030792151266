// @vendors
import React from 'react'
import { NavLink } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

// @constants
import { PATH_NAME as clientPathName } from '../../../pages/client/constant'

// @styles
import styles from '../styles.module.sass'

const ClientAppNavigationOptions = () => (
  <>
    <Typography className={styles.item} variant="subtitle1" noWrap>
      <NavLink activeStyle={{ fontWeight: 900 }} to={`${clientPathName}/carriers`}>
        My Carriers
      </NavLink>
    </Typography>
    <Typography className={styles.item} variant="subtitle1" noWrap>
      <NavLink activeStyle={{ fontWeight: 900 }} to={`${clientPathName}/drivers`}>
        My Drivers
      </NavLink>
    </Typography>
    <Typography className={styles.item} variant="subtitle1" noWrap>
      <NavLink activeStyle={{ fontWeight: 900 }} to={`${clientPathName}/facilities`}>
        My Facilities
      </NavLink>
    </Typography>

    <div className={styles.space} />
  </>
)

export default ClientAppNavigationOptions
