// @utils
import { buildActionType, buildAsyncActionType } from '../../../../store/utils'

const MODULE_NAME = 'steps'

export const REQUEST_STEP_DETAILS = buildAsyncActionType(MODULE_NAME, 'REQUEST_STEP_DETAILS')

export const RESET_STEP_FETCH_STATUS = buildActionType(MODULE_NAME, 'RESET_STEP_FETCH_STATUS')

export const anheuserBuschId = '20048c40-b215-4b6c-8ad3-e49bc667470b'
