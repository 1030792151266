// @vendors
import React, { useEffect, useState, Fragment } from 'react'
import { compact } from 'lodash'

// @components
import Section from './section'

const Sections = ({ sections: sectionsProps, isDisabled, requiredControls, setAllowContinue, enableToHidden }) => {
  const [sections, setSections] = useState([])

  useEffect(() => {
    if (enableToHidden) {
      const sectionsUpdated = compact(
        sectionsProps.map((section, i) => {
          if (section.key !== 'trailerInfoPickUp') return section
        })
      )

      setSections(sectionsUpdated)
    } else {
      setSections(sectionsProps)
    }
  }, [sectionsProps])

  return sections.map((section, i) => (
    <Fragment key={i}>
      <Section
        enableToHidden={enableToHidden}
        isDisabled={isDisabled}
        section={section}
        requiredControls={requiredControls}
        setAllowContinue={setAllowContinue}
      />
    </Fragment>
  ))
}

export default Sections
