// @constants
import * as ticketConstants from './constant'
import * as carrierTicketConstants from '../../../../common-components/steps/constant'
import * as ticketListConstants from '../../../../common-components/tickets-list/constant'
import { isDroppingOffAndPickingUp, addHiddenAttrbByKey } from '../../../../utils'
import { LOG_OUT } from '../../../../security/constant'

const initialState = { fetched: false, ticketId: null, hasBeenCreated: false }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ticketConstants.GET_NEW_CARRIER_TICKET_SUCCESS: {
      return { ...action.payload, fetched: true, hasBeenCreated: false }
    }

    case ticketConstants.GET_CARRIER_TICKET_SUCCESS: {
      return { ...state, ...action.payload, hasBeenCreated: true }
    }

    case carrierTicketConstants.SAVE_TICKET_SUCCESS: {
      return { ...state, hasBeenCreated: true }
    }

    case carrierTicketConstants.CREATE_TICKET_SUCCESS: {
      return { ...state, hasBeenCreated: true }
    }

    case carrierTicketConstants.ASSIGN_TICKET_TO_DRIVER_SUCCESS: {
      return { ...state, ...action.payload }
    }

    case ticketConstants.GET_CARRIER_TICKET_DETAILS_SUCCESS: {
      return { ...state, ...action.payload }
    }

    case ticketConstants.GET_QR_CODE_SUCCESS: {
      return { ...state, ...action.payload }
    }

    case ticketListConstants.GET_TICKET_LIST_SUCCESS: {
      return initialState
    }

    case LOG_OUT: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default reducer
