// @vendors
import React from 'react'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as AccountCircle } from '../assets/account-circle.svg'

// @components
import RenderMenuOptions from './navigation-bar-options'

// @styles
import styles from '../styles.module.sass'

const MobileNavigationBar = ({ userLogged, handleMenuOptions, handleMenuClose }) => {
  const urlPath = userLogged && userLogged.profile

  return (
    <div className={styles.mobileOptions} onClick={handleMenuOptions}>
      <div className={styles.avatarWrapper}>
        {userLogged && userLogged.avatar ? <Avatar alt="profile" src={userLogged.avatar} className={styles.avatar} /> : <AccountCircle />}
        <Typography className={styles.name} variant="subtitle1" noWrap>
          <Link to={`/${urlPath}/profile`}>{`${userLogged.firstName} ${userLogged.lastName}`}</Link>
        </Typography>
      </div>

      {urlPath === 'driver' && (
        <Typography variant="subtitle1" noWrap>
          <Link to={`/${urlPath}/profile`}>My Account</Link>
        </Typography>
      )}

      {RenderMenuOptions(urlPath)}

      <Button className={styles.logoutBtn} onClick={handleMenuClose('logout')}>
        <Typography variant="subtitle1">Log Out</Typography>
      </Button>
    </div>
  )
}

export default MobileNavigationBar
