// @vendors
import axios from 'axios'

// @constants
import * as constants from './constant'

// @utils
import httpConfig from '../../http/config'
import { standardRequestHandler, standardSuccessHandler, standardErrorHandler } from '../../http/utils'

const { apiServer } = httpConfig

export const fetchTheme = () => (dispatch, getState) => {
  const { clientId } = getState().clientData
  standardRequestHandler(dispatch, constants.REQUEST_THEME.REQUESTED)

  axios
    .get(`${apiServer.url}/api/Clients/${clientId}/settings`)
    .then(res => standardSuccessHandler(dispatch, constants.REQUEST_THEME.SUCCESS, res.data))
    .catch(err => standardErrorHandler(dispatch, err, constants.REQUEST_THEME.ERROR, 'Error Fetching Theme, will use default.'))
}

export const resetThemeFetchStatus = () => ({
  type: constants.RESET_THEME_FETCH_STATUS
})
