// @vendors
import { connect } from 'react-redux'

// @actions
import { getJwt, notifyLoginToAcceptInvite } from '../../actions'
import { acceptInvitation } from '../../../common-components/accept-invitation/actions'

// @components
import SignIn from './sign-in'

const mapStateToProps = (state, ownProps) => {
  const { security } = state
  const { search } = ownProps.location

  const params = (search && search.split('&')) || ''
  const email = search && search ? params[1].split('=')[1] : ''
  const inviteId = search && search ? params[3].split('=')[1] : ''
  const acceptToken = search && search ? params[0].split('=')[1] : ''

  return {
    security,
    acceptToken,
    inviteId,
    email
  }
}

const mapDispatchToProps = {
  getJwt,
  acceptInvitation,
  notifyLoginToAcceptInvite
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
