import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core'

// @styles
import styles from './styles.module.sass'

const AssignmentConfirmDialog = ({ driver, ticketNumbers, AssignTcketsHandler, isOpen, onClose }) => {
  return (
    <Dialog disableEscapeKeyDown open={isOpen} onClose={onClose} maxWidth="sm" fullWidth className={styles.modalContainer}>
      <DialogTitle>Confirm Assignment</DialogTitle>
      <DialogContent dividers>
        {ticketNumbers ? (
          <>
            <Typography variant="body1" align="center">
              Are you sure you want to assign the tickets:
            </Typography>
            <Typography variant="body1" align="center">
              {ticketNumbers.join(' - ')}
            </Typography>
            <Typography variant="body1" align="center">{`to ${driver.firstName} ${driver.lastName}?`}</Typography>
          </>
        ) : (
          <Typography variant="body1" align="center">
            Are you sure you want to assign the ticket to {`${driver.firstName} ${driver.lastName}`} ?
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={AssignTcketsHandler}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AssignmentConfirmDialog
