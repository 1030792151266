// @vendors
import { connect } from 'react-redux'

// @components
import CardForm from './card-form'

const mapStateToProps = state => ({
  fetchingTheme: state.theming.fetching,
  fetchedTheme: state.theming.fetched,
  themingClientData: state.theming.data
})

export default connect(mapStateToProps)(CardForm)
