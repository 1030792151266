// @vendors
import React from 'react'
import { Redirect } from 'react-router-dom'

// @utils
import useMount from '../../../hooks/use-mount'
import withMissingPropsRedirect from '../../../utils/redirection/with-missing-props-redirection'

// @constants
import { PATH_NAME } from '../constant'
import { unauthorizedRedirect } from '../../../utils/redirection/constant'

const ClientLoader = () => {
  useMount(() => {})

  return <Redirect to={`${PATH_NAME}/facilities`} />
}

export default withMissingPropsRedirect([unauthorizedRedirect], ClientLoader)
