// @vendors
import React, { useEffect } from 'react'
import GridList from '@material-ui/core/GridList'
import Typography from '@material-ui/core/Typography'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'

// @styles
import styles from './styles.module.sass'

const ImageSelection = ({ control, isDisabled, onHandleChange, currentValue }) => {
  const { data, label } = control

  const handleClick = value => event => {
    event.preventDefault()

    if (onHandleChange && !isDisabled) {
      onHandleChange(value)
    }
  }

  const mainGridListClassName = control.mobile === 'column' ? `${styles.cardContainer} ${styles.column}` : styles.cardContainer

  useEffect(() => {
    if (currentValue) {
      onHandleChange(currentValue)
    }
  }, [currentValue])

  return (
    <>
      <Typography variant="h5" component="h4" className={styles.labelControl}>
        {label}
      </Typography>
      {data && (
        <GridList className={mainGridListClassName}>
          {data.map(card => {
            const isSelected = card.value === currentValue
            const imageSource = isSelected ? card.selectedImageUrl : card.imageUrl

            return (
              <GridListTile
                key={card.value}
                className={styles.card}
                classes={{
                  tile: label ? styles.itemWrapper2 : styles.itemWrapper
                }}
                onClick={handleClick(card.value)}
              >
                <span className={styles.itemImageContainer}>
                  <img src={imageSource} alt={card.label} className={styles.itemImg} />
                </span>
                <GridListTileBar
                  title={card.label}
                  className={isSelected ? `${styles.itemLabel} ${styles.selected}` : styles.itemLabel}
                  classes={{ title: styles.itemLabelWrapper }}
                />
              </GridListTile>
            )
          })}
        </GridList>
      )}
    </>
  )
}

export default ImageSelection
