// @vendors
import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

// @components
import LoginFooter from './components/login-footer'
import CardForm from '../../../common-components/card-form'
import PasswordInputIcon from '../password-reset/components/password-input-icon'

// @constants
import { signInInitialState, EMAIL_HELPER_TEXT, ERROR_MESSAGE } from './constant'

// @utils
import useMount from '../../../hooks/use-mount'
import { emailFormatValidation } from '../../../utils/field-value-validation'
import { getLoginRedirection } from '../../../utils/redirection/login-redirection'

//@styles
import './styles.scss'

const SignIn = ({ acceptInvitation, acceptToken, email, getJwt, inviteId, notifyLoginToAcceptInvite, security }) => {
  const [state, setState] = useState(signInInitialState(email))
  const [helperText, setHelperText] = useState(EMAIL_HELPER_TEXT)

  useMount(() => {
    if (acceptToken) {
      notifyLoginToAcceptInvite()
    }
  })

  useEffect(() => {
    if (security && acceptToken && email && inviteId) {
      ;(async () => {
        await acceptInvitation(inviteId, acceptToken, email)
      })()
    }
  }, [acceptInvitation, acceptToken, email, inviteId, security])

  const handleChange = name => event => {
    const value = event.target.value
    let valid = event.target.checkValidity()

    if (name === 'username') {
      valid = emailFormatValidation(value)

      if (!valid) {
        event.target.setCustomValidity(EMAIL_HELPER_TEXT[name])
        setHelperText({
          email: ERROR_MESSAGE
        })
      } else {
        event.target.setCustomValidity('')
        setHelperText({ ...helperText, [name]: null })
      }
    }

    setState({
      ...state,
      [name]: { value, valid, pristine: false }
    })
  }

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword })
  }

  const showErrors = () => {
    setState({
      ...state,
      username: { ...state.username, pristine: false },
      password: { ...state.password, pristine: false }
    })
  }

  const handleSubmit = event => {
    const { password, username } = state
    event.preventDefault()

    if (username.valid && username.value && password.value && password.valid) {
      getJwt(username.value, password.value)
    } else {
      showErrors()
    }
  }

  if (security && security.Profile) {
    return <Redirect to={getLoginRedirection(security.Profile)} />
  }

  const isValidUsername = state.username.value && state.username.valid
  const isValidPassword = state.password.value && state.password.valid
  const shouldDisableLoginButton = !isValidUsername || !isValidPassword

  return (
    <CardForm header="Welcome Back!">
      <section className="login-form">
        <TextField
          disabled={!!email}
          error={!state.username.valid && !state.username.pristine}
          helperText={helperText.email}
          id="username"
          InputProps={{
            classes: {
              input: 'login-form__username'
            }
          }}
          label="Username"
          onChange={handleChange('username')}
          required
          value={state.username.value}
        />

        <FormControl className="login-form__password-container">
          <InputLabel htmlFor="password" className="login-form__password-label">
            Password*
          </InputLabel>
          <Input
            error={!state.password.valid && !state.password.pristine}
            endAdornment={<PasswordInputIcon handleClickShowPassword={handleClickShowPassword} showPassword={state.showPassword} />}
            id="password"
            inputProps={{
              className: 'login-form__password'
            }}
            onChange={handleChange('password')}
            required
            type={state.showPassword ? 'text' : 'password'}
            value={state.password.value}
          />
        </FormControl>

        <LoginFooter handleLogin={handleSubmit} shouldDisableLoginButton={shouldDisableLoginButton} />
      </section>
    </CardForm>
  )
}

export default SignIn
