// @vendors
import React from 'react'
import { Route } from 'react-router-dom'

// @components
import ClientLoader from './client-loader'
import ClientProfile from './client-profile'
import CarrierList from './carriers/list'
import Facilities from './facilities'
import FacilityForm from './facilities/components/facility-form'
import Drivers from './drivers/list'

// @constants
import { PATH_NAME } from './constant'

const ClientApp = () => [
  <Route exact key={`${PATH_NAME}`} path={`${PATH_NAME}`} component={ClientLoader} />,
  <Route key={`${PATH_NAME}/carriers`} exact path={`${PATH_NAME}/carriers`} component={CarrierList} />,
  <Route key={`${PATH_NAME}/profile`} exact path={`${PATH_NAME}/profile`} component={ClientProfile} />,
  <Route key={`${PATH_NAME}/drivers`} exact path={`${PATH_NAME}/drivers`} component={Drivers} />,

  <Route key={`${PATH_NAME}/facilities`} exact path={`${PATH_NAME}/facilities`} component={Facilities} />,
  <Route key={`${PATH_NAME}/facilities/new`} exact path={`${PATH_NAME}/facilities/new`} component={FacilityForm} />,
  <Route exact key={`${PATH_NAME}/facilities/:facilityId/edit`} path={`${PATH_NAME}/facilities/:facilityId/edit`} component={FacilityForm} />
]

export default ClientApp
