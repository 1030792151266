// @vendors
import axios from 'axios'

// @constants
import * as constants from './constant'

// @utils
import httpConfig from '../../../http/config'
import {
  standardRequestHandler,
  standardSuccessHandler,
  standardErrorHandler,
  standardErrorMessageHandler,
  createApiRequestHeaders
} from '../../../http/utils'

const { securityServer } = httpConfig

export const updateClientPassword = ({ Id: id, oldPassword, newPassword, access_token: token, token_type: tokenType }) => (dispatch, getState) => {
  const { clientId } = getState().clientData
  const config = {
    headers: createApiRequestHeaders(token, tokenType)
  }

  standardRequestHandler(dispatch, constants.UPDATE_CLIENT_PASSWORD.REQUESTED)

  const postParams = {
    newPassword,
    oldPassword,
    clientId: clientId
  }

  if (oldPassword === newPassword) {
    return standardErrorMessageHandler(dispatch, 'Old Password and New Password could not be the same')
  }

  return axios
    .post(`${securityServer.url}/api/User/${id}/ChangePassword`, postParams, config)
    .then(res => {
      standardSuccessHandler(dispatch, constants.UPDATE_CLIENT_PASSWORD.SUCCESS, res.data, 'Client password updated successfully.')
    })
    .catch(err => {
      standardErrorHandler(
        dispatch,
        err,
        constants.UPDATE_CLIENT_PASSWORD.ERROR,
        `Could not update the client password, ${err.response.data.Message}`
      )
    })
}
