// @vendors
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// @components
import AssignDriverBar from './assign-driver-bar'

// @selectors
import { getDriverFromTicket, getDriversFromCarrier } from '../../../../common-components/tickets-list/components/assign/selectors'

// @actions
import { assignDriver } from '../../../../common-components/tickets-list/components/assign/actions'
import { requestDrivers } from '../../driver-list/actions'
import { getForm } from '../../../driver/driver-tickets/ticket/selectors'

const mapStateToProps = state => ({
  driver: getDriverFromTicket(state) || getForm(state).driver,
  drivers: getDriversFromCarrier(state)
})
const mapDispatchToProps = { requestDrivers, assignDriver }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssignDriverBar))
