// @vendors
import { connect } from 'react-redux'

// @components
import Control from './control'

// @utils
import { getFormValues } from '../../../driver-tickets/ticket-values/selectors'
// @actions
import { updateFormValue } from '../../../driver-tickets/ticket-values/actions'
import { setFlag } from '../../../../../store/actions/flags'

const mapStateToProps = state => ({
  formValues: getFormValues(state)
})

const mapDispatchToProps = {
  updateFormValue,
  setFlag
}

export default connect(mapStateToProps, mapDispatchToProps)(Control)
