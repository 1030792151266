// @constants
import * as constants from './constant'
import * as securityConstants from '../../../../security/constant'

const initialState = null

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_QR_CODE_SUCCESS: {
      return { ...action.payload }
    }
    case securityConstants.LOG_OUT: {
      return initialState
    }
    default: {
      return state
    }
  }
}

export default reducer
