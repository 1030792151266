// @vendors
import React, { useEffect } from 'react'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

// @apps
import DriverApp from '../pages/driver'
import CarrierApp from '../pages/carrier'
import ClientApp from '../pages/client'

// @components
import Login from '../security/components/sign-in'
import SignUp, { SignUpPlaceHolder } from '../security/components/sign-up'
import UiShowroom from '../common-components/ui-showroom'
import PasswordReset from '../security/components/password-reset'
import Notifications from '../common-components/notifications'
import PasswordRecovery from '../security/components/password-recovery'
import GlobalBusySpinner from '../common-components/busy-spinner/global-busy-spinner'
import RestrictedRoutes from '../utils/redirection/restricted-routes'
import CarrierSignUp from '../pages/carrier/carrier-signup'

// @utils
import useMount from '../hooks/use-mount'
import { getMuiThemeData } from '../utils/theming/utils'

const Routes = ({ clientData, theming, fetchTheme, resetThemeFetchStatus, security, requestClientData }) => {
  useMount(() => {
    requestClientData()
    if (theming.fetched && !theming.data) {
      resetThemeFetchStatus()
    }
  })

  useEffect(() => {
    if (!theming.fetched && !theming.fetching && clientData.clientId) {
      fetchTheme()
    }
  })

  const setIconsUrl = () => {
    let touchIconUrl
    let faviconUrl
    if (theming && theming.data && theming.data.client) {
      touchIconUrl = theming.data.client.logoUrl
      faviconUrl = theming.data.client.faviconUrl
    }

    if (faviconUrl) {
      const favicon = document.getElementById('favicon')
      favicon.href = faviconUrl
    }
    if (touchIconUrl) {
      const touchIcon = document.getElementById('touch-icon')
      touchIcon.href = touchIconUrl
    }
  }

  let location = useLocation()
  if (!theming.fetched && !clientData.clientId) {
    return <SignUpPlaceHolder />
  } else {
    const theme = createMuiTheme(getMuiThemeData(theming.data))
    setIconsUrl()

    const profile = security && security.Profile && security.Profile.toLowerCase()
    const path = location && `${window.location.host}${location.pathname}`.toLowerCase()

    return (
      <MuiThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/signup" component={SignUp} />
          <Route path="/password-recovery" component={PasswordRecovery} />
          <Route path="/password-reset" component={PasswordReset} />
          <Route path="/carrier-signup" component={CarrierSignUp} />
          <Route exact path="/ui" component={UiShowroom} />
          {security && profile && (
            <>
              <RestrictedRoutes path={path} profile={profile}>
                <DriverApp />
              </RestrictedRoutes>
              <RestrictedRoutes path={path} profile={profile}>
                <CarrierApp />
              </RestrictedRoutes>
              <RestrictedRoutes path={path} profile={profile}>
                <ClientApp />
              </RestrictedRoutes>
              {location.pathname === '/accept' && (
                <Route
                  to="/accept"
                  component={() => (
                    <Redirect to={`/${profile.replace(profile.charAt(0), profile.charAt(0).toUpperCase())}/accept${location.search || ''}`} />
                  )}
                />
              )}
            </>
          )}
          <Route path="*">
            <Redirect to={`/${location.pathname === '/accept' ? location.search : ''}`} />
          </Route>
        </Switch>
        <GlobalBusySpinner />
        <Notifications />
      </MuiThemeProvider>
    )
  }
}

export default Routes
