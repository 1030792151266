// @vendors
import axios from 'axios'

// @utils
import httpConfig from '../../http/config'
import { standardRequestHandler, standardSuccessHandler, standardErrorHandler, createApiRequestHeaders } from '../../http/utils'

// @constants
import * as constants from './constant'
import { formatFiltersToParams } from '../../utils'

const { apiServer } = httpConfig

export const getTickets = (page, pageSize) => (dispatch, getState) => {
  const state = getState()
  const { clientId } = state.clientData
  const { access_token: token, token_type: tokenType, Id: id, Profile: profile } = state.security
  const { tickets } = state

  const { Type, Status } = formatFiltersToParams(tickets && tickets.filters)
  const defaultPage = 1
  const defaultPageSize = tickets && tickets.pager.pageSize

  const config = {
    headers: createApiRequestHeaders(token, tokenType),
    params: {
      ClientId: clientId,
      Page: page || defaultPage,
      PageSize: pageSize || defaultPageSize,
      Type,
      Status
    }
  }

  standardRequestHandler(dispatch, constants.GET_TICKET_LIST_REQUEST)

  return axios
    .get(`${apiServer.url}/api/${profile.toLowerCase() === 'driver' ? 'Drivers' : 'Carriers'}/${id}/tickets`, config)
    .then(res => {
      standardSuccessHandler(dispatch, constants.GET_TICKET_LIST_SUCCESS, {
        ...res.data,
        profile
      })
    })
    .catch(err => {
      standardErrorHandler(dispatch, err, constants.GET_TICKET_LIST_ERROR, constants.ERROR_FETCHING_TICKETS)
    })
}

export const deleteTicket = ticketNumber => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType } = getState().security

  const config = {
    headers: createApiRequestHeaders(token, tokenType)
  }

  standardRequestHandler(dispatch, constants.DELETE_TICKET_REQUEST, {
    ticketNumber
  })

  return axios
    .delete(`${apiServer.url}/api/Tickets/${ticketNumber}`, config)
    .then(res => {
      standardSuccessHandler(dispatch, constants.DELETE_TICKET_SUCCESS, { ticketNumber }, constants.TICKET_DELETED_SUCCESSFULLY)
    })
    .catch(err => {
      standardErrorHandler(dispatch, err, constants.DELETE_TICKET_ERROR, constants.ERROR_DELETING_TICKET)
    })
}

export const setFilters = filters => dispatch => {
  dispatch({ type: constants.SET_FILTERS, payload: filters })
}
