// @vendors
import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'

// @components
import UserProfileMenuItem from './components/user-menu-options'
import MobileNavigationBar from './components/mobile-navigation-bar'
import DesktopNavigationBar from './components/desktop-navigation-bar'

// @styles
import styles from './styles.module.sass'

const NavigationBar = ({ driver, carrier, client, logout, themingClientData, classes, user, resetCarrier, resetDriver }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const menuOptionsEl = useRef(null)
  const iconEl = useRef(null)
  const history = useHistory()

  const isMenuOpen = Boolean(anchorEl)
  const menuId = 'primary-search-account-menu'
  const driverExists = driver && driver.id
  const carrierExists = carrier && carrier.id
  const clientExists = client && client.clientId

  const role = {
    Carrier: {
      exist: carrierExists,
      fullName: carrierExists && carrier.carrierName,
      avatar: carrierExists && carrier.avatar,
      profile: carrierExists && 'carrier'
    },

    Driver: {
      exist: driverExists,
      fullName: driverExists && `${driver.firstName} ${driver.lastName}`,
      avatar: driverExists && driver.avatar,
      profile: driverExists && 'driver'
    },

    Client: {
      exist: clientExists,
      fullName: clientExists && client.clientName,
      avatar: clientExists && '',
      profile: clientExists && 'client'
    }
  }

  const userLogged = {
    profile: (user && user.Profile.toLowerCase()) || role[user.Profile].profile,
    firstName: user && user.FirstName,
    lastName: user && user.LastName,
    avatar: role[user.Profile].avatar,
    fullName: role[user.Profile].fullName
  }

  const handleProfileMenuOpen = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = action => event => {
    event.stopPropagation()

    if (action === 'logout') {
      setAnchorEl(null)
      history.push('/')
      resetCarrier()
      resetDriver()
      logout()
    } else if (action === 'close') {
      setAnchorEl(null)
    }
  }

  const handleMenuOptions = event => {
    event.preventDefault()
    menuOptionsEl.current.toggleAttribute('showing')
    iconEl.current.toggleAttribute('hide')
  }

  return (
    <div className={styles.grow} onClick={handleMenuClose('close')}>
      <AppBar position="static" className={(classes && classes.bar) || styles.bar}>
        <div className={styles.iconWrapper} ref={iconEl}>
          <IconButton className={styles.menuIcon} aria-label="open drawer" onClick={handleMenuOptions}>
            <MenuIcon />
          </IconButton>
        </div>

        <Toolbar className={styles.toolbar} ref={menuOptionsEl}>
          <MobileNavigationBar handleMenuClose={handleMenuClose} handleMenuOptions={handleMenuOptions} userLogged={userLogged} />

          <DesktopNavigationBar
            handleProfileMenuOpen={handleProfileMenuOpen}
            menuId={menuId}
            menuOptionsEl={menuOptionsEl}
            themingClientData={themingClientData}
            userLogged={userLogged}
          />
        </Toolbar>
      </AppBar>

      <UserProfileMenuItem
        anchorEl={anchorEl}
        handleMenuClose={handleMenuClose}
        isMenuOpen={isMenuOpen}
        menuId={menuId}
        userProfile={userLogged.profile}
      />
    </div>
  )
}

export default NavigationBar
