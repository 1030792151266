// @constants
import * as constants from './constant'

const initialState = {
  globalBusyCount: 0
}

const globalSpinnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.ADD_TO_GLOBAL_SPINNER: {
      return { ...state, globalBusyCount: state.globalBusyCount + 1 }
    }
    case constants.SUBTRACT_FROM_GLOBAL_SPINNER: {
      return { ...state, globalBusyCount: !state.globalBusyCount ? 0 : state.globalBusyCount - 1 }
    }
    default: {
      return state
    }
  }
}

export default globalSpinnerReducer
