// @vendors
import { connect } from 'react-redux'

// @utils
import { getFormSteps } from './selectors'
import { getSecurity } from '../../../../security'
import { getForm } from '../../driver-tickets/ticket/selectors'
import { getFormValues } from '../../driver-tickets/ticket-values/selectors'

// @actions
import { requestStepDetails, resetStepFetchStatus } from './actions'
import { createTicket, saveTicket, finalizeTicket, setValidStepStatus, notifyFinishTicketError } from '../../../../common-components/steps/actions'
import { setFlag } from '../../../../store/actions/flags'

// @components
import Steps from './steps'

const mapStateToProps = state => ({
  steps: getFormSteps(state),
  security: getSecurity(state),
  formValues: getFormValues(state),
  hasBeenCreated: getForm(state).hasBeenCreated,
  ticketStatus: getForm(state).ticketStatus,
  clientName: state.clientData.clientName,
  flags: state.flags
})

const mapDispatchToProps = {
  requestStepDetails,
  resetStepFetchStatus,
  saveTicket,
  createTicket,
  setValidStepStatus,
  finalizeTicket,
  notifyFinishTicketError,
  setFlag
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps)
