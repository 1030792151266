// @vendors
import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

// @utils
import withMissingPropsRedirect from '../../../utils/redirection/with-missing-props-redirection'

// @constants
import { PATH_NAME } from '../constants'
import { unauthorizedRedirect } from '../../../utils/redirection/constant'

const CarrierLoader = ({ carrier, requestCarrier, requestDrivers, drivers }) => {
  useEffect(() => {
    if (carrier) {
      if (!drivers) requestDrivers()
    } else {
      requestCarrier()
    }
  }, [carrier])

  return carrier ? <Redirect to={`${PATH_NAME}/tickets`} /> : null
}

export default withMissingPropsRedirect([unauthorizedRedirect], CarrierLoader)
