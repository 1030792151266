// @vendors
import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'

// @styles
import styles from './styles.module.sass'

const TicketSidebar = ({ filters, getTickets, setFilters, head }) => {
  const headHasType = head && head.filter(header => header.key === 'Type')
  const { type: currentTypes, status: currentStatus } = filters

  const filterList = [
    { label: 'All', filter: 'ALL' },
    { label: 'Both', filter: 'droppingoff-pickingup' },
    { label: 'Drop off', filter: 'droppingoff' },
    { label: 'Pick up', filter: 'pickingup' }
  ]

  const filterByStatusList = [
    { label: 'All', filter: 'ALL' },
    { label: 'Incomplete', filter: 'Incomplete' },
    { label: 'Ready', filter: 'Ready' },
    { label: 'InProgress', filter: 'InProgress' },
    { label: 'Done', filter: 'Done' }
  ]

  const handleFilterByType = filter => {
    const isChecked = currentTypes.includes(filter)
    let typeList

    if (isChecked) {
      typeList = currentTypes.filter(type => type !== filter)
    } else {
      typeList = [...currentTypes, filter]
    }

    if (typeList.includes('ALL')) {
      setFilters({ type: [] })
    } else {
      setFilters({ type: typeList })
    }

    getTickets()
  }

  const handleFilterByStatus = filter => {
    const isChecked = currentStatus.includes(filter)
    let statusList

    if (isChecked) {
      statusList = currentStatus.filter(status => status !== filter)
    } else {
      statusList = [...currentStatus, filter]
    }

    if (statusList.includes('ALL')) {
      setFilters({ status: [] })
    } else {
      setFilters({ status: statusList })
    }

    getTickets()
  }

  return (
    <List className={styles.sidebarList}>
      <div className={styles.filterByType}>
        <ListItem key="filterByType">
          <Typography component="h4" className={styles.filterByTitle}>
            Filter by type
          </Typography>
        </ListItem>
        <div className={styles.filterOptions}>
          {headHasType ? (
            filterList.map((each, index) => {
              const isAllChecked = !currentTypes.length && each.filter === 'ALL'
              return (
                <ListItem key={index} onClick={() => handleFilterByType(each.filter)}>
                  <Checkbox color="primary" checked={isAllChecked || currentTypes.includes(each.filter)} />
                  <ListItemText primary={each.label} className={styles.filterByLabel} />
                </ListItem>
              )
            })
          ) : (
            <ListItem>
              <Typography variant="body2">Unavailable</Typography>
            </ListItem>
          )}
        </div>
      </div>

      <div className={styles.filterByStatus}>
        <ListItem key="filterBystatus">
          <Typography component="h4" className={styles.filterByTitle}>
            Filter by status
          </Typography>
        </ListItem>
        <div className={styles.filterOptions}>
          {filterByStatusList.map((each, index) => {
            const isAllChecked = !currentStatus.length && each.filter === 'ALL'
            return (
              <ListItem key={`${index}-${each.label}`} onClick={() => handleFilterByStatus(each.filter)}>
                <Checkbox color="primary" checked={isAllChecked || currentStatus.includes(each.filter)} />
                <ListItemText primary={each.label} className={styles.filterByLabel} />
              </ListItem>
            )
          })}
        </div>
      </div>
    </List>
  )
}

export default TicketSidebar
