// @vendors
import React from 'react'
import Box from '@material-ui/core/Box'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

// @components
import InputSelect from '../../pages/driver/components/input-select'
import ImageSelection from '../../pages/driver/components/image-selection'
import InputMultiSelect from '../../pages/driver/components/input-select/input-multi-select'

// @styles
import styles from './styles.module.sass'

const imageMockData = {
  label: 'Select your trailer lenght',
  single: true,
  data: [
    {
      imageUrl: '/images/trailer.svg',
      imageSelectedUrl: '/images/trailerSelected.svg',
      label: '53 cm',
      value: '70'
    },
    {
      imageUrl: '/images/trailer.svg',
      imageSelectedUrl: '/images/trailerSelected.svg',
      label: '70 cm',
      value: '70'
    },
    {
      imageUrl: '/images/trailer.svg',
      imageSelectedUrl: '/images/trailerSelected.svg',
      label: '80 cm',
      value: '80'
    },
    {
      imageUrl: '/images/trailer.svg',
      imageSelectedUrl: '/images/trailerSelected.svg',
      label: '100 cm',
      value: '100'
    }
  ]
}

const inputSelectdata = () => {
  return {
    control: {
      placeholder: 'Tractor number',
      label: 'Tractor number',
      data: [{ label: 'Tractor1' }, { label: 'Tractor2' }]
    },
    onHandleChange: selectItem => {
      console.log(selectItem)
    }
  }
}

const multiSelectData = () => {
  return {
    control: {
      label: 'Seal number',
      type: 'select|multiple',
      placeholder: 'Select or type seal number',
      min: 1,
      max: 5,
      required: false,
      data: null,
      key: 'sealNumber',
      currentValue: 'seal 1||seal 2||seal 3'
    },
    onHandleChange: selectItem => {
      console.log(selectItem)
    }
  }
}

const { control, onHandleChange } = inputSelectdata()
const { control: controlMulti, onHandleChange: onHandleChangeMulti } = multiSelectData()

const UiShowroom = () => (
  <Container>
    <Box className={styles.spaced}>
      <Typography variant="h2" component="h1">
        Some UI Components
      </Typography>
    </Box>
    <Box className={styles.spaced}>
      <Typography variant="h4">Text Inputs</Typography>
      <TextField required label="Label" placeholder="Placeholder" />
    </Box>
    <Box className={styles.spaced}>
      <Typography variant="h4">Buttons</Typography>
      <Button variant="contained">Button</Button>
    </Box>
    <Box className={styles.spaced}>
      <Typography variant="h4">Icons</Typography>
      <Icon>star</Icon>
      <Icon>build</Icon>
      <Icon>alarm</Icon>
      <Icon>bookmarks</Icon>
    </Box>
    <Box className={styles.spaced}>
      <ImageSelection control={imageMockData} currentValue="53" />
    </Box>
    <Box className={styles.spaced}>
      <InputSelect control={control} onHandleChange={onHandleChange} currentValue="" />
    </Box>
    <Box className={styles.spaced}>
      <InputMultiSelect control={controlMulti} onHandleChange={onHandleChangeMulti} currentValue={[]} />
    </Box>
  </Container>
)

export default UiShowroom
