// @vendors
import { connect } from 'react-redux'

// @components
import Acccept from './accept-invitation'

// @actions
import { acceptInvitation } from './actions'

const mapStateToProps = (state, ownProps) => {
  const { search } = ownProps.location
  const params = search.split('&')
  const acceptToken = search ? params[0].split('=')[1] : ''
  const email = search ? params[1].split('=')[1] : ''
  const inviteId = search ? params[3].split('=')[1] : ''
  return {
    acceptToken,
    inviteId,
    email,
    profile: state.security.Profile
  }
}

const mapDispatchToProps = {
  acceptInvitation
}

export default connect(mapStateToProps, mapDispatchToProps)(Acccept)
