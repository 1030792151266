// @vendors
import React from 'react'
import { useHistory } from 'react-router-dom'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

// @styles
import styles from '../styles.module.sass'

const UserProfileMenuItem = ({ anchorEl, handleMenuClose, isMenuOpen, menuId, userProfile: urlPath }) => {
  const history = useHistory()

  const handleMyAccountClick = () => {
    history.push(`/${urlPath}/profile`)
  }

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      className={styles.menu}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
    >
      <MenuItem onClick={handleMyAccountClick}>
        <Typography variant="subtitle1" noWrap>
          My Account
        </Typography>
      </MenuItem>
      <MenuItem onClick={handleMenuClose('logout')}>
        <Typography className={styles.logoutLabel} variant="inherit">
          Log Out
        </Typography>
        <ListItemIcon className={styles.closeIcon} onClick={handleMenuClose('close')}>
          <HighlightOffIcon />
        </ListItemIcon>
      </MenuItem>
    </Menu>
  )
}

export default UserProfileMenuItem
