export const EMAIL_INITIAL_STATE = {
  value: '',
  valid: false,
  dirty: false,
  pristine: true
}
export const EMAIL_HELPER_TEXT = {
  email: null
}
export const ERROR_MESSAGE = 'Email format is incorrect'
export const RESET_PASSWORD_DESCRIPTION = 'Please type your email below to receive a password reset link.'
