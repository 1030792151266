// @vendors
import InfoIcon from '@material-ui/icons/Info'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

// @constants
import { NOTIFICATION_TYPES } from '../../constant'

export const setNotificationIcon = type => {
  switch (type) {
    case NOTIFICATION_TYPES.ERROR:
      return ErrorIcon
    case NOTIFICATION_TYPES.SUCCESS:
      return CheckCircleIcon
    default:
      return InfoIcon
  }
}

export const setNotificationStyles = (theme, type) => {
  switch (type) {
    case NOTIFICATION_TYPES.ERROR:
      return {
        backgroundColor: theme.palette.error.main
      }
    case NOTIFICATION_TYPES.SUCCESS:
      return {
        backgroundColor: theme.palette.primary.main
      }
    default:
      return {}
  }
}
