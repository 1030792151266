// @vendors
import React from 'react'
import { Redirect } from 'react-router-dom'

const withMissingPropsRedirection = (missingPropsRedirects, component) => props => {
  const redirectTo = missingPropsRedirects.reduce((curRedirect, propRedirect) => {
    if (!curRedirect && !props[propRedirect.prop]) {
      return propRedirect.redirectTo
    }

    return curRedirect
  }, null)
  const Component = component

  return redirectTo ? <Redirect to={redirectTo} /> : <Component {...props} />
}

export default withMissingPropsRedirection
