import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core'

// @styles
import styles from './styles.module.sass'

const DeleteDialog = ({ title, textBody, onDelete, isOpen, onClose }) => {
  return (
    <Dialog disableEscapeKeyDown open={isOpen} onClose={onClose} maxWidth="sm" fullWidth className={styles.modalContainer}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">{textBody}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDelete}>Delete</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
