// @vendors
import ContentLoader from 'react-content-loader'
import React from 'react'

// @styles
import styles from './styles.module.sass'

const SignUpPlaceHolder = () => (
  <ContentLoader height={300} width={400} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb" className={styles.signupPlaceholder}>
    <rect x="100" y="100" rx="3" ry="3" width="190" height="6" />
    <rect x="0" y="140" rx="3" ry="3" width="380" height="20" />
    <rect x="0" y="180" rx="3" ry="3" width="380" height="20" />
    <rect x="280" y="220" rx="3" ry="3" width="100" height="6" />
    <rect x="0" y="230" rx="3" ry="3" width="380" height="20" />
    <rect x="100" y="260" rx="3" ry="3" width="190" height="6" />
    <circle cx="200" cy="30" r="30" />
  </ContentLoader>
)

export default SignUpPlaceHolder
