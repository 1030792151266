// @vendors
import axios from 'axios'

// @utils
import httpConfig from '../../../http/config'
import { createApiRequestHeaders, standardErrorHandler, standardRequestHandler, standardSuccessHandler } from '../../../http/utils'

// @constants
import * as constants from './constant'

const { apiServer } = httpConfig

export const getFacilities = () => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType } = getState().security
  const { clientId } = getState().clientData

  const config = {
    headers: createApiRequestHeaders(token, tokenType)
  }

  standardRequestHandler(dispatch, constants.REQUEST_FACILITIES.REQUESTED)

  return axios
    .get(`${apiServer.url}/api/Clients/${clientId}/facilities`, config)
    .then(res => standardSuccessHandler(dispatch, constants.REQUEST_FACILITIES.SUCCESS, res.data))
    .catch(err => standardErrorHandler(dispatch, err, constants.REQUEST_FACILITIES.ERROR, 'Error Fetching FACILITIES'))
}

export const createFacility = facility => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType } = getState().security
  const { clientId } = getState().clientData
  const config = {
    headers: createApiRequestHeaders(token, tokenType)
  }
  const body = { clientId: clientId, ...facility }

  standardRequestHandler(dispatch, constants.CREATE_FACILITY.REQUESTED)

  return axios
    .post(`${apiServer.url}/api/Facilities`, body, config)
    .then(res => standardSuccessHandler(dispatch, constants.CREATE_FACILITY.SUCCESS, res.data, 'Facility was created successfully'))
    .catch(err => {
      standardErrorHandler(dispatch, err, constants.CREATE_FACILITY.ERROR, 'Error Creating Facility')
    })
}

export const getFacility = facilityId => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType } = getState().security
  const config = {
    headers: createApiRequestHeaders(token, tokenType)
  }

  standardRequestHandler(dispatch, constants.GET_FACILITY.REQUESTED)

  return axios
    .get(`${apiServer.url}/api/Facilities/${facilityId}`, config)
    .then(res => standardSuccessHandler(dispatch, constants.GET_FACILITY.SUCCESS, res.data))
    .catch(err => standardErrorHandler(dispatch, err, constants.GET_FACILITY.ERROR, 'Error Fetching Facility'))
}

export const updateFacility = facility => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType } = getState().security
  const { clientId } = getState().clientData
  const config = {
    headers: createApiRequestHeaders(token, tokenType)
  }
  const body = { clientId: clientId, ...facility }

  standardRequestHandler(dispatch, constants.UPDATE_FACILITY.REQUESTED)

  return axios
    .put(`${apiServer.url}/api/Facilities/${facility.id}`, body, config)
    .then(res => standardSuccessHandler(dispatch, constants.UPDATE_FACILITY.SUCCESS, res.data, 'Facility was updated successfully'))
    .catch(err => standardErrorHandler(dispatch, err, constants.UPDATE_FACILITY.ERROR, 'Error Upating Facility'))
}
