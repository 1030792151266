export const getForm = state => state.form

export const getFacility = state => {
  let facility =
    state.form &&
    state.form.steps &&
    state.form.steps[0] &&
    state.form.steps[0].sections &&
    state.form.steps[0].sections[1] &&
    state.form.steps[0].sections[1].controls &&
    state.form.steps[0].sections[1].controls[1]

  facility =
    facility ||
    (state.carrierTicket &&
      state.carrierTicket.steps &&
      state.carrierTicket.steps[0] &&
      state.carrierTicket.steps[0].sections &&
      state.carrierTicket.steps[0].sections[1] &&
      state.carrierTicket.steps[0].sections[1].controls &&
      state.carrierTicket.steps[0].sections[1].controls[1])

  return facility ? facility : { currentValue: null }
}
