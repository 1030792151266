// @vendors
import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

// @constants
import { EMAIL_HELPER_TEXT } from './constant'

// @utils
import { emailFormatValidation } from '../../utils/field-value-validation'

// @styles
import styles from './styles.module.sass'
import { ERROR_MESSAGE } from '../../security/components/password-recovery/constant'

const EmailInvite = ({ isOpen, location, onClose, title, url, sendEmailInvite, inviteType = 'driver' }) => {
  const buildFormObject = () => ({
    email: { value: '', valid: false, pristine: true, dirty: false },
    form: { valid: false }
  })

  const [state, setState] = useState(buildFormObject())
  const [open, setOpen] = useState(isOpen)
  const [helperText, setHelperText] = useState(EMAIL_HELPER_TEXT)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const showErrors = () => {
    setState({
      ...state,
      email: { ...state.email, pristine: false }
    })
  }

  const handleOnClose = () => {
    setState(buildFormObject())
    onClose()
  }

  const handleChange = event => {
    const value = event.target.value
    const valid = emailFormatValidation(value)

    if (!valid) {
      event.target.setCustomValidity(EMAIL_HELPER_TEXT.email)
      setHelperText({
        email: ERROR_MESSAGE
      })
    } else {
      event.target.setCustomValidity('')
      setHelperText({ ...helperText, email: null })
    }

    setState({
      ...state,
      email: { ...state.email, value, valid, pristine: false, dirty: true },
      form: { valid: event.target.form.checkValidity() }
    })
  }

  const handleOnSubmit = event => {
    event.preventDefault()

    if (state.form.valid) {
      sendEmailInvite(state.email.value, url, inviteType)
      setState(buildFormObject())
    } else {
      showErrors()
    }
  }

  return (
    <Dialog open={open} onClose={handleOnClose} className={styles.modalContainer} maxWidth="sm" fullWidth>
      <DialogTitle>{title.toUpperCase()}</DialogTitle>
      <DialogContent>
        <form className={styles.form} noValidate onSubmit={handleOnSubmit}>
          <TextField
            label="Email"
            name="email"
            id="email"
            type="email"
            onChange={handleChange}
            required
            error={!state.email.valid && !state.email.pristine}
            value={state.email.value}
            className={styles.email}
            InputProps={{
              classes: {
                input: styles.input
              }
            }}
            helperText={helperText.email}
          />

          <div className={styles.buttonWrapper}>
            <Button className={styles.button} type="submit" color="primary" disabled={!state.email.valid && !state.email.pristine}>
              INVITE
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default EmailInvite
