// @vendors
import { connect } from 'react-redux'

// @components
import InputFetch from './input-fetch'

// @utils
import { getSecurity } from '../../../../security'
import { standardErrorHandler } from '../../../../http/utils'
import { getFormValues } from '../../driver-tickets/ticket-values/selectors'
import { getForm, getFacility } from '../../driver-tickets/ticket/selectors'
import { getTicketInfo } from '../../../carrier/carrier-tickets/ticket/selectors'
import { getThemingData } from '../../../../utils/theming/selectors'
import { setFlag } from '../../../../store/actions/flags'

const mapDispatchToProps = dispatch => {
  return {
    setFlag: (flag, value) => setFlag(flag, value)(dispatch),
    notifyErrorData: err => {
      standardErrorHandler(dispatch, err, 'ERROR_FETCHING_DATA', err)
    }
  }
}

const mapStateToProps = state => {
  const { access_token: token, token_type: tokenType } = getSecurity(state)
  const isTicketCreated = getForm(state).hasBeenCreated || getTicketInfo(state).hasBeenCreated
  const ticketId = getForm(state).ticketId || getTicketInfo(state).ticketId
  const facilityId = getFormValues(state).facility || getFacility(state).currentValue
  const theming = getThemingData(state)
  return { token, tokenType, ticketId, facilityId, isTicketCreated, theming }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputFetch)
