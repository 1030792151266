export const buildActionType = (moduleName, actionName) => `${moduleName}__${actionName}`

export const buildAsyncActionType = (moduleName, actionName) => {
  const baseActionName = buildActionType(moduleName, actionName)
  return {
    REQUESTED: `${baseActionName}_REQUESTED`,
    SUCCESS: `${baseActionName}_SUCCESS`,
    ERROR: `${baseActionName}_ERROR`
  }
}
