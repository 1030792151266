// @vendors
import { connect } from 'react-redux'

// @utils
import { getCarrier } from '../carrier-profile/selector'

// @actions
import { requestCarrier } from '../carrier-profile/actions'
import { requestDrivers } from '../driver-list/actions'

// @components
import CarrierLoader from './carrier-loader'
import { getDriversFromCarrier } from '../../../common-components/tickets-list/components/assign/selectors'

const mapStateToProps = state => ({
  security: state.security,
  carrier: getCarrier(state),
  drivers: getDriversFromCarrier(state)
})

const mapDispatchToProps = {
  requestCarrier,
  requestDrivers
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierLoader)
