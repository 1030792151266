// @vendors
import React, { useEffect, useState } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

// @styles
import styles from './styles.module.sass'

const BasicSelect = ({ control, isDisabled, currentValue, onHandleChange }) => {
  const [value, setValue] = useState(currentValue || '')

  const handleChange = event => {
    event.stopPropagation()
    onHandleChange(event.target.value)
  }

  useEffect(() => {
    if (currentValue) {
      onHandleChange(currentValue)
      setValue(currentValue)
    }
  }, [currentValue])

  return (
    <FormControl className={styles.selectWrapper}>
      <InputLabel id={`${control.label}-label`}>{control.label}</InputLabel>
      <Select
        id={control.label}
        label={control.label}
        disabled={isDisabled}
        placeholder={control.placeholder}
        value={value}
        onChange={handleChange}
        MenuProps={{ classes: { paper: styles.options } }}
      >
        {control.data.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default BasicSelect
