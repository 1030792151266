// @vendors
import { connect } from 'react-redux'

// @components
import SignUp from './signup'
import SignUpPlaceHolder from './signupPlaceholder'

// @actions
import { getJwt } from '../../actions'
import { resetDriver, createDriver } from '../../../pages/driver/driver-profile/actions'
import { setFlag } from '../../../store/actions/flags'

// @utils
import { isDriverCreated, getDriver } from '../../../pages/driver/driver-profile/selectors'

const mapStateToProps = (state, ownprops) => {
  const { search } = ownprops.location
  const params = search.split('&')
  const inviteToken = search ? params[0].split('=')[1] : ''
  const email = search ? params[1].split('=')[1] : ''
  const clientId = search ? params[2].split('=')[1] : ''
  const inviter = search ? params[3].split('=')[1] : ''

  return {
    driverCreated: isDriverCreated(state),
    driver: getDriver(state),
    inviteToken: decodeURIComponent(inviteToken),
    invitedEmail: email,
    clientId,
    inviter
  }
}

const mapDispatchToProps = {
  resetDriver,
  createDriver,
  getJwt,
  setFlag
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)

export { SignUpPlaceHolder }
