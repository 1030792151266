// @vendors
import React from 'react'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import DoneRounded from '@material-ui/icons/DoneRounded'
import InputAdornment from '@material-ui/core/InputAdornment'

// @styles
import styles from '../styles.module.sass'

const renderAdornment = dataFetch => (
  <InputAdornment position="end">
    <IconButton className={dataFetch && dataFetch.length >= 1 ? styles.done : styles.hideIcon}>
      <DoneRounded />
    </IconButton>
  </InputAdornment>
)

const Input = inputProps => {
  const { InputProps, ref, dataFetch, label, ...other } = inputProps
  const id = `input-${label.trim().toLocaleLowerCase()}`

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          fullWidth: styles.inputRoot
        },
        endAdornment: renderAdornment(dataFetch),
        ...InputProps,
        id
      }}
      {...other}
    />
  )
}

export default Input
