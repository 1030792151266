// @vendor
import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

// @styles
import styles from './styles.module.sass'

const ConfirmationModal = ({ handleConfirmation, onClose, showDialog, ticketNumber, driverName, ticketStatus }) => (
  <Dialog open={showDialog} onClose={onClose} maxWidth="sm" className={styles.confirmationDialog} aria-labelledby="confirmation-dialog">
    <DialogTitle id="confirmation-dialog" className={styles.confirmationDialogTitle}>
      {!ticketStatus ? 'Assign Ticket Confirmation' : 'Delete Ticket Confirmation'}
    </DialogTitle>

    <DialogContent>
      <DialogContentText>
        {!ticketStatus
          ? `Are you sure you want to assign this Ticket to ${driverName}?`
          : `Are you sure you want to Delete the Ticket #${ticketNumber} ? `}
      </DialogContentText>
    </DialogContent>

    <DialogActions className={styles.confirmationDialogFooter}>
      <Button className={styles.cancelButton} onClick={onClose}>
        Cancel
      </Button>
      <Button autoFocus className={styles.confirmButton} color="primary" onClick={event => handleConfirmation(event, ticketNumber, ticketStatus)}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmationModal
