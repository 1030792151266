// @vendors
import { connect } from 'react-redux'

// @actions
import { requestDrivers, removeRelationshipRequest } from '../../actions'

// @components
import DriverList from './list'

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  requestDrivers,
  removeRelationshipRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverList)
