// @vendors
import { connect } from 'react-redux'

// @components
import FacilityForm from './facility-form'

// @utils
import { getSelected } from '../../selectors'

// @actions
import { createFacility, updateFacility, getFacility } from '../../actions'

const mapStateToProps = (state, ownprops) => {
  const { params } = ownprops.match
  const { facilityId } = params
  return {
    facilityId,
    facility: getSelected(state),
    security: state.security
  }
}

const mapDispatchToProps = {
  createFacility,
  updateFacility,
  getFacility
}

export default connect(mapStateToProps, mapDispatchToProps)(FacilityForm)
