// @vendors
import { connect } from 'react-redux'

// @utils
import { getDriver } from '../driver-profile/selectors'

// @actions
import { requestDriver } from '../driver-profile/actions'

// @components
import DriverLoader from './driver-loader'

const mapStateToProps = state => ({
  security: state.security,
  driver: getDriver(state),
  justCreatedDriver: state.flags.justCreatedDriver
})

const mapDispatchToProps = {
  requestDriver
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverLoader)
