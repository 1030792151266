// @constants
import * as constants from './constant'
import * as ticketListConstants from '../../../../common-components/tickets-list/constant'
import * as securityConstants from '../../../../security/constant'

const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ticketListConstants.GET_TICKET_LIST_SUCCESS: {
      return initialState
    }
    case constants.UPDATE_FORM_VALUE: {
      const { key, value } = action.payload
      return { ...state, [key]: value }
    }
    case constants.RESET_FORM_VALUES: {
      return {}
    }
    case securityConstants.LOG_OUT: {
      return initialState
    }
    default: {
      return state
    }
  }
}

export default reducer
