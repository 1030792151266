// @vendors
import React from 'react'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MobileStepper from '@material-ui/core/MobileStepper'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

// @constants
import { STEP_PREVIOUS, STEP_CONTINUE } from '../constant'

// @styles
import styles from './styles.module.sass'

// prettier-ignore
const StepsFooter = ({ 
    onHandleNavigationStep,
    onHandleSaveForLater, 
    isFirstStep, 
    isFinalStep, 
    isDisabled, 
    ticketStatus, 
    steps, 
    activeStep }) => {
  const display = isFirstStep ? 'none' : 'inline-flex'
  const handleNavigation = (event, navigation) => {
    event.preventDefault()
    onHandleNavigationStep(navigation)
  }

  const handleSave = event => {
    event.preventDefault()
    onHandleSaveForLater()
  }

  return (
    <Container className={styles.container}>
      <MobileStepper
        variant="dots"
        steps={steps}
        position="static"
        className={!isDisabled ? styles.wrapper : styles.wrapperDisabled}
        classes={{ dots: styles.dots }}
        activeStep={activeStep}
        nextButton={
          <div>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={styles.previousBtn}
              onClick={event => handleNavigation(event, STEP_PREVIOUS)}
              style={{ display: display }}
            >
              <ArrowBackIcon className={styles.leftIcon} />
              <span className={styles.stepActionDescription}>PREVIOUS</span>
            </Button>
            {ticketStatus === 'Ready' || isFirstStep || isFinalStep ? (
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={styles.continueBtn}
                onClick={event => handleNavigation(event, STEP_CONTINUE)}
              >
                <span className={styles.stepActionDescription}>CONTINUE</span>
                <ArrowForwardIcon className={styles.rightIcon} />
              </Button>
            ) : null}
          </div>
        }
        backButton={
          !isDisabled && (
            <Button disabled={isDisabled} variant="outlined" size="large" color="primary" onClick={event => handleSave(event)}>
              <Hidden mdUp>
                <span>SAVE</span>
              </Hidden>
              <Hidden smDown>
                <span>SAVE FOR LATER </span>
              </Hidden>
            </Button>
          )
        }
      />
    </Container>
  )
}

export default StepsFooter
