// @actions constants
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION'

// @notification types constants
export const NOTIFICATION_TYPES = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INFO: 'INFO'
}
