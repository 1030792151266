// @vendors
import React, { Fragment, useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

// @components
import QRDetails from '../../../../../../common-components/qr-details'

// @styles
import styles from './styles.module.sass'
import QRStyles from '../../styles.module.sass'

const QRDetailsModal = ({ qrCodeUrl, ticketId, requestQrCode, qrCode }) => {
  const [openModal, setOpenModal] = useState(false)

  const handleClick = event => {
    event.stopPropagation()
    setOpenModal(true)
  }

  const handleClose = event => {
    event.stopPropagation()
    setOpenModal(false)
  }

  useEffect(() => {
    if ((openModal && !qrCode) || (openModal && ticketId !== qrCode.ticketId)) {
      requestQrCode(ticketId)
    }
  }, [ticketId, requestQrCode, openModal, qrCode])

  return (
    <Fragment>
      <Button onClick={handleClick}>
        <img className={QRStyles.img} alt="qr" src={qrCodeUrl} />
      </Button>

      <Modal open={openModal} className={QRStyles.modal} onClose={handleClose}>
        <div className={styles.qrContainer}>
          {qrCode && ticketId === qrCode.ticketId ? (
            <QRDetails qrCode={qrCode} />
          ) : (
            <Grid style={{ height: '100%' }} container alignItems="center" justify="center">
              <Grid item>
                <CircularProgress color="primary" />
              </Grid>
            </Grid>
          )}
        </div>
      </Modal>
    </Fragment>
  )
}

export default QRDetailsModal
