// @utils
import { buildAsyncActionType } from '../../store/utils'

const MODULE_NAME = 'client'
export const PATH_NAME = `/${MODULE_NAME}`

export const REQUEST_CARRIERS = buildAsyncActionType(MODULE_NAME, 'REQUEST_CARRIERS')

export const REQUEST_DRIVERS = buildAsyncActionType(MODULE_NAME, 'REQUEST_DRIVERS')

export const REMOVE_RELATIONSHIP = buildAsyncActionType(MODULE_NAME, 'REMOVE_RELATIONSHIP')
