// @vendors
import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import PersonIcon from '@material-ui/icons/Person'
import { Icon, IconButton, Grid, Card, Avatar } from '@material-ui/core'

// @components
import ProfileInfo from './components/profile-info'
import PasswordInfo from './components/password-info'
import NavigationBar from '../../../common-components/navigation-bar'

// @styles
import styles from './styles.module.sass'

const DriverProfile = ({
  driver,
  notifyInvalidFileType,
  security,
  updateDriver,
  updateDriverAvatar,
  theme,
  clientName,
  justCreatedDriver,
  setFlag
}) => {
  const [state, setState] = useState({
    editProfile: false,
    editPassword: false,
    avatarUrl: driver ? driver.avatar : null
  })

  const handleUpdateDriver = driver => {
    updateDriver(driver)
  }

  const handleEditProfile = value => {
    setState({
      editProfile: value,
      editPassword: state.editPassword,
      avatarUrl: state.avatarUrl || driver.avatar
    })
  }

  const handleChangeAvatar = event => {
    const reader = new FileReader()
    const allowedImageTypes = ['image/png', 'image/jpg', 'image/jpeg']

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files

      const validType = !!allowedImageTypes.find(imagetype => imagetype === file.type)

      if (!validType) {
        return notifyInvalidFileType()
      }

      reader.readAsDataURL(file)

      reader.onload = e => {
        if (validType)
          setState({
            ...state,
            avatarUrl: reader.result
          })
      }

      reader.onloadend = e => {
        handleUpdateAvatar(file)
      }
    }
  }

  const handleEditPassword = value => {
    setState({
      editPassword: value,
      editProfile: state.editProfile,
      avatarUrl: state.avatarUrl
    })
  }

  const handleUpdateAvatar = (file, validFileType) => {
    const data = new FormData()
    data.append('file', file)

    updateDriverAvatar(data)
  }

  const profileInfoProps = {
    driver,
    editProfile: state.editProfile,
    handleUpdateDriver,
    handleEditProfile,
    theme,
    clientName
  }

  const passwordInfoProps = {
    driver,
    editPassword: state.editPassword,
    handleUpdateDriver,
    handleEditPassword
  }

  const onSelectAvatar = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.onchange = e => handleChangeAvatar(e)
    input.click()
  }

  useEffect(() => {
    if (justCreatedDriver) {
      setFlag('justCreatedDriver', false)
    }
  }, [justCreatedDriver])

  return security && driver ? (
    <>
      <NavigationBar classes={{ bar: styles.navigationBar }} />
      <Card className={styles.card}>
        <div className={styles.cardHeader}></div>

        <Grid container justify="center" className={styles.avatarContainer}>
          {state.avatarUrl ? (
            <Avatar
              alt="driver avatar"
              src={state.avatarUrl}
              className={state.editProfile ? styles.clickableAvatar : styles.avatar}
              onClick={state.editProfile ? onSelectAvatar : () => {}}
            />
          ) : (
            <PersonIcon
              className={state.editProfile ? styles.clickableAvatar : styles.avatar}
              onClick={state.editProfile ? onSelectAvatar : () => {}}
            />
          )}
        </Grid>
        <ProfileInfo {...profileInfoProps}></ProfileInfo>
      </Card>

      <Card className={styles.cardPassword}>
        <PasswordInfo {...passwordInfoProps}></PasswordInfo>
      </Card>
    </>
  ) : (
    <Redirect to="/" />
  )
}
export default DriverProfile
