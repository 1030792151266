// @vendors
import React, { useEffect, useState } from 'react'
import Downshift from 'downshift'
import CircularProgress from '@material-ui/core/CircularProgress'

// @actions
import { requestInputFetchData } from './actions'

// @components
import Input from './components/input'
import InputFetchCard from './components/card'

// @styles
import styles from './styles.module.sass'
import { HandleSaveForLater } from '../../../../utils'

const InputFetch = ({
  control,
  onHandleChange,
  isDisabled,
  token,
  theming,
  tokenType,
  ticketId,
  facilityId,
  notifyErrorData,
  isTicketCreated,
  handleSafeForLater,
  setAllowContinue,
  currentValue,
  setFlag
}) => {
  const [inputValue, setInputValue] = useState(control.currentValue || '')
  const [dataFetch, setDataFetch] = useState(control.data || [])
  const [open, setOpen] = useState(false)

  const handleDataFetch = data => {
    const shipmentData = [...data, { key: control.key, value: inputValue }]
    setDataFetch(data)
    onHandleChange(shipmentData)

    if (dataFetch) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  const handleOutboundResponse = (shipmentResponse, validShipmentNumber = true) => {
    if (control.key.toLowerCase() === 'outboundshipment') {
      setFlag(
        'validOutboundLiveLoad',
        !!shipmentResponse && !!shipmentResponse.find(r => r.key.toLowerCase() === 'live load' && r.value.toLowerCase() === 'true')
      )
      setFlag(
        'validOutboundReefer',
        !!shipmentResponse && !!shipmentResponse.find(r => r.key.toLowerCase() === 'reefers' && r.value.toLowerCase() === 'true')
      )

      setFlag('validOutboundShipmentNumber', validShipmentNumber)

      setFlag('outboundShipmentNumberValidationCompleted', true)
    } else if (control.key.toLowerCase() === 'inboundshipment') {
      setFlag('validInboundShipmentNumber', validShipmentNumber)
      setFlag('inboundShipmentNumberValidationCompleted', true)
    }
  }

  useEffect(() => {
    if (currentValue) {
      if (typeof currentValue === 'object') {
        const shipmentValue = currentValue.find(v => v.key === control.key) // currentValue.length > 1 ? currentValue.find(v => v.key === control.key).value : currentValue[0].value
        setInputValue(shipmentValue.value)

        setDataFetch(currentValue.filter(i => i.key !== control.key))
        onHandleChange([...currentValue.filter(i => i.key !== control.key), shipmentValue])

        setOpen(true)
      } else {
        setInputValue(currentValue)
        onHandleChange(currentValue)

        setOpen(true)
        setDataFetch([])
        requestInputFetchData({
          inputValue: currentValue,
          url: control.url,
          token,
          tokenType,
          ticketId,
          facilityId,
          notifyErrorData,
          isTicketCreated,
          handleSafeForLater: () => Promise.resolve(true),
          controlKey: control.key
        })
          .then(data => {
            handleDataFetch(data)
            handleOutboundResponse(data)
          })
          .catch(err => {
            setOpen(false)
            handleOutboundResponse(null, false)
          })
      }
    }
  }, [control.currentValue])

  const handleKeyDown = event => {
    if (event.key === 'Enter' && event.target.value) {
      event.preventDefault()
      setOpen(true)
      setDataFetch([])
      setAllowContinue(false)

      requestInputFetchData({
        inputValue: event.target.value,
        url: control.url,
        token,
        tokenType,
        ticketId,
        facilityId,
        notifyErrorData,
        isTicketCreated,
        handleSafeForLater,
        controlKey: control.key
      })
        .then(data => {
          setAllowContinue(true)
          handleDataFetch(data)
          handleOutboundResponse(data)
        })
        .catch(err => {
          setOpen(false)

          if (err && err.toLowerCase().search('shipment is not ready') !== -1) {
            setAllowContinue(true)
            handleOutboundResponse(null, true)
            return
          }

          handleOutboundResponse(null, false)
          onHandleChange(null)
          setAllowContinue(false)
        })
    } else if (event.key === 'Enter' && !event.target.value) {
      setAllowContinue(true)
      setOpen(false)
      setDataFetch([])
    } else if (event.target.value) {
      setAllowContinue(false)
      setDataFetch([])
      setOpen(false)

      const shipmentData = [{ key: control.key, value: event.target.value }]
      onHandleChange(shipmentData)
    } else if (!event.target.value) {
      setAllowContinue(false)
      onHandleChange(null)
    }
  }

  const handleBlur = event => {
    event.stopPropagation()

    if (event.key === 'Tab' && event.target.value) {
      const shipmentData = [{ key: control.key, value: event.target.value }]
      onHandleChange(shipmentData)
      setOpen(true)
      setDataFetch([])
      setAllowContinue(false)

      return requestInputFetchData({
        inputValue: event.target.value,
        url: control.url,
        token,
        tokenType,
        ticketId,
        facilityId,
        notifyErrorData,
        isTicketCreated,
        handleSafeForLater,
        controlKey: control.key
      })
        .then(data => {
          setAllowContinue(true)
          handleDataFetch(data)
          handleOutboundResponse(data)
        })
        .catch(err => {
          setOpen(false)

          if (err && err.toLowerCase().search('shipment is not ready') !== -1) {
            setAllowContinue(true)
            handleOutboundResponse(null, true)
            return
          }

          handleOutboundResponse(null, false)
          onHandleChange(null)
          setAllowContinue(false)
        })
    }

    return setOpen(false)
  }

  const handleChange = event => {
    setInputValue(event.target.value)
  }

  return (
    <div className={styles.root}>
      <Downshift id={`downshift-simple-${control.key.trim().toLocaleLowerCase()}`} isOpen={open} inputValue={inputValue}>
        {({ getInputProps, getLabelProps, getMenuProps, isOpen }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            disabled: isDisabled,
            onKeyUp: handleKeyDown,
            onKeyDown: handleBlur,
            onChange: handleChange,
            placeholder: control.placeholder
          })

          return (
            <div>
              {Input({
                dataFetch,
                fullWidth: true,
                label: control.label,
                InputLabelProps: getLabelProps({ shrink: true }),
                InputProps: { onBlur, onFocus },
                inputProps
              })}
              {isOpen && dataFetch.length <= 1 && (
                <div {...getMenuProps()}>
                  <CircularProgress />
                </div>
              )}
              {isOpen && dataFetch.length > 1 && <div {...getMenuProps()}>{InputFetchCard(dataFetch, theming)}</div>}
            </div>
          )
        }}
      </Downshift>
    </div>
  )
}

export default props => {
  return <HandleSaveForLater.Consumer>{value => <InputFetch {...props} handleSafeForLater={value} />}</HandleSaveForLater.Consumer>
}
