// @vendors
import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import InputAdornment from '@material-ui/core/InputAdornment'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

// @styles
import './styles.scss'

const PasswordInputIcon = ({ handleClickShowPassword, handleMouseDownShowPassword, showPassword }) => {
  const setInputIcon = showPassword ? <VisibilityIcon className="show-password-icon" /> : <VisibilityOffIcon />

  return (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownShowPassword}>
        {setInputIcon}
      </IconButton>
    </InputAdornment>
  )
}

export default PasswordInputIcon
