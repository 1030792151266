// @vendors
import React, { Fragment, useState, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import Button from '@material-ui/core/Button'
import TableBody from '@material-ui/core/TableBody'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

// @components
import TicketRow from './components/row'
import TicketSidebar from './components/side-bar'
import TicketListHeader from './components/header'
import Pagination from '../pagination'
import NavigationBar from '../navigation-bar'
import AssignDriverModal from './components/assign'

// @utils
import withMissingPropsRedirection from '../../utils/redirection/with-missing-props-redirection'

// @constants
import { PATH_NAME as DRIVER_PATH_NAME } from '../../pages/driver/constant'
import { PATH_NAME as CARRIER_PATH_NAME } from '../../pages/carrier/constants'
import { unauthorizedRedirect } from '../../utils/redirection/constant'

// @styles
import styles from './styles.module.sass'

const TicketList = ({ history, security, deleteTicket, getTickets, tickets, setFilters, filters, assignDriver, selectMode, drivers }) => {
  const { Profile: profile } = security
  const [selectedTicket, setSelectedTicket] = useState('')
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const [checkedTickets, setCheckedTickets] = useState([])
  const [checkedTicketNumbers, setCheckedTicketNumbers] = useState([])
  const [checkAll, setCheckAll] = useState(false)
  const [openAssignDriverModal, setOpenAssignDriver] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [selectedDriver, setSelectedDriver] = useState(null)

  const { type, status } = filters

  const onCloseAssignDriverModal = () => {
    setOpenAssignDriver(false)
  }

  const onSelectDriver = driver => {
    setSelectedDriver(driver)
  }

  const refreshTickets = () => {
    requestTickets(currentPage, currentPageSize)
    setOpenAssignDriver(false)
    setSelectedDriver(null)
  }

  const handleClick = event => {
    event.preventDefault()
    history.push(`${profile.toLowerCase() === 'driver' ? DRIVER_PATH_NAME : CARRIER_PATH_NAME}/tickets/new`)
  }

  const onCheckTicket = (value, ticketNumber) => {
    if (value === 'all') {
      let checkedTickets = []
      let checkedTicketNumbers = []

      tickets.body.forEach(ticketBody => {
        const ticketId = ticketBody.cells.find(cell => cell.key === 'ticketId').label
        checkedTickets = [...checkedTickets, ticketId]

        const ticketNumber = ticketBody.cells.find(cell => cell.key === 'Number').label
        checkedTicketNumbers = [...checkedTicketNumbers, ticketNumber]
      })

      setCheckedTicketNumbers([...checkedTicketNumbers])
      setCheckedTickets([...checkedTickets])
      setCheckAll(true)
      return
    } else if (value === 'none') {
      setCheckedTickets([])
      setCheckedTicketNumbers([])
      setCheckAll(false)
      return
    }

    const exists = checkedTickets.includes(value)

    if (exists) {
      const newCheckedTickets = checkedTickets.filter(ticketId => ticketId !== value)

      const newCheckedTicketNumbers = checkedTicketNumbers.filter(number => number !== ticketNumber)

      setCheckedTickets([...newCheckedTickets])
      setCheckedTicketNumbers([...newCheckedTicketNumbers])

      if (newCheckedTickets.length < tickets.body.length) {
        setCheckAll(false)
      }

      return
    }

    setCheckedTickets([...checkedTickets, value])
    setCheckedTicketNumbers([...checkedTicketNumbers, ticketNumber])
  }

  const filteredTicketList = () => {
    const pageSize = tickets && tickets.pager ? tickets.pager.pageSize : null
    getTickets(null, pageSize)
  }

  const toggleSidebar = (event, open) => {
    event.preventDefault()
    setSidebarOpen(open)
  }

  useEffect(() => {
    getTickets()
  }, [getTickets])

  const onHandleEdit = (event, ticketId) => {
    event.preventDefault()
    history.push(`${profile.toLowerCase() === 'driver' ? DRIVER_PATH_NAME : CARRIER_PATH_NAME}/tickets/${ticketId}`)
  }

  const onHandleDelete = (event, ticketNumber, ticketStatus) => {
    event.preventDefault()

    if (ticketStatus === 'Incomplete' || ticketStatus === 'Ready') {
      deleteTicket(ticketNumber)
    }
  }

  const requestTickets = (page, pageSize) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
    getTickets(page, pageSize)
  }

  const handleRowClick = id => {
    const selectedIndex = selectedTicket.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTicket, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTicket.slice(1))
    } else if (selectedIndex === selectedTicket.length - 1) {
      newSelected = newSelected.concat(selectedTicket.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedTicket.slice(0, selectedIndex), selectedTicket.slice(selectedIndex + 1))
    }

    setSelectedTicket(newSelected)
  }

  const isSelected = id => selectedTicket.indexOf(id) !== -1

  return (
    <>
      <NavigationBar />
      {tickets && tickets.pager && (tickets.pager.itemCount > 0 || type.length || status.length) ? (
        <Fragment>
          <div className={styles.headerContainer}>
            <Container className={styles.headerWrapper}>
              <div className={profile.toLowerCase() === 'carrier' && styles.headerButtonsContainer}>
                {profile.toLowerCase() === 'carrier' && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.newTicket}
                    disabled={!checkedTickets.length}
                    onClick={() => setOpenAssignDriver(true)}
                  >
                    ASSIGN DRIVER
                  </Button>
                )}
                <Button variant="contained" color="primary" className={styles.newTicket} onClick={handleClick}>
                  CREATE NEW TICKET
                </Button>
              </div>
              <TicketSidebar
                toggleSidebar={toggleSidebar}
                isSidebarOpen={isSidebarOpen}
                filters={filters}
                getTickets={filteredTicketList}
                setFilters={setFilters}
                head={tickets.head}
              />
            </Container>
          </div>

          <Container className={styles.root}>
            <Table className={styles.table}>
              <TicketListHeader onCheckAll={onCheckTicket} unCheckAll={checkAll} head={tickets.head} profile={profile} />
              <TableBody>
                {tickets.body.map(ticketBody => {
                  const ticketId = ticketBody.cells.find(cell => cell.key === 'ticketId').label
                  const ticketNumber = ticketBody.cells.find(cell => cell.key === 'Number').label
                  const ticketStatus = ticketBody.cells.find(cell => cell.key === 'Status').label

                  return (
                    <TicketRow
                      key={ticketId}
                      head={tickets.head}
                      ticket={ticketBody.cells}
                      ticketId={ticketId}
                      ticketNumber={ticketNumber}
                      ticketStatus={ticketStatus}
                      isTicketSelected={isSelected(ticketId)}
                      onHandleDelete={onHandleDelete}
                      onHandleEdit={onHandleEdit}
                      handleRowClick={handleRowClick}
                      security={security}
                      onCheckTicket={onCheckTicket}
                      ticketChecked={checkedTickets.includes(ticketId)}
                    />
                  )
                })}
              </TableBody>
            </Table>
            <Pagination pager={tickets.pager} onHandlePageChange={requestTickets} />
          </Container>
        </Fragment>
      ) : (
        <Container maxWidth="lg" className={styles.noData}>
          <Typography component="h4">You don’t have any tickets yet, let’s create your first one</Typography>

          <Button variant="contained" color="primary" className={styles.newTicket} onClick={handleClick}>
            CREATE NEW TICKET
          </Button>
        </Container>
      )}
      <AssignDriverModal
        drivers={drivers}
        onClose={onCloseAssignDriverModal}
        isOpen={openAssignDriverModal}
        selectedTickets={{
          ticketIds: checkedTickets,
          ticketNumbers: checkedTicketNumbers
        }}
        selectedTicketNumbers={checkedTicketNumbers}
        assignTicketsRequest={assignDriver}
        refreshTickets={refreshTickets}
        onSelectDriver={onSelectDriver}
        selectedDriver={selectedDriver}
      />
    </>
  )
}

export default withMissingPropsRedirection([unauthorizedRedirect], TicketList)
