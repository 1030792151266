// @actions constants
export const LOG_OUT = 'LOG_OUT'
export const GET_JWT_ERROR = 'GET_JWT_ERROR'
export const GET_JWT_REQUEST = 'GET_JWT_REQUEST'
export const GET_JWT_SUCCESS = 'GET_JWT_SUCCESS'

export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'

export const SEND_RESET_PASSWORD_LINK_ERROR = 'SEND_RESET_PASSWORD_LINK_ERROR'
export const SEND_RESET_PASSWORD_LINK_REQUEST = 'SEND_RESET_PASSWORD_LINK_REQUEST'
export const SEND_RESET_PASSWORD_LINK_SUCCESS = 'SEND_RESET_PASSWORD_LINK_SUCCESS'

// @configuration constants
export const GRANT_TYPE = 'password'
export const SECURITY_SCOPE = 'readyagsApi'

// @messages constants
export const COULD_NOT_LOGGED_IN = 'Could not be logged in'
export const RESET_PASSWORD_ERROR_MESSAGE = 'Could not reset the password'
export const RESET_PASSWORD_SUCCESS_MESSAGE = 'Password updated successfully'
export const ACCEPT_INVITATION_MESSAGE = 'Please log in to accept your invitation.'
export const RECOVERY_EMAIL_SENT_MESSAGE = 'Password recovery link sent successfully'
export const RECOVERY_EMAIL_SENT_MESSAGE_ERROR = 'Could not send the email with the instructions to reset the password.'
