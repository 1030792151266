// @constants
import * as constants from './constant'

export const updateFormValue = (key, value) => ({
  type: constants.UPDATE_FORM_VALUE,
  payload: { key, value }
})

export const resetFormValues = () => ({
  type: constants.RESET_FORM_VALUES
})
