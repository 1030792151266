// @vendors
import React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

// @styles
import styles from '../../pages/driver/components/qr/styles.module.sass'

const QRDetails = ({ qrCode }) => {
  return (
    <Container className={styles.container}>
      <div>
        <Typography component="h4">{qrCode.primaryDescription}</Typography>
        <img src={qrCode.qrCode} alt="qr" className={styles.fullImg} />
      </div>

      <div className={styles.button}>
        <Typography component="h4">{qrCode.secondaryDescription}</Typography>
        {qrCode.ticketNumber}
      </div>
    </Container>
  )
}

export default QRDetails
