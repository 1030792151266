const securityServerUrl = window.READYAGS_SECURITY_API_URI || (process.env.NODE_ENV === 'development' ? 'https://dev-secapi.readyags.com' : null)

const apiServerUrl = window.READYAGS_API_URI || (process.env.NODE_ENV === 'development' ? 'https://dev-api.readyags.com' : null)

export default {
  securityServer: {
    url: securityServerUrl,
    scope: 'readyagsApi'
  },
  apiServer: {
    url: apiServerUrl
  }
}
