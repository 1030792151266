export const signInInitialState = email => ({
  username: {
    value: email || '',
    valid: !!email,
    pristine: !email
  },
  password: {
    value: '',
    valid: false,
    pristine: true
  },
  showPassword: false
})

export const EMAIL_HELPER_TEXT = {
  email: null
}
export const ERROR_MESSAGE = 'Email format is incorrect'
