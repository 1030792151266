// @vendors
import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

// @components
import EmailInvite from '../email-invite'

// @styles
import styles from './styles.module.sass'

const DriversToolbar = ({ handleSuggestions }) => {
  const handleChange = event => {
    event.stopPropagation()
    handleSuggestions(event.target.value)
  }

  const [openInviteModal, setInviteModal] = useState(false)

  const onCloseInviteModal = () => {
    setInviteModal(false)
  }

  return (
    <>
      <Toolbar className={styles.toolbar}>
        <div className={styles.toolbarWrapper}>
          <div className={styles.search}>
            <div className={styles.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              classes={{
                root: styles.inputRoot,
                input: styles.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleChange}
            />
          </div>
          <Button className={styles.inviteButton} variant="outlined" onClick={() => setInviteModal(true)}>
            <PersonAddIcon className={styles.driverIcon} />
            <span className={styles.inviteButtonLabel}>{'+ '}INVITE DRIVER</span>
          </Button>
        </div>
      </Toolbar>
      <EmailInvite
        isOpen={openInviteModal}
        location={window.location.origin}
        onClose={onCloseInviteModal}
        url={`${window.location.origin}`}
        title="invite driver"
        inviteType="driver"
      />
    </>
  )
}

export default DriversToolbar
