export const GET_CARRIER_DRIVERS_REQUEST = 'GET_CARRIER_DRIVERS_REQUEST'
export const GET_CARRIER_DRIVERS_SUCCESS = 'GET_CARRIER_DRIVERS_SUCCESS'
export const GET_CARRIER_DRIVERS_ERROR = 'GET_CARRIER_DRIVERS_ERROR'
export const GET_CARRIER_DRIVERS_ERROR_MESSAGE = 'Could not get the list of drivers.'

export const UPDATE_CARRIER_SUCCESS = 'UPDATE_CARRIER_SUCCESS'

export const REMOVE_CARRIER_DRIVERS_REQUEST = 'REMOVE_CARRIER_DRIVER_REQUEST'
export const REMOVE_CARRIER_DRIVERS_SUCCESS = 'REMOVE_CARRIER_DRIVER_SUCCESS'
export const REMOVE_CARRIER_DRIVERS_SUCCESS_MESSAGE = 'Driver deleted successfully.'
export const REMOVE_CARRIER_DRIVERS_ERROR = 'REMOVE_CARRIER_DRIVER_ERROR'
export const REMOVE_CARRIER_DRIVERS_ERROR_MESSAGE = 'Could not delete the driver.'
