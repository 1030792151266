// @vendors
import React, { useState } from 'react'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import FormControl from '@material-ui/core/FormControl'

// @utils
import httpConfig from '../../../../../http/config'
import useMount from '../../../../../hooks/use-mount'

// @styles
import styles from './styles.module.sass'

const { apiServer } = httpConfig

const getStatesFromUS = () =>
  axios.get(`${apiServer.url}/api/Settings/states`).then(res =>
    res.data.map(stateFromUs => ({
      label: stateFromUs.code
    }))
  )

const getCountryCode = () =>
  axios.get(`${apiServer.url}/api/Settings/countryCodes`).then(res =>
    res.data.map(countryCode => ({
      label: countryCode.dial_code,
      countryName: countryCode.name,
      code: countryCode.code
    }))
  )

const ProfileInfo = ({ driver, editProfile, handleUpdateDriver, handleEditProfile, theme, clientName }) => {
  const buildDriverObject = () => {
    return {
      firstName: { value: driver.firstName, valid: true, pristine: true },
      lastName: { value: driver.lastName, valid: true, pristine: true },
      email: {
        value: driver.email,
        valid: true,
        pristine: true
      },
      countryCode: {
        value: driver.countryCode,
        valid: true,
        pristine: true
      },
      phoneNumber: { value: driver.phoneNumber, valid: true, pristine: true },
      licenseNumber: {
        value: driver.licenseNumber,
        valid: true,
        pristine: true
      },
      licenseStateCode: {
        value: driver.licenseStateCode,
        valid: true,
        pristine: true
      },
      licensePlate: {
        value: driver.licensePlate,
        valid: true,
        pristine: true
      },
      form: { valid: true }
    }
  }

  const [helperText, setHelperText] = useState({
    email: null
  })

  const [state, setState] = useState(buildDriverObject())
  const [controlState, setControlState] = useState({
    data: [],
    label: 'Driver’s License State',
    placeholder: 'Select State'
  })

  const [countryCode, setCountryCode] = useState({
    data: [],
    label: 'Country Code',
    placeholder: 'Select Country Code'
  })

  const handleChange = event => {
    const { target } = event
    const { form, name, value: controlValue } = target

    let value = controlValue
    let valid = event.target.checkValidity()

    if (name === 'firstName' || name === 'lastName') {
      value = value.replace(/\d+/g, '')
    } else if (name === 'phoneNumber') {
      value = value.replace(/[^\w\s]|[a-zA-Z]|\_/gi, '')
    } else if (name === 'email') {
      const emailFormat = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
      valid = emailFormat.test(value)

      if (!valid) {
        const ht = {
          email: 'Email format is incorrect'
        }

        event.target.setCustomValidity(ht[name])
        setHelperText({ ...helperText, [name]: event.target.validationMessage })
      } else {
        event.target.setCustomValidity('')
        setHelperText({ ...helperText, [name]: null })
      }
    }

    setState({
      ...state,
      [name]: { ...state[name], value, valid, pristine: false },
      form: { valid: form.checkValidity() }
    })
  }

  const handleLicenseStateCode = event => {
    setState({
      ...state,
      licenseStateCode: {
        ...state.licenseStateCode,
        value: event.target.value,
        valid: true,
        pristine: false
      }
    })
  }

  const handleCountryCode = event => {
    setState({
      ...state,
      countryCode: {
        ...state.countryCode,
        value: event.target.value,
        valid: true,
        pristine: false
      }
    })
  }

  const getValues = () => {
    let values = {}

    Object.keys(state).forEach(name => {
      const prop = { [name]: state[name].value }
      values = { ...values, ...prop }
    })

    return values
  }

  useMount(() => {
    getStatesFromUS().then(states => {
      setControlState({
        data: states,
        label: 'Driver’s License State',
        placeholder: 'Select State'
      })
    })

    getCountryCode().then(countryCode => {
      setCountryCode({
        data: countryCode,
        label: 'Country Code*',
        placeholder: 'Select Country Code'
      })
    })
  })

  const onSave = event => {
    event.preventDefault()

    const driverFirstNameValidation = driver.firstName !== state.firstName.value
    const driverLastNameValidation = driver.lastName !== state.lastName.value
    const driverEmailValidation = driver.email !== state.email.value
    const driverCountryCodeValidation = driver.countryCode !== state.countryCode.value
    const driverPhoneNumberValidation = driver.phoneNumber !== state.phoneNumber.value
    const driverLicenseNumberValidation = driver.licenseNumber !== state.licenseNumber.value
    const driverLicenseStateCodeValidation = driver.licenseStateCode !== state.licenseStateCode.value
    const driverLicensePlateValidation = driver.licensePlate !== state.licensePlate.value

    if (
      driverFirstNameValidation ||
      driverLastNameValidation ||
      driverEmailValidation ||
      driverCountryCodeValidation ||
      driverPhoneNumberValidation ||
      driverLicenseNumberValidation ||
      driverLicenseStateCodeValidation ||
      driverLicensePlateValidation
    ) {
      handleUpdateDriver({ ...getValues() })
    }
  }

  const onCancel = () => {
    handleEditProfile(false)
    setState({ ...buildDriverObject() })
  }

  return (
    <CardContent classes={{ root: styles.cardContent }}>
      {!editProfile && (
        <div className={styles.editButtonWrapper}>
          <Button variant="contained" color="primary" onClick={() => handleEditProfile(!editProfile)}>
            Edit
          </Button>
        </div>
      )}

      {(editProfile && (
        <>
          <Typography gutterBottom variant="subtitle1" component="h2" className={styles.title}>
            Edit Your Info
          </Typography>
          <form noValidate autoComplete="off" className={styles.form} onSubmit={onSave}>
            <TextField
              required
              error={!state.firstName.valid && !state.firstName.pristine}
              name="firstName"
              label="First Name"
              className={styles.textField}
              value={state.firstName.value}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              required
              error={!state.lastName.valid && !state.lastName.pristine}
              name="lastName"
              label="Last Name"
              className={styles.textField}
              value={state.lastName.value}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              required
              error={!state.email.valid && !state.email.pristine}
              name="email"
              label="E-mail"
              className={styles.email}
              value={state.email.value}
              helperText={helperText.email}
              onChange={handleChange}
              margin="normal"
            />
            <div className={styles.licenseGroup}>
              <FormControl className={styles.countryCodeItem}>
                <InputLabel name="license-state-label">Country Code*</InputLabel>
                <Select
                  name="country-code"
                  label={countryCode.label}
                  placeholder={countryCode.placeholder}
                  value={state.countryCode.value}
                  renderValue={value => value}
                  onChange={handleCountryCode}
                >
                  {countryCode.data.map((country, index) => (
                    <MenuItem className={styles.countryCodeOptions} key={`${country.label}-${index}`} value={country.code}>
                      <span className={styles.countryCodeValue}>{country.label}</span>
                      <span className={styles.countryCodeCountry}>{`(${country.code}) - ${country.countryName}`}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                required
                error={!state.phoneNumber.valid && !state.phoneNumber.pristine}
                name="phoneNumber"
                label="Phone Number"
                className={styles.licenseItem}
                value={state.phoneNumber.value}
                onChange={handleChange}
                type="text"
              />
              <FormControl className={styles.licenseItem}>
                <InputLabel name="license-state-label">Driver’s License State (Optional)</InputLabel>
                <Select
                  name="license-state"
                  label={controlState.label}
                  placeholder={controlState.placeholder}
                  value={state.licenseStateCode.value}
                  onChange={handleLicenseStateCode}
                >
                  {controlState.data.map(stateFromUs => (
                    <MenuItem key={stateFromUs.label} value={stateFromUs.label}>
                      {stateFromUs.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                error={!state.licenseNumber.valid && !state.licenseNumber.pristine}
                name="licenseNumber"
                label="Driver’s License Number (Optional)"
                className={styles.licenseItem}
                value={state.licenseNumber.value}
                onChange={handleChange}
              />
            </div>
            <TextField
              error={!state.licensePlate.valid && !state.licensePlate.pristine}
              name="licensePlate"
              label="License Plate (Optional)"
              value={state.licensePlate.value}
              onChange={handleChange}
              className={styles.licensePlate}
              helperText={
                clientName.toLowerCase() === 'budexpress' && (
                  <Typography component="p" className={styles.message} style={{ color: theme && theme.theme.successColor }}>
                    Add License Plate to allow for quicker pre-check process.
                  </Typography>
                )
              }
            />

            <Grid container justify="flex-end" alignItems="center" className={styles.buttonsContainer}>
              <Button size="medium" onClick={onCancel}>
                CANCEL
              </Button>
              <Button type="submit" size="medium" disabled={!state.form.valid}>
                SAVE
              </Button>
            </Grid>
          </form>
        </>
      )) || (
        <Grid container justify="space-around" wrap="wrap" className={styles.profileContainer}>
          <div className={styles.wraper}>
            <label>First Name</label>
            <p>{state.firstName.value}</p>
          </div>
          <div className={styles.wraper}>
            <label>Last Name</label>
            <p>{state.lastName.value}</p>
          </div>
          <div className={styles.emailWraper}>
            <label>Email</label>
            <p>{state.email.value}</p>
          </div>
          <div className={styles.licenseGroup}>
            <div className={styles.wraper}>
              <label>Country Code</label>
              <p>{state.countryCode.value}</p>
            </div>
            <div className={styles.wraper}>
              <label>Phone Number</label>
              <p>{state.phoneNumber.value}</p>
            </div>
            <div className={styles.wraper}>
              <label>Driver’s License State (Optional)</label>
              <p>{state.licenseStateCode.value}</p>
            </div>
            <div className={styles.wraper}>
              <label>Driver’s License Number (Optional)</label>
              <p>{state.licenseNumber.value}</p>
            </div>
          </div>
          <div className={styles.emailWraper}>
            <label>License Plate (Optional)</label>
            <p>{state.licensePlate.value}</p>
            {clientName.toLowerCase() === 'budexpress' && (
              <Typography component="p" className={styles.message} style={{ color: theme && theme.theme.successColor }}>
                Add License Plate to allow for quicker pre-check process.
              </Typography>
            )}
          </div>
        </Grid>
      )}
    </CardContent>
  )
}

export default ProfileInfo
