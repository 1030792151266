// @vendors
import { connect } from 'react-redux'

// @components
import PasswordRecovery from './password-recovery'

// @actions
import { sendResetPasswordLink } from '../../actions'

const mapStateToProps = state => ({
  security: state.security
})

const mapDispatchToProps = {
  sendResetPasswordLink
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery)
