import axios from 'axios'
import querystring from 'querystring'

// @utils
import httpConfig from '../../../http/config'
import { standardRequestHandler, standardSuccessHandler, standardErrorHandler } from '../../../http/utils'
import { createApiRequestHeaders } from '../../../http/utils'

// @constants
import * as constants from '../constant'

const { apiServer } = httpConfig

export const sendEmailInvite = (email, url, inviteType) => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType, Id, Profile: profile } = getState().security

  const { clientId } = getState().clientData

  const params = {
    email,
    url
  }

  if (profile.toLowerCase() !== 'client') params.clientId = clientId

  const config = {
    headers: {
      ...createApiRequestHeaders(token, tokenType),
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  standardRequestHandler(dispatch, constants.SEND_INVITATION_EMAIL_REQUEST)

  axios
    .post(
      `${apiServer.url}/api/${profile.replace(profile.charAt(0), profile.charAt(0).toUpperCase())}s/${
        profile.toLowerCase() === 'client' ? clientId : Id
      }/${inviteType === 'driver' ? 'InviteDriver' : 'InviteCarrier'}?`,
      querystring.stringify(params),
      config
    )
    .then(res => {
      standardSuccessHandler(dispatch, constants.SEND_INVITATION_EMAIL_SUCCESS, null, constants.invitationSentSuccessfully(inviteType))
    })
    .catch(err => standardErrorHandler(dispatch, err, constants.SEND_INVITATION_EMAIL_ERROR))
}
