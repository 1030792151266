// @vendors
import React, { useState, useEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar'

// @components
import NotificationContent from './components/notification-content'

// @constants
import { NOTIFICATION_TYPES } from './constant'

const Notifications = ({ notifications, dismissNotification }) => {
  const [currentNotification, setCurrentNotification] = useState(null)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)

  useEffect(() => {
    if (!currentNotification && notifications.length > 0) {
      setCurrentNotification(notifications[0])
      dismissNotification(notifications[0].id)
      setSnackbarOpen(true)
    }
  }, [currentNotification, notifications, dismissNotification])

  const onClose = () => {
    setSnackbarOpen(false)
    setTimeout(() => setCurrentNotification(null), 200)
  }

  // prettier-ignore
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      style={{ zIndex: 2000, position: 'absolute', top: 70 }}
      open={isSnackbarOpen}
      autoHideDuration={2500}
      onClose={onClose}
      onEntering={
        isSnackbarOpen && 
        currentNotification &&
        (currentNotification.type || '').toLowerCase() === NOTIFICATION_TYPES.ERROR.toLowerCase() ? 
        window.scrollTo(0, 0) : 
        () => {}
      }
    >
      <NotificationContent
        type={currentNotification ? currentNotification.type : NOTIFICATION_TYPES.INFO}
        message={currentNotification ? currentNotification.message : 'Nothing to report.'}
        onClose={onClose}
      />
    </Snackbar>
  )
}

export default Notifications
