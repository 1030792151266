// @constants
import * as constants from './constant'

const initialState = null

const securityReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_JWT_SUCCESS: {
      return { ...action.payload }
    }
    case constants.RESET_PASSWORD_SUCCESS: {
      return { ...action.payload }
    }
    case constants.LOG_OUT: {
      return null
    }
    default: {
      return state
    }
  }
}

export default securityReducer
