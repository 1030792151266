// @vendors
import uuid from 'uuid/v4'

// @constants
import * as constants from './constant'

const { NOTIFICATION_TYPES } = constants

export const addNotification = (type, message) => ({
  type: constants.ADD_NOTIFICATION,
  payload: {
    id: uuid(),
    type,
    message
  }
})

export const dismissNotification = id => ({
  type: constants.DISMISS_NOTIFICATION,
  payload: id
})

export const notify = message => addNotification(NOTIFICATION_TYPES.INFO, message)
export const notifyError = message => addNotification(NOTIFICATION_TYPES.ERROR, message)
export const notifySuccess = message => addNotification(NOTIFICATION_TYPES.SUCCESS, message)
