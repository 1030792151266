// @vendors
import { connect } from 'react-redux'

// @components
import NavigationBar from './navigation-bar'

// @actions
import { logout } from '../../security/actions'

// @utils
import { getSecurity } from '../../security'
import { getDriver } from '../../pages/driver/driver-profile/selectors'
import { getThemingClientData } from '../../utils/theming/selectors'
import { getCarrier } from '../../pages/carrier/carrier-profile/selector'
import { resetDriver } from '../../pages/driver/driver-profile/actions'
import { resetCarrier } from '../../pages/carrier/carrier-profile/actions'
import { getClient } from '../../http/selector'

const mapStateToProps = state => ({
  carrier: getCarrier(state),
  driver: getDriver(state),
  client: getClient(state),
  themingClientData: getThemingClientData(state),
  user: getSecurity(state)
})

const mapDispatchToProps = {
  logout,
  resetDriver,
  resetCarrier
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
