// @constants
import * as constants from './constant'

const initialState = {}

const clientDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CLIENT_DATA_SUCCESS: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}

export default clientDataReducer
