export const formatToMultiSelectValue = values => (values ? values.join('||') : '')

export const formatFromMultiSelectValue = values => (values ? values.split('||') : [])

export const validate = ({ input, min, max, selectedItems, setErrorInput, setHelperText, isRequired, requiredMessage }) => {
  if (selectedItems.length >= max) {
    input.setCustomValidity(`You have selected the max number of items(${max}) for this field`)

    setErrorInput(false)
    setHelperText(input.validationMessage)
  } else {
    input.setCustomValidity('')

    setErrorInput(false)
    setHelperText(null)
  }
}

export const getSuggestions = (value, { showEmpty = false, suggestions = [] } = {}) => {
  let count = 0

  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length

  return inputLength === 0 && !showEmpty
    ? []
    : suggestions.filter(suggestion => {
        if (suggestion && suggestion.label) {
          const keep = count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue

          if (keep) {
            count += 1
          }

          return keep
        }

        return false
      })
}
