// @constants
import * as constants from './constant'

export const addToGlobalSpinner = () => ({
  type: constants.ADD_TO_GLOBAL_SPINNER
})

export const subtractFromGlobalSpinner = () => ({
  type: constants.SUBTRACT_FROM_GLOBAL_SPINNER
})
