// @vendors
import React from 'react'
import { Redirect } from 'react-router-dom'

const RestrictedRoutes = ({ children, path, profile }) => {
  const isRestricted = !path.includes(`${window.location.host}/${profile}`)
  const redirectTo = `/${profile}`
  return isRestricted ? <Redirect to={redirectTo} /> : children
}

export default RestrictedRoutes
