// @vendors
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

// @utils
import useMount from '../../../../hooks/use-mount'
import withMissingPropsRedirection from '../../../../utils/redirection/with-missing-props-redirection'

// @constants
import { unauthorizedRedirect } from '../../../../utils/redirection/constant'

const Ticket = ({ requestNewForm, requestForm, resetFormFetchStatus, security, form, match, resetFormValues }) => {
  const { ticketId } = match.params
  const [readyToRedirect, setReadyToRedirect] = useState(false)

  const reload = () => {
    const noForm = !form.steps || !form.ticketId
    const { ticketId } = match.params
    const differentForm = ticketId !== form.ticketId

    if (form.fetched && (noForm || differentForm)) {
      resetFormFetchStatus()
      resetFormValues()
    } else {
      setReadyToRedirect(true)
    }
  }

  useMount(reload)

  useEffect(() => {
    if (!form.fetched) {
      if (ticketId === 'new') {
        requestNewForm()
      } else {
        requestForm(ticketId)
      }

      setReadyToRedirect(true)
    }
  }, [form.fetched])

  return readyToRedirect && form.steps && form.ticketId ? (
    <Redirect to={`/${security.Profile.toLowerCase()}/tickets/${form.ticketId}/steps/${form.steps[0].id}`} />
  ) : null
}

export default withMissingPropsRedirection([unauthorizedRedirect], Ticket)
