// @vendors
import React, { useState } from 'react'
import Container from '@material-ui/core/Container'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import Typography from '@material-ui/core/Typography'

// @components
import ListItem from './list-item'
import Toolbar from './toolbar'
import DeleteDialog from '../delete-dialog'

// @styles
import styles from './styles.module.sass'

const DriverTable = ({ drivers, handleSuggestions, onRemoveDriver, selectMode, selectedDriver, onSelectDriver, refresDrivers }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [idRelationShip, setIdRelationShip] = useState(null)

  const onOpenHandler = id => {
    setIdRelationShip(id)
    setOpenDeleteDialog(true)
  }

  const onCloseHandler = () => {
    setOpenDeleteDialog(false)
  }

  const onDeleteHandler = () => {
    onRemoveDriver(idRelationShip).then(refresDrivers)
    onCloseHandler()
  }

  return (
    <>
      {!selectMode && <Toolbar handleSuggestions={handleSuggestions} />}
      <Container className={styles.root}>
        <Table className={styles.table}>
          {!selectMode && (
            <TableHead className={styles.tableHead}>
              <TableRow>
                <TableCell />
                <TableCell align="center">
                  <Typography variant="body1">First Name</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1">Last Name</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1">Email</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1">Phone Number</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1">Current Tickets</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1">Completed Trips</Typography>
                </TableCell>
                <TableCell align="center" />
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {drivers && drivers.length ? (
              drivers.map((driver, index) => (
                <ListItem
                  key={index}
                  driver={driver}
                  onRemoveDriver={onOpenHandler}
                  selectMode={selectMode}
                  onSelectDriver={onSelectDriver}
                  selectedDriver={selectedDriver}
                />
              ))
            ) : (
              <Typography variant="body1">No drivers found.</Typography>
            )}
          </TableBody>
        </Table>
      </Container>
      <DeleteDialog
        isOpen={openDeleteDialog}
        title="Delete Driver"
        textBody="Are you sure you want to remove the driver?"
        onDelete={onDeleteHandler}
        onClose={onCloseHandler}
      />
    </>
  )
}

export default DriverTable
