// @vendor
import React from 'react'
import { Route } from 'react-router-dom'

// @components
import QR from './components/qr'
import Steps from './components/steps'
import DriverLoader from './driver-loader'
import Ticket from './driver-tickets/ticket'
import DriverProfile from './driver-profile'
import TicketList from '../../common-components/tickets-list'
import AcceptDriverInvitation from '../../common-components/accept-invitation'

// @constants
import { PATH_NAME } from './constant'

const DriverApp = () => [
  <Route key={`${PATH_NAME}/`} exact path={`${PATH_NAME}/`} component={DriverLoader} />,
  <Route key={`${PATH_NAME}/profile`} exact path={`${PATH_NAME}/profile`} component={DriverProfile} />,
  <Route key={`${PATH_NAME}/tickets`} exact path={`${PATH_NAME}/tickets`} component={TicketList} />,
  <Route key={`${PATH_NAME}/accept`} exact path={`${PATH_NAME}/accept`} component={AcceptDriverInvitation} />,
  <Route key={`${PATH_NAME}/tickets/:ticketId`} path={`${PATH_NAME}/tickets/:ticketId`} component={Ticket} />,
  <Route key={`${PATH_NAME}/tickets/:ticketId/steps/:stepId`} path={`${PATH_NAME}/tickets/:ticketId/steps/:stepId`} component={Steps} />,
  <Route key={`${PATH_NAME}/tickets/:ticketId/qr`} path={`${PATH_NAME}/tickets/:ticketId/qr`} component={QR} />
]

export default DriverApp
