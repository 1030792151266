// @vendors
import React, { useState } from 'react'

// @utils
import useMount from '../../../../hooks/use-mount'

// @components
import NavigationBar from '../../../../common-components/navigation-bar'
import DriverTable from '../../../../common-components/driver-list'

const DriverList = ({ requestDrivers, removeRelationshipRequest }) => {
  const [drivers, setDrivers] = useState([])
  const [suggestions, setSuggestions] = useState(null)

  const handleSuggestions = inputValue => {
    const newSuggestions = drivers.filter(
      driver => driver.firstName.toLowerCase().includes(inputValue.toLowerCase()) || driver.lastName.toLowerCase().includes(inputValue.toLowerCase())
    )

    setSuggestions(newSuggestions)
  }

  const refreshDrivers = () => {
    ;(async () => {
      const response = await requestDrivers()
      setDrivers(response)
    })()
  }

  useMount(() => {
    ;(async () => {
      const response = await requestDrivers()
      setDrivers(response)
    })()
  })

  return (
    <>
      <NavigationBar />
      <DriverTable
        drivers={suggestions || drivers}
        handleSuggestions={handleSuggestions}
        onRemoveDriver={removeRelationshipRequest}
        refresDrivers={refreshDrivers}
      />
    </>
  )
}

export default DriverList
