// @vendors
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import Modal from '@material-ui/core/Modal'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import Container from '@material-ui/core/Container'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AttatchmentIcon from '@material-ui/icons/Attachment'
import CircularProgress from '@material-ui/core/CircularProgress'

// @utils
import httpConfig from '../../../../../../http/config'
import { createApiRequestHeaders } from '../../../../../../http/utils'

// @styles
import styles from './styles.module.sass'

const AttatchmentTicketCell = ({ ticketCell, ticketId, security }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [attatchmentsFecthed, setAttatchmentsFetched] = useState(false)
  const [attatchments, setAttatchments] = useState()

  const onClick = e => {
    e.preventDefault()
    setIsModalOpen(true)
  }

  useEffect(() => {
    if (isModalOpen && !attatchmentsFecthed) {
      const { access_token: token, token_type: tokenType } = security
      const params = {
        headers: createApiRequestHeaders(token, tokenType)
      }

      axios
        .get(`${httpConfig.apiServer.url}/api/Tickets/${ticketId}/attachments`, params)
        .then(res => {
          setAttatchmentsFetched(true)
          setAttatchments(res.data)
        })
        .catch(err => {
          setAttatchmentsFetched(true)
        })
    }
  }, [isModalOpen, attatchmentsFecthed, setAttatchmentsFetched, setAttatchments, security, ticketId])

  const getAttatchments = () => {
    if (attatchments) {
      return attatchments.length > 0 ? (
        attatchments.map(attatchment => (
          <ListItem button component="a" href={attatchment.url} key={attatchment.id}>
            <ListItemIcon>
              <AttatchmentIcon />
            </ListItemIcon>
            <ListItemText primary={attatchment.attachmentName} />
          </ListItem>
        ))
      ) : (
        <ListItem>
          <ListItemText primary="No attatchments to show" />
        </ListItem>
      )
    }

    return (
      <ListItem>
        <ListItemText primary="There was an error fetching attatchments :(" />
      </ListItem>
    )
  }

  return (
    <>
      {ticketCell.label.startsWith('No') ? (
        <>{ticketCell.label}</>
      ) : (
        <Link href="#" onClick={onClick}>
          {ticketCell.label}
        </Link>
      )}
      <Modal className={styles.modal} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={styles.modalContentWrapper}>
          <div>
            <Container maxWidth="lg" className={styles.modalContent}>
              {!attatchmentsFecthed ? (
                <Grid style={{ height: '200px' }} container justify="center" alignItems="center">
                  <Grid item>
                    <CircularProgress color="primary" />
                  </Grid>
                </Grid>
              ) : (
                <Box px={0.5} py={1.5}>
                  <List component="ul">
                    <ListItemText primary="Title" />
                  </List>
                  <Divider />
                  <List component="ul">{getAttatchments()}</List>
                </Box>
              )}
            </Container>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AttatchmentTicketCell
