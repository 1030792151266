// @vendors
import React from 'react'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

// @styles
import styles from './styles.module.sass'

const GeneralBusySpinner = ({ isBusy }) =>
  isBusy && (
    <Box className={styles.fullscreen}>
      <CircularProgress />
    </Box>
  )

export default GeneralBusySpinner
