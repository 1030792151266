// @vendors
import axios from 'axios'

// @constants
import * as constants from './constant'

// @utils
import httpConfig from '../../../http/config'
import { standardRequestHandler, standardSuccessHandler, standardErrorHandler, createApiRequestHeaders } from '../../../http/utils'

const { apiServer } = httpConfig

export const requestDrivers = () => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType, Id: carrierId } = getState().security

  const { clientId } = getState().clientData

  const config = {
    headers: createApiRequestHeaders(token, tokenType),
    params: {
      clientId: clientId
    }
  }

  standardRequestHandler(dispatch, constants.GET_CARRIER_DRIVERS_REQUEST, {
    carrierId
  })

  return axios
    .get(`${apiServer.url}/api/Carriers/${carrierId}/drivers`, config)
    .then(res => {
      standardSuccessHandler(dispatch, constants.GET_CARRIER_DRIVERS_SUCCESS, {
        drivers: res.data
      })
      return res.data
    })
    .catch(err => {
      standardErrorHandler(dispatch, err, constants.GET_CARRIER_DRIVERS_ERROR, constants.GET_CARRIER_DRIVERS_ERROR_MESSAGE)
    })
}

export const removeDriverRelationshipRequest = idRelationship => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType } = getState().security

  const config = {
    headers: createApiRequestHeaders(token, tokenType)
  }

  standardRequestHandler(dispatch, constants.REMOVE_CARRIER_DRIVERS_REQUEST, {
    idRelationship
  })

  return axios
    .delete(`${apiServer.url}/api/Invite/${idRelationship}/relationship`, config)
    .then(() => {
      standardSuccessHandler(dispatch, constants.REMOVE_CARRIER_DRIVERS_SUCCESS, null, constants.REMOVE_CARRIER_DRIVERS_SUCCESS_MESSAGE)
    })
    .catch(err => {
      standardErrorHandler(dispatch, err, constants.REMOVE_CARRIER_DRIVERS_ERROR, constants.REMOVE_CARRIER_DRIVERS_ERROR_MESSAGE)
    })
}
