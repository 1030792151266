// @vendors
import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import { Container } from '@material-ui/core'

// @utils
import useMount from '../../../hooks/use-mount'

// @components
import FacilityRow from './components/facility-row'
import FacilitiesToolbar from './components/toolbar'
import NavigationBar from '../../../common-components/navigation-bar'

// @constants
import { PATH_NAME } from '../constant'

// @styles
import styles from './styles.module.sass'

const Facilities = ({ history, facilities, getFacilities }) => {
  const [suggestions, setSuggestions] = useState(null)

  const handleSuggestions = inputValue => {
    const newSuggestions = facilities.filter(facility => facility.name.toLowerCase().includes(inputValue.toLowerCase()))

    setSuggestions(newSuggestions)
  }

  const newFacility = () => {
    history.push(`${PATH_NAME}/facilities/new`)
  }

  const handleEdit = facilityId => event => {
    event.stopPropagation()
    history.push(`${PATH_NAME}/facilities/${facilityId}/edit`)
  }

  useMount(() => {
    getFacilities()
  })

  return (
    <>
      <NavigationBar />
      <FacilitiesToolbar handleSuggestions={handleSuggestions} newFacility={newFacility} />
      <Container className={styles.root}>
        <Table className={styles.table} aria-label="facilities table">
          <TableHead className={styles.tableHead}>
            <TableRow>
              <TableCell />
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Address</TableCell>
              <TableCell align="center">Hook Url</TableCell>
              <TableCell align="center">Hook Url Authentication Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(suggestions || (facilities && facilities)).map((facility, index) => (
              <FacilityRow key={index} facility={facility} handleEdit={handleEdit} />
            ))}
          </TableBody>
        </Table>
      </Container>
    </>
  )
}

export default Facilities
