// @vendors
import React from 'react'
import { Route } from 'react-router-dom'

// @constants
import { PATH_NAME } from './constants'

// @components
import CarrierProfile from './carrier-profile'
import CarrierLoader from './carrier-loader'
import TicketList from '../../common-components/tickets-list'
import Ticket from '../driver/driver-tickets/ticket'
import Steps from '../driver/components/steps'
import Qr from '../driver/components/qr'
import DriverList from './driver-list'
import AcceptCarrierInvitation from '../../common-components/accept-invitation'

const CarrierApp = () => [
  <Route key={`${PATH_NAME}`} path={`${PATH_NAME}/`} component={CarrierLoader} />,
  <Route key={`${PATH_NAME}/drivers`} exact path={`${PATH_NAME}/drivers`} component={DriverList} />,
  <Route key={`${PATH_NAME}/profile`} exact path={`${PATH_NAME}/profile`} component={CarrierProfile} />,
  <Route key={`${PATH_NAME}/tickets`} exact path={`${PATH_NAME}/tickets`} component={TicketList} />,
  <Route key={`${PATH_NAME}/accept`} exact path={`${PATH_NAME}/accept`} component={AcceptCarrierInvitation} />,
  <Route key={`${PATH_NAME}/tickets/:ticketId`} path={`${PATH_NAME}/tickets/:ticketId`} component={Ticket} />,
  <Route key={`${PATH_NAME}/tickets/:ticketId/steps/:stepId`} path={`${PATH_NAME}/tickets/:ticketId/steps/:stepId`} component={Steps} />,
  <Route key={`${PATH_NAME}/tickets/:ticketId/qr`} path={`${PATH_NAME}/tickets/:ticketId/qr`} component={Qr} />
]

export default CarrierApp
