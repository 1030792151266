// @vendors
import React, { useState } from 'react'
import Container from '@material-ui/core/Container'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import Typography from '@material-ui/core/Typography'

// @utils
import useMount from '../../../../hooks/use-mount'

// @components
import CarrierListItem from './list-item'
import NavigationBar from '../../../../common-components/navigation-bar'
import CarriersToolbar from './toolbar'
import DeleteDialog from '../../../../common-components/delete-dialog'

// @styles
import styles from './styles.module.sass'

const CarrierList = ({ requestCarriers, removeCarrier, refresDrivers }) => {
  const [carriers, setCarriers] = useState([])
  const [suggestions, setSuggestions] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [idRelationShip, setIdRelationShip] = useState(null)

  const handleSuggestions = inputValue => {
    const newSuggestions = carriers.filter(
      carrier =>
        carrier.carrierCode.toLowerCase().includes(inputValue.toLowerCase()) || carrier.carrierName.toLowerCase().includes(inputValue.toLowerCase())
    )

    setSuggestions(newSuggestions)
  }

  const getCarriers = () => {
    ;(async () => {
      const response = await requestCarriers()
      setCarriers(response)
    })()
  }

  useMount(() => {
    getCarriers()
  })

  const onOpenHandler = id => {
    setIdRelationShip(id)
    setOpenDeleteDialog(true)
  }

  const onCloseHandler = () => {
    setOpenDeleteDialog(false)
  }

  const onDeleteHandler = () => {
    removeCarrier(idRelationShip).then(getCarriers)
    onCloseHandler()
  }

  return (
    <>
      <NavigationBar />
      <CarriersToolbar handleSuggestions={handleSuggestions} />
      <Container className={styles.root}>
        <Table className={styles.table}>
          <TableHead className={styles.tableHead}>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                <Typography variant="body1">Code</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1">Name</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1">Email</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1">Phone Number</Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {suggestions || carriers.length ? (
              (suggestions || carriers).map((carrier, index) => {
                return <CarrierListItem onRemoveDriver={onOpenHandler} key={index} carrier={carrier} />
              })
            ) : (
              <Typography variant="h6">No carriers found.</Typography>
            )}
          </TableBody>
        </Table>
        <DeleteDialog
          isOpen={openDeleteDialog}
          title="Delete Carrier"
          textBody="Are you sure you want to remove the carrier?"
          onDelete={onDeleteHandler}
          onClose={onCloseHandler}
        />
      </Container>
    </>
  )
}

export default CarrierList
