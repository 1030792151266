// @vendors
import { connect } from 'react-redux'

// @utils
import { getTicketList } from './selector'
import { getSecurity } from '../../security'

// @actions
import { deleteTicket, getTickets, setFilters } from './actions'
import { assignDriver } from './components/assign/actions'

// @components
import TicketList from './ticket-list'
import { getFilters } from './selector'
import { getCarrier } from '../../pages/carrier/carrier-profile/selector'

const mapStateToProps = (state, ownProps) => {
  const carrier = getCarrier(state)
  const drivers = carrier && carrier.drivers && carrier.drivers
  return {
    security: getSecurity(state),
    tickets: getTicketList(state),
    filters: getFilters(state),
    selectMode: ownProps.selectMode,
    drivers
  }
}

const mapDispatchToProps = {
  getTickets,
  deleteTicket,
  setFilters,
  assignDriver
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketList)
