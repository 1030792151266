export const GET_DRIVER_REQUEST = 'GET_DRIVER_REQUEST'
export const GET_DRIVER_SUCCESS = 'GET_DRIVER_SUCCESS'
export const GET_DRIVER_ERROR = 'GET_DRIVER_ERROR'
export const GET_DRIVER_ERROR_MESSAGE = 'Could not get the driver.'

export const CREATE_DRIVER_REQUEST = 'CREATE_DRIVER_REQUEST'
export const CREATE_DRIVER_SUCCESS = 'CREATE_DRIVER_SUCCESS'
export const CREATE_DRIVER_SUCCESS_MESSAGE = 'Driver created successfully.'
export const CREATE_DRIVER_ERROR = 'CREATE_DRIVER_ERROR'
export const CREATE_DRIVER_ERROR_MESSAGE = 'Could not create the driver.'

export const UPDATE_DRIVER_REQUEST = 'UPDATE_DRIVER_REQUEST'
export const UPDATE_DRIVER_SUCCESS = 'UPDATE_DRIVER_SUCCESS'
export const UPDATE_DRIVER_SUCCESS_MESSAGE = 'Driver updated successfully.'
export const UPDATE_DRIVER_ERROR = 'UPDATE_DRIVER_ERROR'
export const UPDATE_DRIVER_ERROR_MESSAGE = 'Could not update the driver.'

export const UPDATE_DRIVER_AVATAR_REQUEST = 'UPDATE_DRIVER_AVATAR_REQUEST'
export const UPDATE_DRIVER_AVATAR_SUCCESS = 'UPDATE_DRIVER_AVATAR_SUCCESS'
export const UPDATE_DRIVER_AVATAR_SUCCESS_MESSAGE = 'Avatar updated successfully.'
export const UPDATE_DRIVER_AVATAR_ERROR = 'UPDATE_DRIVER_AVATAR_ERROR'
export const UPDATE_DRIVER_AVATAR_ERROR_MESSAGE = 'Could not update the driver avatar.'

export const RESET_DRIVER = 'RESET_DRIVER'
