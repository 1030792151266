// @vendors
import axios from 'axios'

// @constants
import { REQUEST_CARRIERS, REMOVE_RELATIONSHIP } from './constant'

// @utils
import httpConfig from '../../http/config'
import { standardRequestHandler, standardSuccessHandler, standardErrorHandler, createApiRequestHeaders } from '../../http/utils'

const { apiServer } = httpConfig

export const requestCarriers = () => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType } = getState().security
  const { clientId } = getState().clientData

  const config = {
    headers: createApiRequestHeaders(token, tokenType)
  }

  standardRequestHandler(dispatch, REQUEST_CARRIERS.REQUESTED, {
    clientId: clientId
  })

  return axios
    .get(`${apiServer.url}/api/Clients/${clientId}/Carriers`, config)
    .then(res => {
      standardSuccessHandler(dispatch, REQUEST_CARRIERS.SUCCESS, res.data)
      return res.data
    })
    .catch(err => {
      standardErrorHandler(dispatch, err, REQUEST_CARRIERS.ERROR, 'Could not get the list of carriers')
    })
}

export const requestDrivers = () => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType } = getState().security
  const { clientId } = getState().clientData

  const config = {
    headers: createApiRequestHeaders(token, tokenType)
  }

  standardRequestHandler(dispatch, REQUEST_CARRIERS.REQUESTED, {
    clientId: clientId
  })

  return axios
    .get(`${apiServer.url}/api/Clients/${clientId}/Drivers`, config)
    .then(res => {
      standardSuccessHandler(dispatch, REQUEST_CARRIERS.SUCCESS, res.data)
      return res.data
    })
    .catch(err => {
      standardErrorHandler(dispatch, err, REQUEST_CARRIERS.ERROR, 'Could not get the list of drivers')
    })
}

export const removeRelationshipRequest = idRelationship => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType } = getState().security

  const config = {
    headers: createApiRequestHeaders(token, tokenType)
  }

  standardRequestHandler(dispatch, REMOVE_RELATIONSHIP.REQUESTED, {
    idRelationship
  })

  return axios
    .delete(`${apiServer.url}/api/Invite/${idRelationship}/relationship`, config)
    .then(() => {
      standardSuccessHandler(dispatch, REMOVE_RELATIONSHIP.SUCCESS, null, 'Relationship deleted successfully')
    })
    .catch(err => {
      standardErrorHandler(dispatch, err, REMOVE_RELATIONSHIP.ERROR, 'Could not delete the relationship')
    })
}
