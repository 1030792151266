// @vendors
import React from 'react'
import Card from '@material-ui/core/Card'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

// @styles
import styles from '../styles.module.sass'

const renderCardContent = (data, theme) => {
  const cardContent = data.map(({ key, label, value, message }, index) => {
    const keyLabel = key || label
    let formatedBoolValue
    switch (value) {
      case 'True':
        formatedBoolValue = 'Yes'
        break
      case 'False':
        formatedBoolValue = 'No'
        break
      default:
        formatedBoolValue = value
        break
    }
    return (
      <Paper elevation={0} key={keyLabel} className={index <= 2 ? styles.itemGray : styles.itemWhite}>
        <div className={styles.itemWrapper}>
          <Typography component="h5" className={styles.label}>
            {keyLabel}
          </Typography>
          {value && (
            <Typography component="p" className={index === 3 || index === 4 ? styles.value : ''}>
              {formatedBoolValue}
            </Typography>
          )}
          {message && (
            <Typography component="p" className={styles.message} style={{ color: theme && theme.theme.successColor }}>
              {message}
            </Typography>
          )}
        </div>
      </Paper>
    )
  })

  return cardContent
}

const InputFetchCard = (data, theme) => (
  <Card key="data" className={styles.card}>
    <CardContent className={styles.content}>
      {data && data.length > 1 ? (
        renderCardContent(data, theme)
      ) : (
        <Paper elevation={0} className={styles.noData}>
          <Typography variant="h6">No Data found</Typography>
        </Paper>
      )}
    </CardContent>
  </Card>
)

export default InputFetchCard
