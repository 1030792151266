// @vendors
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import WebFontLoader from 'webfontloader'
import { BrowserRouter as Router } from 'react-router-dom'

// @ components
import App from './source/routes'

// @utils
import * as serviceWorker from './serviceWorker'

// @store
import store from './source/store'

// @styles
import './styles.scss'

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()

WebFontLoader.load({
  google: {
    families: ['Roboto:300,400,500,700', 'Material Icons']
  }
})
