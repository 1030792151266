// @actions
import axios from 'axios'

// @constants
import * as constants from './constant'

// @utils
import httpConfig from '../../../../http/config'
import { createApiRequestHeaders, standardRequestHandler, standardSuccessHandler, standardErrorHandler } from '../../../../http/utils'

const { apiServer } = httpConfig

export const requestQrCode = ticketId => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType } = getState().security
  const config = {
    headers: createApiRequestHeaders(token, tokenType)
  }

  standardRequestHandler(dispatch, constants.GET_QR_CODE_REQUEST, {
    ticketId
  })

  return axios
    .get(`${apiServer.url}/api/Tickets/${ticketId}/qr`, config)
    .then(res => {
      standardSuccessHandler(dispatch, constants.GET_QR_CODE_SUCCESS, {
        ticketId,
        ...res.data
      })
    })
    .catch(err => {
      standardErrorHandler(dispatch, err, constants.GET_QR_CODE_ERROR, constants.GET_QR_CODE_ERROR_MESSAGE)
    })
}
