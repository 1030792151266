// @vendors
import { connect } from 'react-redux'

// @components
import PasswordReset from './password-reset'

// @utils
import { resetPassword, getJwt } from '../../actions'

const mapStateToProps = state => ({
  driver: state.driver,
  security: state.security
})

const mapDispatchToProps = {
  getJwt,
  resetPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
