import { createContext } from 'react'

export const getValues = obj => {
  let values = {}

  Object.keys(obj).forEach(name => {
    const prop = { [name]: obj[name].value }
    values = { ...values, ...prop }
  })

  return values
}

export const setInitState = initialState => {
  let state = {}

  Object.keys(initialState).forEach(name => {
    const prop = {
      [name]: {
        value: initialState[name],
        valid: !!initialState[name],
        pristine: true
      }
    }
    state = { ...state, ...prop }
  })

  return { ...state, form: { valid: true } }
}

export const formatFiltersToParams = filters => {
  if (filters) {
    const Type = filters.type.length > 0 ? filters.type : []
    const Status = filters.status.length > 0 ? filters.status : []

    return { Type: Type.join('|'), Status: Status.join('|') }
  } else {
    return { Type: '', Status: '' }
  }
}

export const HandleSelection = createContext(() => {})

export const HandleSaveForLater = createContext(async () => true)

export const getSectionsBasedOnSelection = (steps, selection) => {
  const sectionsMapped = {
    droppingoff: ['Drop off info', 'trailerInfoDropOff'],
    pickingup: ['safetyCheckStep2', 'Pick up info', 'trailerInfoPickUp', 'permit', 'fuelLevelCheck'],
    'droppingoff-pickingup': ['Drop off info', 'trailerInfoDropOff', 'Pick up info', 'permit', 'fuelLevelCheck']
  }

  if (selection) {
    const stepsBySectionSelected = [
      steps[0],
      {
        sections: steps[1].sections.filter(
          section => sectionsMapped[selection].includes(section.key) || sectionsMapped[selection].includes(section.label)
        )
      }
    ]
    return stepsBySectionSelected
  }

  return [steps[0]]
}

export const hasToWaitForUpdatedInbound = (step, formValues) => {
  const hasStepInboundControl = step.sections.reduce((inboundControls, section) => {
    const controls = section.controls.filter(control => control.key === 'inboundShipment')
    return inboundControls.concat(controls)
  }, []).length

  const inboundValue = formValues.inboundShipment && formValues.inboundShipment.find(field => field.key === 'inboundShipment').value
  if (hasStepInboundControl && inboundValue && formValues.inboundShipment.length <= 1) {
    return true
  }
  return false
}

export const getSuggestions = (list, propertiesName, inputValue) => {
  return list.filter(item => {
    const propertiesValues = propertiesName.map(prop => item[prop].toLowerCase())
    return propertiesValues.some(pv => pv.includes(inputValue.toLowerCase()))
  })
}

export const isDroppingOffAndPickingUp = sections => {
  let droppingoffFlag,
    pickingupFlag = false

  sections.forEach(section => {
    if (/^Drop off/.test(section.label)) droppingoffFlag = true
    if (/^Pick up/.test(section.label)) pickingupFlag = true
  })

  return droppingoffFlag && pickingupFlag
}

export const addHiddenAttrbByKey = (sections, keysToHidden, keyToFind) => {
  sections.forEach(section => {
    if (section.key === keyToFind) {
      keysToHidden.forEach(key => {
        section.controls.forEach(control => {
          if (key === control.label) control.hidden = true
        })
      })
    }
  })
}
