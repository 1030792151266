// @vendors
import { combineReducers } from 'redux'

// @reducers
import themingReducer from '../utils/theming/reducer'
import index from '../security/reducer'
import QRReducer from '../pages/driver/components/qr/reducer'
import driverReducer from '../pages/driver/driver-profile/reducer'
import carrierReducer from '../pages/carrier/carrier-profile/reducer'
import facilitiesReducer from '../pages/client/facilities/reducer'
import formReducer from '../pages/driver/driver-tickets/ticket/reducer'
import busySpinnerReducer from '../common-components/busy-spinner/reducer'
import ticketsReducer from '../common-components/tickets-list/reducer'
import notificationsReducer from '../common-components/notifications/reducer'
import formValuesReducer from '../pages/driver/driver-tickets/ticket-values/reducer'
import carrierTicketReducer from '../pages/carrier/carrier-tickets/ticket/reducer'
import clientDataReducer from '../http/reducer'
import flagsReducer from './reducer/flags'

const reducer = combineReducers({
  security: index,
  busySpinner: busySpinnerReducer,
  form: formReducer,
  driver: driverReducer,
  carrier: carrierReducer,
  formValues: formValuesReducer,
  notifications: notificationsReducer,
  qr: QRReducer,
  tickets: ticketsReducer,
  theming: themingReducer,
  facilities: facilitiesReducer,
  carrierTicket: carrierTicketReducer,
  clientData: clientDataReducer,
  flags: flagsReducer
})

export default reducer
