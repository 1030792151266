import { buildAsyncActionType } from '../../../store/utils'

export const MODULE_NAME = 'facilities'

export const REQUEST_FACILITIES = buildAsyncActionType(MODULE_NAME, 'REQUEST_FACILITIES')

export const CREATE_FACILITY = buildAsyncActionType(MODULE_NAME, 'CREATE_FACILITY')

export const UPDATE_FACILITY = buildAsyncActionType(MODULE_NAME, 'UPDATE_FACILITY')

export const GET_FACILITY = buildAsyncActionType(MODULE_NAME, 'GET_FACILITY')
