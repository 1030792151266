import { connect } from 'react-redux'

// @utils
import { getDrivers } from './selector'

// @components
import DriverList from './driver-list'

// @actions
import { requestDrivers, removeDriverRelationshipRequest } from './actions'

const mapStateToProps = state => ({
  drivers: getDrivers(state)
})

const mapDispatchToProps = {
  requestDrivers,
  removeDriver: removeDriverRelationshipRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverList)
