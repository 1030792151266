// @vendors
import React, { Fragment, useState } from 'react'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import Visibility from '@material-ui/icons/Visibility'
import FormControl from '@material-ui/core/FormControl'
import CardContent from '@material-ui/core/CardContent'
import InputAdornment from '@material-ui/core/InputAdornment'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { FormHelperText } from '@material-ui/core'

// @styles
import styles from './styles.module.sass'

const PasswordInfo = ({ client, updateClientPassword }) => {
  const buildState = () => {
    return {
      oldPassword: {
        value: '',
        valid: false,
        pristine: true,
        showPassword: false
      },
      password: {
        value: '',
        valid: false,
        pristine: true,
        showPassword: false
      },
      confirmPassword: {
        value: '',
        valid: false,
        pristine: true,
        showPassword: false
      },
      regexLowercaseLetterValid: true,
      regexUppercaseLetterValid: true,
      regexNumberValid: true,
      regexSpecialCharacterValid: true,
      regexLengthValid: true,
      form: { valid: false }
    }
  }

  const [state, setState] = useState(buildState())
  const [editMode, setEditMode] = useState(false)

  const [helperText, setHelperText] = useState({
    confirmPassword: null
  })

  const handleChange = event => {
    const { target } = event
    const { form, name, value: controlValue } = target

    let value = controlValue
    let valid = event.target.checkValidity()

    if (name === 'password') {
      const confirmPasswordControl = document.querySelector('#confirmPassword')
      let newState = { ...state }

      var regexUppercaseLetter = /[A-Z]/
      var regexLowercaseLetter = /[a-z]/
      var regexNumber = /\d/
      var regexSpecialCharacter = /[!@#$%^&"'*\\()_+=\[{\]};:<>|./?,-]/
      var regexLength = /(?=.{6,32})/

      newState = {
        ...newState,
        regexUppercaseLetterValid: regexUppercaseLetter.test(value),
        regexLowercaseLetterValid: regexLowercaseLetter.test(value),
        regexNumberValid: regexNumber.test(value),
        regexSpecialCharacterValid: regexSpecialCharacter.test(value),
        regexLengthValid: regexLength.test(value),
        password: { value: value }
      }

      if (
        newState.regexLowercaseLetterValid &&
        newState.regexUppercaseLetterValid &&
        newState.regexNumberValid &&
        newState.regexSpecialCharacterValid &&
        newState.regexLengthValid
      ) {
        newState = {
          ...newState,
          [name]: { ...state[name], value, valid, pristine: false },
          password: { value: value, valid: true, pristine: true },
          form: { valid: form.checkValidity() }
        }
      } else {
        newState = {
          ...newState,
          [name]: { ...state[name], value, valid, pristine: false },
          password: { value: value, valid: false, pristine: false },
          form: { valid: false }
        }
      }
      if (value && state.confirmPassword.value && value !== state.confirmPassword.value) {
        confirmPasswordControl.setCustomValidity("Password doesn't match.")
        setHelperText({ ...helperText, confirmPassword: confirmPasswordControl.validationMessage })

        newState = {
          ...newState,
          [name]: { ...state[name], value, valid, pristine: false },
          confirmPassword: { value: state.confirmPassword.value, valid: false, pristine: false },
          form: { valid: false }
        }
      } else if (value && state.confirmPassword.value && value === state.confirmPassword.value) {
        confirmPasswordControl.setCustomValidity('')
        setHelperText({ ...helperText, confirmPassword: null })

        newState = {
          ...newState,
          [name]: { ...state[name], value, valid, pristine: false },
          confirmPassword: { value: state.confirmPassword.value, valid: true, pristine: false },
          form: { valid: form.checkValidity() }
        }
      }
      return setState(newState)
    } else if (name === 'confirmPassword') {
      if (value !== state.password.value) {
        valid = false

        event.target.setCustomValidity("Password doesn't match.")
        setHelperText({ ...helperText, [name]: event.target.validationMessage })
      } else {
        valid = true
        event.target.setCustomValidity('')
        setHelperText({ ...helperText, [name]: null })
      }
    }

    setState({
      ...state,
      [name]: { ...state[name], value, valid, pristine: false },
      form: { valid: form.checkValidity() }
    })
  }

  const handleClickShowPassword = name => () => {
    setState({
      ...state,
      [name]: { ...state[name], showPassword: !state[name].showPassword }
    })
  }

  const getValues = () => {
    let values = {}

    Object.keys(state).forEach(name => {
      const prop = { [name]: state[name].value }
      values = { ...values, ...prop }
    })

    return values
  }

  const showErrors = () => {
    setState({
      ...state,
      password: { ...state.password, pristine: false },
      oldPassword: { ...state.oldPassword, pristine: false },
      confirmPassword: { ...state.confirmPassword, pristine: false }
    })
  }

  const onSave = event => {
    event.preventDefault()

    if (!state.form.valid) {
      return showErrors()
    }

    const { password, oldPassword } = getValues()

    updateClientPassword({
      ...client,
      oldPassword,
      newPassword: password
    })

    setState(buildState())
    setEditMode(false)
  }

  const onCancel = () => {
    setEditMode(false)
    setState(buildState())
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleEditMode = () => {
    setEditMode(!editMode)
  }

  return (
    <CardContent classes={{ root: styles.passwordContainer }}>
      {editMode ? (
        <Fragment>
          <form noValidate autoComplete="off" className={styles.form} onSubmit={onSave}>
            <FormControl className={styles.textField}>
              <InputLabel htmlFor="oldPassword">Email</InputLabel>
              <Input disabled autoComplete="off" id="email" value={client.Email} type="text" inputProps={{ className: styles.textField }}></Input>
            </FormControl>
            <FormControl className={styles.textField}>
              <InputLabel error={!state.oldPassword.valid && !state.oldPassword.pristine} htmlFor="oldPassword">
                Old Password
              </InputLabel>
              <Input
                required
                autoComplete="off"
                error={!state.oldPassword.valid && !state.oldPassword.pristine}
                id="oldPassword"
                name="oldPassword"
                value={state.oldPassword.value}
                onChange={handleChange}
                type={state.oldPassword.showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('oldPassword')}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {state.oldPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              ></Input>
            </FormControl>
            <FormControl className={styles.textField}>
              <InputLabel error={!state.password.valid && !state.password.pristine} htmlFor="password">
                New Password
              </InputLabel>
              <Input
                required
                autoComplete="off"
                error={!state.password.valid && !state.password.pristine}
                id="password"
                name="password"
                value={state.password.value}
                onChange={handleChange}
                type={state.password.showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('password')}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {state.password.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              ></Input>
            </FormControl>
            <Typography
              variant="caption"
              color="currentcolor"
              style={{
                display:
                  state.regexLowercaseLetterValid &&
                  state.regexUppercaseLetterValid &&
                  state.regexNumberValid &&
                  state.regexSpecialCharacterValid &&
                  state.regexLengthValid
                    ? 'block'
                    : 'none'
              }}
            >
              Passwords must be at least 6-32 characters long containing at least one uppercase letter, one lowercase letter, one number and one
              special character
            </Typography>
            <Typography variant="caption" color="error" style={{ display: state.regexLowercaseLetterValid ? 'none' : 'block' }}>
              Password must contain at least one lower case letter
            </Typography>
            <Typography variant="caption" color="error" style={{ display: state.regexUppercaseLetterValid ? 'none' : 'block' }}>
              Password must contain at least one upper case letter
            </Typography>
            <Typography variant="caption" color="error" style={{ display: state.regexNumberValid ? 'none' : 'block' }}>
              Password must contain at least one number
            </Typography>
            <Typography variant="caption" color="error" style={{ display: state.regexSpecialCharacterValid ? 'none' : 'block' }}>
              Password must contain at least one special character
            </Typography>
            <Typography variant="caption" color="error" style={{ display: state.regexLengthValid ? 'none' : 'block' }}>
              Password must be at least 6-32 characters long
            </Typography>
            <FormControl>
              <InputLabel error={!state.confirmPassword.valid && !state.confirmPassword.pristine} htmlFor="confirmPassword">
                Confirm Password *
              </InputLabel>
              <Input
                required
                autoComplete="off"
                error={!state.confirmPassword.valid && !state.confirmPassword.pristine}
                id="confirmPassword"
                name="confirmPassword"
                value={state.confirmPassword.value}
                onChange={handleChange}
                type={state.confirmPassword.showPassword ? 'text' : 'password'}
                disabled={!state.password.valid}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('confirmPassword')}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {state.confirmPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText children={helperText.confirmPassword && <span className={styles.textDanger}>{helperText.confirmPassword}</span>} />
            </FormControl>
            <Grid container justify="flex-end" alignItems="center" className={styles.buttonsContainer}>
              <Button size="medium" onClick={onCancel}>
                CANCEL
              </Button>
              <Button type="submit" size="medium">
                SAVE
              </Button>
            </Grid>
          </form>
        </Fragment>
      ) : (
        <Grid container justify="space-between" wrap="wrap" alignItems="center" className={styles.password}>
          <Typography variant="h6" className={styles.title} gutterBottom>
            Change Password
          </Typography>
          <Button variant="contained" color="primary" onClick={handleEditMode}>
            Edit
          </Button>
        </Grid>
      )}
    </CardContent>
  )
}

export default PasswordInfo
