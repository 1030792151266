// @constants
import * as constants from './constant'

const initialState = null

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_DRIVER_SUCCESS: {
      return { ...action.payload }
    }
    case constants.UPDATE_DRIVER_SUCCESS: {
      return { ...state, ...action.payload }
    }
    case constants.UPDATE_DRIVER_AVATAR_SUCCESS: {
      return { ...state, avatar: action.payload.avatar }
    }
    case constants.GET_DRIVER_SUCCESS: {
      return { ...action.payload }
    }
    case constants.RESET_DRIVER: {
      return initialState
    }
    default: {
      return state
    }
  }
}

export default reducer
