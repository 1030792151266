// @constants
import * as constants from './constant'
import * as stepsConstants from '../../components/steps/constant'
import * as securityConstants from '../../../../security/constant'
import * as ticketListConstants from '../../../../common-components/tickets-list/constant'
import * as commonStepsConstants from '../../../../common-components/steps/constant'

const initialState = {
  steps: null,
  fetched: false,
  ticketId: null,
  ticketStatus: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case commonStepsConstants.CREATE_TICKET_SUCCESS: {
      return { ...state, hasBeenCreated: true }
    }
    case ticketListConstants.GET_TICKET_LIST_SUCCESS: {
      return initialState
    }
    case constants.REQUEST_NEW_FORM.SUCCESS: {
      const steps = action.payload.steps.map(step => ({
        ...step,
        fetchedDetails: false
      }))
      return { ...state, ...action.payload, steps, hasBeenCreated: false }
    }
    case constants.REQUEST_FORM.SUCCESS: {
      const steps = action.payload.steps.map(step => ({
        ...step,
        fetchedDetails: false
      }))
      return { ...state, ...action.payload, steps, hasBeenCreated: true }
    }
    case stepsConstants.REQUEST_STEP_DETAILS.REQUESTED: {
      const updatedSteps = state.steps.map(step => (step.id === action.payload ? { ...step, fetchedDetails: true } : step))
      return { ...state, steps: updatedSteps }
    }
    case stepsConstants.REQUEST_STEP_DETAILS.SUCCESS: {
      const {
        stepId,
        data: { sections, ticketStatus, driver }
      } = action.payload
      const updatedSteps = state.steps.map(step => (step.id === stepId ? { ...step, sections } : step))

      return { ...state, steps: updatedSteps, ticketStatus, driver }
    }
    case stepsConstants.RESET_STEP_FETCH_STATUS: {
      const updatedSteps = state.steps.map(step => {
        if (step.id === action.payload) {
          return { ...step, fetchedDetails: false }
        }
        return step
      })
      return { ...state, steps: updatedSteps }
    }
    case constants.RESET_FORM_FETCH_STATUS: {
      return {
        ...state,
        fetched: false,
        ticketId: null,
        steps: null,
        ticketStatus: null
      }
    }
    case securityConstants.LOG_OUT: {
      return initialState
    }
    default: {
      return state
    }
  }
}

export default reducer
