// @actions constant
export const SAVE_TICKET_ERROR = 'SAVE_TICKET_ERROR'
export const SAVE_TICKET_REQUEST = 'SAVE_TICKET_REQUEST'
export const SAVE_TICKET_SUCCESS = 'SAVE_TICKET_SUCCESS'

export const CREATE_TICKET_ERROR = 'CREATE_TICKET_ERROR'
export const CREATE_TICKET_REQUEST = 'CREATE_TICKET_REQUEST'
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS'

export const FINALIZE_TICKET_ERROR = 'FINALIZE_TICKET_ERROR'
export const FINALIZE_TICKET_REQUEST = 'FINALIZE_TICKET_REQUEST'
export const FINALIZE_TICKET_SUCCESS = 'FINALIZE_TICKET_SUCCESS'

export const ASSIGN_TICKET_TO_DRIVER_REQUEST = 'ASSIGN_TICKET_TO_DRIVER_REQUEST'
export const ASSIGN_TICKET_TO_DRIVER_SUCCESS = 'ASSIGN_TICKET_TO_DRIVER_SUCCESS'
export const ASSIGN_TICKET_TO_DRIVER_ERROR = 'ASSIGN_TICKET_TO_DRIVER_ERROR'

// @messages constants
export const TICKET_SAVE_ERROR = 'Error Updating the ticket'
export const TICKET_PROGRESS_SAVED = 'Your progress has been saved'
export const TICKET_CREATED = 'Your ticket has been created'
export const TICKET_CREATION_ERROR = 'Error creating the ticket'
export const FINALIZE_TICKET_ERROR_MESSAGE = 'Error Finalizing your Ticket'
export const ASSIGN_TICKET_TO_DRIVER_ERROR_MESSAGE = 'Could not assign the driver.'

// @steps constants
export const STEP_PREVIOUS = 'previous'
export const STEP_CONTINUE = 'continue'
