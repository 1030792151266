// @actions constants
export const SEND_INVITATION_EMAIL_ERROR = 'SEND_INVITATION_EMAIL_ERROR'
export const SEND_INVITATION_EMAIL_REQUEST = 'SEND_INVITATION_EMAIL_REQUEST'
export const SEND_INVITATION_EMAIL_SUCCESS = 'SEND_INVITATION_EMAIL_SUCCESS'

// @messages
export const INVITATION_ERROR_MESSAGE = 'Could not send the email.'
export const invitationSentSuccessfully = profile => {
  const userProfile = profile && profile.toLowerCase()
  return `Thanks, if the email address is valid the ${userProfile} will receive a message with a link to create an account.`
}

export const EMAIL_HELPER_TEXT = {
  email: null
}
export const ERROR_MESSAGE = 'Email format is incorrect'
