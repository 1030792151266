// @vendors
import React, { useState, useEffect } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// @styles
import styles from './styles.module.sass'

const CustomRadioControl = ({ control, isDisabled, onHandleChange, currentValue }) => {
  const { data, label } = control
  const [value, setValue] = useState(() => {
    onHandleChange(currentValue)
    return currentValue
  })

  const handleChange = event => {
    event.stopPropagation()
    setValue(event.target.value)
    onHandleChange(event.target.value)
  }

  useEffect(() => {
    if (!currentValue) {
      setValue('')
      onHandleChange('')
    } else if (currentValue !== value) {
      setValue(currentValue)
      onHandleChange(currentValue)
    }
  }, [currentValue, onHandleChange, value])

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup value={value} onChange={handleChange} classes={{ root: styles.row }}>
        {data.map(item => (
          <FormControlLabel value={item.value} disabled={isDisabled} control={<Radio />} label={item.label} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default CustomRadioControl
