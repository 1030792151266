// @vendors
import { connect } from 'react-redux'

// @actions
import { requestCarriers, removeRelationshipRequest } from '../../actions'

// @components
import CarrierList from './list'

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  requestCarriers,
  removeCarrier: removeRelationshipRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierList)
