import { connect } from 'react-redux'

// @components
import DriverProfile from './driver-profile'

// @actions
import { updateDriver, updateDriverAvatar, notifyInvalidFileType } from './actions'
import { setFlag } from '../../../store/actions/flags'

//@ utils
import { getDriver } from './selectors'
import { getSecurity } from '../../../security'
import { getThemingData } from '../../../utils/theming/selectors'

const mapStateToProps = state => ({
  driver: getDriver(state),
  security: getSecurity(state),
  theme: getThemingData(state),
  clientName: state.clientData.clientName,
  justCreatedDriver: state.flags.justCreatedDriver
})

const mapDispatchToProps = {
  updateDriver,
  updateDriverAvatar,
  notifyInvalidFileType,
  setFlag
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverProfile)
