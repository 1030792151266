// @vendors
import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

// @components
import StepsHeader from '../steps/header'
import QRDetails from '../../../../common-components/qr-details'
import NavigationBar from '../../../../common-components/navigation-bar'
import AssignDriverBar from '../../../carrier/components/assign-driver-bar'

const QR = ({ ticketId, requestQrCode, qrCode, steps, profile, ticketStatus }) => {
  const assignedDriver = steps && steps.length && steps[0].driver
  const allowReassign = !assignedDriver && (ticketStatus === 'InProgress' || ticketStatus === 'Done')
  const allowAssign = ticketStatus === 'Incomplete' || ticketStatus === 'Ready'

  useEffect(() => {
    requestQrCode(ticketId)
  }, [ticketId, requestQrCode])

  if (!steps) {
    return <Redirect to="/forms" />
  }

  return (
    qrCode && (
      <>
        <NavigationBar />
        {profile.toLowerCase() === 'carrier' && (allowAssign || allowReassign) && <AssignDriverBar onHandleSaveForLater={async () => true} />}
        <StepsHeader steps={steps} currentStep={2} />
        <QRDetails qrCode={qrCode} showFullDetails />
      </>
    )
  )
}

export default QR
