// @vendors
import React from 'react'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'

// @styles
import styles from '../../styles.module.sass'

const FacilitiesToolbar = ({ newFacility, handleSuggestions }) => {
  const handleChange = event => {
    event.stopPropagation()
    handleSuggestions(event.target.value)
  }

  const handleClick = event => {
    event.preventDefault()
    newFacility()
  }

  return (
    <Toolbar className={styles.toolbar}>
      <div className={styles.toolbarWrapper}>
        <div className={styles.search}>
          <div className={styles.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            classes={{
              root: styles.inputRoot,
              input: styles.inputInput
            }}
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleChange}
          />
        </div>
        <Button variant="contained" color="secondary" className={styles.createFacility} onClick={handleClick} disabled={!newFacility}>
          + CREATE FACILITY
        </Button>
      </div>
    </Toolbar>
  )
}

export default FacilitiesToolbar
