// @vendors
import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// @styles
import '../styles.scss'

const LoginFooter = ({ handleLogin, shouldDisableLoginButton }) => {
  const signInButtonClasses = classnames('login-form__footer-sign-in-button', {
    'login-form__footer-sign-in-button--disabled': shouldDisableLoginButton
  })

  return (
    <div className="login-form__footer">
      <Typography align="right" className="login-form__footer-link-text-right" component="h4" variant="caption">
        <Link to="/password-recovery">Forgot your password?</Link>
      </Typography>
      <Button className={signInButtonClasses} color="primary" disabled={shouldDisableLoginButton} onClick={handleLogin} variant="contained">
        sign in
      </Button>
      <Typography align="center" className="login-form__footer-link-text" component="h4" variant="caption">
        <Link to="/signup">Don't you have an account? Sign up</Link>
      </Typography>
    </div>
  )
}

export default LoginFooter
