// @vendors
import axios from 'axios'

// @constants
import * as constants from './constant'

// @utils
import httpConfig from '../../../http/config'
import { standardRequestHandler, standardSuccessHandler, standardErrorHandler, createApiRequestHeaders } from '../../../http/utils'
import { notifyError } from '../../../common-components/notifications/actions'

const { apiServer } = httpConfig

export const requestDriver = () => (dispatch, getState) => {
  const {
    security: { access_token, token_type, Id }
  } = getState()

  const config = {
    headers: createApiRequestHeaders(access_token, token_type)
  }

  standardRequestHandler(dispatch, constants.GET_DRIVER_REQUEST, {
    Id
  })

  return axios
    .get(`${apiServer.url}/api/Drivers/${Id}`, config)
    .then(res => {
      standardSuccessHandler(dispatch, constants.GET_DRIVER_SUCCESS, res.data)
    })
    .catch(err => {
      standardErrorHandler(dispatch, err, constants.GET_DRIVER_ERROR, constants.GET_DRIVER_ERROR_MESSAGE)
    })
}

export const createDriver = (driver, inviter) => (dispatch, getState) => {
  const { clientId } = getState().clientData

  standardRequestHandler(dispatch, constants.CREATE_DRIVER_REQUEST)

  const config = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }

  driver.clientId = driver.clientId || clientId

  let url = `${apiServer.url}/api`

  if (!inviter) {
    url = `${url}/Drivers?clientId=${clientId}`
  } else {
    inviter = inviter.toLowerCase()

    let endpoint = inviter === 'carrier' ? 'Carriers' : `Clients/${driver.clientId}`
    endpoint = `${endpoint}/Driver`

    url = `${apiServer.url}/api/${endpoint}`
  }

  return axios
    .post(url, driver, config)
    .then(res => {
      const password = JSON.parse(res.config.data).password
      standardSuccessHandler(
        dispatch,
        constants.CREATE_DRIVER_SUCCESS,
        {
          ...res.data,
          password
        },
        constants.CREATE_DRIVER_SUCCESS_MESSAGE
      )
    })
    .catch(err => standardErrorHandler(dispatch, err, constants.CREATE_DRIVER_ERROR, constants.CREATE_DRIVER_ERROR_MESSAGE))
}

export const updateDriver = driver => (dispatch, getState) => {
  const {
    security: { access_token, token_type, Id }
  } = getState()

  const config = {
    headers: createApiRequestHeaders(access_token, token_type)
  }

  standardRequestHandler(dispatch, constants.UPDATE_DRIVER_REQUEST, {
    Id
  })

  return axios
    .put(`${apiServer.url}/api/Drivers/${Id}`, driver, config)
    .then(res => {
      standardSuccessHandler(dispatch, constants.UPDATE_DRIVER_SUCCESS, res.data, constants.UPDATE_DRIVER_SUCCESS_MESSAGE)
    })
    .catch(err => {
      standardErrorHandler(dispatch, err, constants.UPDATE_DRIVER_ERROR, constants.UPDATE_DRIVER_ERROR_MESSAGE)
    })
}

export const updateDriverAvatar = file => (dispatch, getState) => {
  const {
    security: { access_token, token_type, Id }
  } = getState()

  const config = {
    headers: createApiRequestHeaders(access_token, token_type)
  }

  standardRequestHandler(dispatch, constants.UPDATE_DRIVER_AVATAR_REQUEST, { Id })

  return axios
    .post(`${apiServer.url}/api/Drivers/${Id}/uploadPicture`, file, config)
    .then(res => {
      standardSuccessHandler(dispatch, constants.UPDATE_DRIVER_AVATAR_SUCCESS, { avatar: res.data }, constants.UPDATE_DRIVER_AVATAR_SUCCESS_MESSAGE)
    })
    .catch(err => {
      standardErrorHandler(dispatch, err, constants.UPDATE_DRIVER_AVATAR_ERROR, constants.UPDATE_DRIVER_AVATAR_ERROR_MESSAGE)
    })
}

export const resetDriver = () => ({ type: constants.RESET_DRIVER })

export const notifyInvalidFileType = () => dispatch => {
  dispatch(notifyError('File type not allowed. Allowed types: png, jpg, jpeg.'))
}
