export const unauthorizedRedirect = {
  prop: 'security',
  redirectTo: '/'
}

export const noDataRedirect = {
  prop: 'steps',
  redirectTo: '/forms/new'
}

export const noDriver = {
  prop: 'driver',
  redirectTo: '/'
}
