// @vendors
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Container from '@material-ui/core/Container'

// @components
import Sections from '../../../../common-components/sections'
import StepsHeader from './header'
import StepsFooter from '../../../../common-components/steps/footer'
import NavigationBar from '../../../../common-components/navigation-bar'

// @constants
import { unauthorizedRedirect, noDataRedirect } from '../../../../utils/redirection/constant'
import { STEP_CONTINUE, STEP_PREVIOUS } from '../../../../common-components/steps/constant'

// @utils
import useMount from '../../../../hooks/use-mount'
import withMissingPropsRedirection from '../../../../utils/redirection/with-missing-props-redirection'
import AssignDriverBar from '../../../carrier/components/assign-driver-bar'

const Steps = ({
  clientName,
  formValues,
  security,
  steps,
  match,
  requestStepDetails,
  resetStepFetchStatus,
  saveTicket,
  createTicket,
  hasBeenCreated,
  ticketStatus,
  setValidStepStatus,
  finalizeTicket,
  notifyFinishTicketError,
  history,
  flags,
  setFlag
}) => {
  const { stepId, ticketId } = match.params
  const [allowContinue, setAllowContinue] = useState(true)
  const [requiredControls, setRequiredControls] = useState([])
  const [reloadSections, setReloadSections] = useState(false)
  const currentStep = steps.find(step => step.id === stepId)

  // for carrier
  const assignedDriver = steps && steps.length && steps[0].driver
  const allowReassign = !assignedDriver && (ticketStatus === 'InProgress' || ticketStatus === 'Done')
  const allowAssign = ticketStatus === 'Incomplete' || ticketStatus === 'Ready'

  const reload = () => {
    resetStepFetchStatus(currentStep.id)
  }

  const getControls = () => {
    let controls = []
    filterSections(currentStep.sections || []).forEach(section => {
      controls = [...controls, ...section.controls]
    })

    return controls
  }

  useMount(reload)

  useEffect(() => {
    if (flags.outboundShipmentNumberValidationCompleted || flags.inboundShipmentNumberValidationCompleted) {
      setReloadSections(true)

      if (flags.outboundShipmentNumberValidationCompleted) {
        setFlag('outboundShipmentNumberValidationCompleted', false)
      }

      if (flags.inboundShipmentNumberValidationCompleted) {
        setFlag('inboundShipmentNumberValidationCompleted', false)
      }
      return
    }

    if (!currentStep.fetchedDetails) {
      requestStepDetails(stepId, ticketId)
    }
  }, [flags.outboundShipmentNumberValidationCompleted, flags.inboundShipmentNumberValidationCompleted, currentStep.fetchedDetails, stepId, ticketId])

  useEffect(() => {
    if (formValues && typeof formValues === 'object' && requiredControls.length) {
      let filteredRequiredControls = requiredControls.filter(
        rc => !Object.keys(formValues).find(key => key === rc.key && formValues[key] && formValues[key] !== rc.invalidValue)
      )
      filteredRequiredControls = [
        ...filteredRequiredControls,
        ...getControls().filter(
          c =>
            c.required &&
            c.key === flags.lastControlUpdated &&
            !filteredRequiredControls.find(fc => fc.key === c.key) &&
            (!formValues[c.key] || formValues[c.key] === c.invalidValue)
        )
      ]
      setRequiredControls([...filteredRequiredControls])
    }
  }, [formValues])

  const isFirstStep = () => {
    if (currentStep.id === steps[0].id) return true
    return false
  }

  const getSkippedControls = controlKey => {
    let skippedControls = []

    if (clientName.toLowerCase() === 'budexpress') {
      if (formValues.jobType === 'droppingoff-pickingup') {
        if (controlKey) {
          skippedControls = [controlKey === 'outboundShipmment' ? 'inboundShipment' : 'outboundShipmment']
        }
      }
    }

    return skippedControls
  }

  const setRefrigeratedPickUpRequiredValue = section => {
    if (formValues.jobType === 'droppingoff-pickingup' || formValues.jobType === 'pickingup') {
      const controls = section.controls.map(control => {
        if ((control.key || '').toLowerCase() === 'refrigeratedpickup') {
          if (
            requiredControls.find(c => (c.key || '').toLowerCase() === 'refrigeratedpickup') &&
            (!flags.validOutboundLiveLoad || !flags.validOutboundReefer)
          ) {
            setRequiredControls(requiredControls.filter(c => (c.key || '').toLowerCase() !== 'refrigeratedpickup'))
          }

          return {
            ...control,
            required: flags.validOutboundLiveLoad && flags.validOutboundReefer
          }
        }

        return control
      })

      return { ...section, controls }
    }

    return section
  }

  const setFuelLevelCheckVisibility = section => {
    const controls = section.controls.map(control => {
      if ((control.key || '').toLowerCase().search('fuelcheck') !== -1) {
        if (requiredControls.find(c => (c.key || '').toLowerCase().search('fuelcheck') !== -1) && !flags.validOutboundLiveLoad) {
          setRequiredControls(requiredControls.filter(c => c.key.toLowerCase().search('fuelcheck') === -1))
        }

        return {
          ...control,
          required: flags.validOutboundLiveLoad
        }
      }

      return control
    })

    return { ...section, controls, hidden: !flags.validOutboundLiveLoad }
  }

  const validateShipmentNumberControls = section => {
    if (flags.outboundShipmentNumberValidationCompleted && (section.label || '').toLowerCase() === 'pick up info') {
      if (!flags.validOutboundShipmentNumber && !requiredControls.find(c => (c.key || '').toLowerCase() === 'outboundshipment')) {
        setRequiredControls([...requiredControls, section.controls.find(c => (c.key || '').toLowerCase() === 'outboundshipment')])
      } else if (flags.validOutboundShipmentNumber && requiredControls.find(c => (c.key || '').toLowerCase() === 'outboundshipment')) {
        setRequiredControls([...requiredControls.filter(c => c.key && c.key.toLowerCase() !== 'outboundshipment')])
      }
    } else if (flags.inboundShipmentNumberValidationCompleted && (section.label || '').toLowerCase() === 'drop off info') {
      if (!flags.validInboundShipmentNumber && !requiredControls.find(c => (c.key || '').toLowerCase() === 'inboundshipment')) {
        setRequiredControls([...requiredControls, section.controls.find(c => (c.key || '').toLowerCase() === 'inboundshipment')])
      } else if (flags.validInboundShipmentNumber && requiredControls.find(c => (c.key || '').toLowerCase() === 'inboundshipment')) {
        setRequiredControls([...requiredControls.filter(c => (c.key || '').toLowerCase() !== 'inboundshipment')])
      }
    }
  }

  const filterSections = (sections = []) => {
    let filteredSections = [...sections]

    if (clientName.toLowerCase() === 'budexpress') {
      filteredSections = filteredSections.map(section => {
        if ((section.key || '').toLowerCase() === 'fuellevelcheck') {
          return setFuelLevelCheckVisibility(section)
        }

        if ((section.key || '').toLowerCase() === 'trailerinfopickup') {
          return setRefrigeratedPickUpRequiredValue(section)
        }

        if ((section.key || '').toLowerCase() === 'jobtype') {
          validateShipmentNumberControls(section)
        }

        return section
      })
    }

    return filteredSections
  }

  const getRequiredControls = (skippedControls = []) => {
    const requiredControls = filterSections(currentStep.sections).reduce((acc, section) => {
      const controls = section.controls.filter(control => control.required)
      return acc.concat(controls)
    }, [])

    const requiredList = requiredControls.filter(control => {
      if (!skippedControls.find(key => control.key === key) && (!formValues[control.key] || formValues[control.key] === control.invalidValue)) {
        return control
      }

      return null
    })

    return requiredList
  }

  const showRequiredControls = () => {
    const requiredControls = getRequiredControls(getSkippedControls())

    if (requiredControls.length) {
      setRequiredControls(requiredControls)
      setValidStepStatus()
    }
  }

  const requestSaveTicket = () => {
    if (currentStep.sections) {
      const allControls = currentStep.sections.reduce((acc, section) => {
        let formatted = []
        section.controls.forEach(control => {
          if (control.type === 'text|service') {
            const values = formValues[control.key] || control.currentValue

            if (typeof values === 'object') {
              formatted = values || []
              formatted = formatted.map(item => {
                if (item.key.toLowerCase() !== control.key.toLowerCase()) {
                  return { ...item, key: `${item.key}-${control.key === 'outboundShipment' ? 'P' : 'D'}` }
                }

                return { ...item }
              })
            } else {
              formatted = [...formatted, { key: control.key, value: values }]
            }
          } else {
            const value = formValues[control.key] ? formValues[control.key] : formValues[control.key] === 0 ? formValues[control.key] : null
            formatted = [...formatted, { key: control.key, value }]
          }
        })

        return [...acc, ...formatted]
      }, [])
      const stepData = {
        stepId,
        finalStep: currentStep.finalStep,
        controls: allControls
      }
      if (hasBeenCreated) {
        return saveTicket(ticketId, stepData)
      } else {
        return createTicket(ticketId, stepData)
      }
    }
  }

  const onHandleNavigationStep = async navigation => {
    const currentStepIndex = steps.findIndex(step => step.id === currentStep.id)

    if (navigation === STEP_CONTINUE && allowContinue) {
      const requiredControls = getRequiredControls(getSkippedControls())

      if (!requiredControls.length) {
        setRequiredControls([])

        if (ticketStatus !== 'InProgress' && ticketStatus !== 'Done') {
          await requestSaveTicket()
        }

        if (currentStep.finalStep) {
          const finished = await finalizeTicket(ticketId)

          if (finished) {
            return history.push(`/${security.Profile.toLowerCase()}/tickets/${ticketId}/qr`)
          }

          notifyFinishTicketError()
        } else {
          requestStepDetails(String(Number(currentStep.id) + 1), ticketId)

          const nextStepId = steps[currentStepIndex + 1].id
          history.push(`/${security.Profile.toLowerCase()}/tickets/${ticketId}/steps/${nextStepId}`)
        }
      } else {
        setRequiredControls(requiredControls)
        setValidStepStatus()
      }
    } else if (navigation === STEP_CONTINUE && !allowContinue) {
      showRequiredControls()
    } else if (navigation === STEP_PREVIOUS) {
      if (!isFirstStep()) {
        setAllowContinue(true)
        const previousStepId = steps[currentStepIndex - 1].id
        history.push(`/${security.Profile.toLowerCase()}/tickets/${ticketId}/steps/${previousStepId}`)
      }
    }
  }

  const onHandleSaveForLater = async controlKey => {
    const requiredControls = getRequiredControls(getSkippedControls(controlKey))

    if (security && !requiredControls.length) {
      await requestSaveTicket()
      return true
    } else {
      setRequiredControls(requiredControls)
      setValidStepStatus()
    }
  }

  return (
    <>
      <NavigationBar />
      {security.Profile.toLowerCase() === 'carrier' && (allowAssign || allowReassign) && (
        <AssignDriverBar onHandleSaveForLater={onHandleSaveForLater} />
      )}
      <StepsHeader steps={steps} currentStep={currentStep} />
      <Container maxWidth="lg">
        {reloadSections || currentStep.sections ? (
          <Sections
            sections={filterSections(currentStep.sections)}
            isDisabled={ticketStatus === 'Done' || ticketStatus === 'InProgress'}
            requiredControls={requiredControls}
            setAllowContinue={setAllowContinue}
          />
        ) : (
          <div />
        )}
      </Container>
      <StepsFooter
        onHandleNavigationStep={onHandleNavigationStep}
        isDisabled={ticketStatus === 'Done' || ticketStatus === 'InProgress'}
        ticketStatus={ticketStatus}
        steps={steps.length}
        activeStep={Number(currentStep.id) - 1}
        onHandleSaveForLater={onHandleSaveForLater}
        isFirstStep={isFirstStep()}
        isFinalStep={!isFirstStep()}
      />
    </>
  )
}

export default withMissingPropsRedirection([unauthorizedRedirect, noDataRedirect], withRouter(Steps))
