// @vendors
import React from 'react'
import { Link } from 'react-router-dom'
import Step from '@material-ui/core/Step'
import Stepper from '@material-ui/core/Stepper'
import StepLabel from '@material-ui/core/StepLabel'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

// @styles
import styles from './styles.module.sass'

const StepsHeader = ({ steps, currentStep }) => {
  // TODO: the step message should come from server
  const getStepMessage = () => {
    const stepIndex = steps.findIndex(step => step.id === currentStep.id)
    switch (stepIndex) {
      case 0:
        return (
          <Typography className={styles.stepMessage}>
            <strong> Welcome </strong> You’re 5 minutes away to be ready to go!
          </Typography>
        )
      case 1:
        return (
          <Typography className={styles.stepMessage}>
            <strong>Great!</strong> We are almost ready to go!
          </Typography>
        )
      default:
        return (
          <Typography className={styles.stepMessage}>
            <strong>Great!</strong> We are ready to go
          </Typography>
        )
    }
  }

  const getStepLabel = (step, labelProps) => {
    if (step.description === 'GO') {
      return (
        <StepLabel {...labelProps}>
          <Link to={`/qr`} style={{ textDecoration: 'none' }}>
            {step.description}
          </Link>
        </StepLabel>
      )
    } else {
      return <StepLabel {...labelProps}>{step.description}</StepLabel>
    }
  }

  return (
    <div className={styles.container}>
      <Container maxWidth="sm">
        <Stepper className={styles.stepper}>
          {steps.map(step => {
            const stepProps = { active: false }
            const labelProps = {}
            if (step.id === currentStep.id) {
              stepProps.active = true
            }
            if (step.completed) {
              stepProps.completed = true
            }
            return (
              <Step key={step.id} {...stepProps}>
                {getStepLabel(step, labelProps)}
              </Step>
            )
          })}
        </Stepper>
        <Typography className={styles.stepMessage} variant="subtitle1">
          {getStepMessage()}
        </Typography>
      </Container>
    </div>
  )
}

export default StepsHeader
