// @vendors
import { connect } from 'react-redux'

// @utils
import { getQrCode } from '../../selectors'

// @actions
import { requestQrCode } from '../../actions'

// @components
import QRDetailsModal from './qr-details-modal'

const mapStateToProps = state => ({
  qrCode: getQrCode(state)
})

const mapDispatchToProps = {
  requestQrCode
}

export default connect(mapStateToProps, mapDispatchToProps)(QRDetailsModal)
