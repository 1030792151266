// @vendors
import { connect } from 'react-redux'

// @components
import Notifications from './notifications'
import { dismissNotification } from './actions'

const mapStateToProps = state => ({
  notifications: state.notifications
})

const mapDispatchToProps = {
  dismissNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
