// @vendors
import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'

// @styles
import styles from './styles.module.sass'

const TicketListHeader = ({ head, profile, onCheckAll, unCheckAll }) => {
  const onCheckAllHandler = event => {
    const {
      target: { checked }
    } = event
    onCheckAll(checked ? 'all' : 'none')
  }

  const renderCells = () => {
    let cells = []

    if (profile.toLowerCase() === 'carrier') {
      cells = [
        ...cells,
        <TableCell key="all">
          <Checkbox color="primary" onChange={onCheckAllHandler} checked={!!unCheckAll} />
        </TableCell>
      ]
    }

    if (profile.toLowerCase() === 'driver' || 'carrier') {
      cells = [...cells, <TableCell key="selection" />]
    }

    cells = [
      ...cells,
      ...head
        .filter(header => header.label)
        .map(header => (
          <TableCell key={header.key}>{profile.toLowerCase() === 'carrier' && header.key === 'User' ? 'DRIVER' : header.label}</TableCell>
        ))
    ]

    if (profile.toLowerCase() === 'driver' || 'carrier') {
      cells = [...cells, <TableCell key="qr" />]
    }

    return cells
  }

  return (
    <TableHead className={styles.head}>
      <TableRow>{[...renderCells()]}</TableRow>
    </TableHead>
  )
}
export default TicketListHeader
