// @vendors
import React from 'react'
import { Redirect } from 'react-router-dom'

// @utils
import useMount from '../../../hooks/use-mount'
import withMissingPropsRedirect from '../../../utils/redirection/with-missing-props-redirection'

// @constants
import { PATH_NAME } from '../constant'
import { unauthorizedRedirect } from '../../../utils/redirection/constant'

const DriverLoader = ({ security, driver, requestDriver, justCreatedDriver }) => {
  useMount(() => {
    requestDriver()
  })

  return driver ? <Redirect to={`${PATH_NAME}/${justCreatedDriver ? 'profile' : 'tickets'}`} /> : null
}

export default withMissingPropsRedirect([unauthorizedRedirect], DriverLoader)
