// @vendors
import axios from 'axios'

// @constants
import * as constants from '../constant'

// @utils
import { createApiRequestHeaders, standardErrorHandler, standardRequestHandler, standardSuccessHandler } from '../../../http/utils'
import httpConfig from '../../../http/config'
import { notifyError } from '../../notifications/actions'

const { apiServer } = httpConfig

const getConfigByUserType = ({ state, clientId, createApiRequestHeaders, stepData = {} }) => {
  const { access_token: token, token_type: tokenType, Id } = state.security
  let params, stepBody

  params = { clientId, userId: Id }

  if (state.driver) {
    params = { clientId, driverId: Id, userId: Id }
  } else {
    params = { clientId, carrierId: Id, userId: Id }
  }

  stepBody = { ...params, ...stepData }

  const config = {
    headers: createApiRequestHeaders(token, tokenType),
    params: Object.keys(stepData).length > 0 ? null : params
  }

  return { config, stepBody }
}

export const saveTicket = (ticketId, stepData) => (dispatch, getState) => {
  const state = getState()

  const {
    clientData: { clientId }
  } = state

  const { config, stepBody } = getConfigByUserType({
    state,
    clientId,
    createApiRequestHeaders,
    stepData
  })

  standardRequestHandler(dispatch, constants.SAVE_TICKET_REQUEST, stepBody)

  return axios
    .put(`${apiServer.url}/api/Tickets/${ticketId}`, stepBody, config)
    .then(res =>
      standardSuccessHandler(
        dispatch,
        constants.SAVE_TICKET_SUCCESS,
        {
          status: res.status
        },
        constants.TICKET_PROGRESS_SAVED
      )
    )
    .catch(err => standardErrorHandler(dispatch, err, constants.SAVE_TICKET_ERROR, constants.TICKET_SAVE_ERROR))
}

export const createTicket = (ticketId, controls) => (dispatch, getState) => {
  const state = getState()

  const {
    clientData: { clientId }
  } = state

  const { config } = getConfigByUserType({
    state,
    clientId,
    createApiRequestHeaders
  })

  standardRequestHandler(dispatch, constants.CREATE_TICKET_REQUEST)

  return axios
    .post(`${apiServer.url}/api/Tickets/${ticketId}/create`, {}, config)
    .then(async () => {
      standardSuccessHandler(dispatch, constants.CREATE_TICKET_SUCCESS, {}, constants.TICKET_CREATED)

      await saveTicket(ticketId, controls)(dispatch, getState)
    })
    .catch(err => standardErrorHandler(dispatch, err, constants.CREATE_TICKET_ERROR, constants.TICKET_CREATION_ERROR))
}

export const finalizeTicket = ticketId => (dispatch, getState) => {
  const state = getState()

  const {
    clientData: { clientId }
  } = state

  const { config } = getConfigByUserType({
    state,
    clientId,
    createApiRequestHeaders
  })

  standardRequestHandler(dispatch, constants.FINALIZE_TICKET_REQUEST, ticketId)

  return axios
    .post(`${apiServer.url}/api/Tickets/${ticketId}/finish`, {}, config)
    .then(res => {
      standardSuccessHandler(dispatch, constants.FINALIZE_TICKET_SUCCESS, ticketId)
      return res.data
    })
    .catch(err => standardErrorHandler(dispatch, err, constants.FINALIZE_TICKET_ERROR, constants.FINALIZE_TICKET_ERROR_MESSAGE, ticketId))
}

export const notifyUpdatedInboundTable = () => dispatch => {
  dispatch(notifyError('Please, wait until you get an updated inbound table.'))
}
export const setValidStepStatus = () => dispatch => {
  dispatch(notifyError('Please complete required fields'))
}

export const notifyFinishTicketError = () => dispatch => {
  dispatch(notifyError('The ticket could not be finished. Please verify the needed fields.'))
}
