// @vendors
import React, { Fragment, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import EditIcon from '@material-ui/icons/Edit'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'

// @styles
import styles from '../../styles.module.sass'

const FacilityRow = ({ facility, handleEdit }) => {
  const { id, name, address, url, authType } = facility

  const [editMode, setEditMode] = useState(false)

  const handleEditMode = event => {
    event.stopPropagation()
    setEditMode(!editMode)
  }

  return (
    <Fragment>
      <TableRow onClick={handleEditMode} className={styles.tableRow}>
        <TableCell className={editMode === false ? styles.editModeOff : styles.editModeOn}>
          <IconButton aria-label="edit" onClick={handleEdit(id)}>
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell align="center">{name}</TableCell>
        <TableCell align="center">{address}</TableCell>
        <TableCell align="center">{url}</TableCell>
        <TableCell align="center">{authType === 1 ? 'Basic' : 'Bearer Token'}</TableCell>
      </TableRow>
      <TableRow className={styles.rowInMobile}>
        <TableCell className={styles.cellInMobile}>
          <Grid container direction="column" alignItems="center">
            <TableCell align="center" className={styles.fixedWidth}>
              {name}
            </TableCell>
            <TableCell align="center" className={styles.fixedWidth}>
              {address}
            </TableCell>
            <TableCell align="center" className={styles.fixedWidth}>
              {url}
            </TableCell>
            <TableCell align="center" className={styles.fixedWidth}>
              {authType === 1 ? 'Basic' : 'Bearer Token'}
            </TableCell>
          </Grid>
        </TableCell>
        <TableCell data-testid="handle-click" className={styles.mobileEditButton} onClick={handleEdit(id)}>
          <IconButton aria-label="edit">
            <EditIcon className={styles.mobileEditIcon} />
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default FacilityRow
