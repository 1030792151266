// @vendors
import React, { Fragment } from 'react'
import Card from '@material-ui/core/Card'

// @components
import NavigationBar from '../../../common-components/navigation-bar'
import PasswordInfo from './components/password-info'

// @styles
import styles from './styles.module.sass'

const ClientProfile = ({ client, updateClientPassword }) => {
  const passwordInfoProps = {
    client,
    updateClientPassword
  }

  return (
    <Fragment>
      <NavigationBar classes={{ bar: styles.navigationBar }} />
      <Card className={styles.card}>
        <PasswordInfo {...passwordInfoProps} />
      </Card>
    </Fragment>
  )
}

export default ClientProfile
