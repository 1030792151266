// @vendors
import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

// @styles
import styles from '../styles.module.sass'

const SuggestionInput = ({ inputAttributes, classes, ref, errorInput, helperText, ...other }) => (
  <TextField
    error={errorInput}
    InputProps={{
      inputRef: ref,
      classes: {
        root: styles.inputRoot,
        input: styles.inputInput
      },
      ...inputAttributes
    }}
    {...other}
    helperText={helperText}
  />
)

SuggestionInput.propTypes = {
  classes: PropTypes.object.isRequired,
  inputAttributes: PropTypes.object
}

export default SuggestionInput
