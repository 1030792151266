// @vendors
import React, { useState, useEffect } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// @styles
import styles from './styles.module.sass'

const SelectRadio = ({ control, isDisabled, onHandleChange, currentValue }) => {
  const [value, setValue] = useState(() => {
    const radioValue = currentValue || 'No'
    onHandleChange(radioValue)
    return radioValue
  })

  const handleChange = event => {
    event.stopPropagation()
    setValue(event.target.value)
    onHandleChange(event.target.value)
  }

  useEffect(() => {
    if (!currentValue) {
      setValue('No')
      onHandleChange('No')
    } else if (currentValue !== value) {
      setValue(currentValue)
      onHandleChange(currentValue)
    }
  }, [currentValue, onHandleChange, value])

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{control.label}</FormLabel>
      <RadioGroup value={value} onChange={handleChange} classes={{ root: styles.row }}>
        <FormControlLabel value={control.options ? control.options[0] : 'Yes'} disabled={isDisabled} control={<Radio />} label="Yes" />
        <FormControlLabel value={control.options ? control.options[1] : 'No'} disabled={isDisabled} control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  )
}

export default SelectRadio
