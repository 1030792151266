// @vendors
import React, { Fragment, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Card from '@material-ui/core/Card'
import PersonIcon from '@material-ui/icons/Person'

// @components
import ProfileInfo from './components/profile-info'
import PasswordInfo from './components/password-info'
import NavigationBar from '../../../common-components/navigation-bar'

// @styles
import styles from './styles.module.scss'

const CarrierProfile = ({ carrier, notifyInvalidFileType, updateCarrierAvatar, updateCarrier }) => {
  const [state, setState] = useState({
    editMode: false,
    avatarUrl: carrier ? carrier.avatar : null
  })

  const onEditProfile = value => {
    setState({
      ...state,
      editMode: value
    })
  }

  const onUpdateAvatar = file => {
    const data = new FormData()
    data.append('file', file)

    updateCarrierAvatar(data, carrier)
  }

  const onChangeAvatar = event => {
    const reader = new FileReader()
    const allowedImageTypes = ['image/png', 'image/jpg', 'image/jpeg']

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files

      const validType = !!allowedImageTypes.find(imagetype => imagetype === file.type)

      if (!validType) {
        return notifyInvalidFileType()
      }

      reader.readAsDataURL(file)

      reader.onload = e => {
        setState({
          ...state,
          avatarUrl: reader.result
        })
      }

      reader.onloadend = e => {
        onUpdateAvatar(file)
      }
    }
  }

  const onSelectAvatar = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.onchange = e => onChangeAvatar(e)
    input.click()
  }

  const profileInfoProps = {
    carrier,
    editMode: state.editMode,
    updateCarrier,
    onEditProfile
  }

  const passwordInfoProps = {
    carrier,
    updateCarrier
  }

  return (
    <Fragment>
      <NavigationBar classes={{ bar: styles.navigationBar }} />
      <Card className={styles.card}>
        <div className={styles.cardHeader}>
          {/*           {!state.editMode && (
            <IconButton aria-label="edit" onClick={() => onEditProfile(!state.editMode)}>
              <Icon>edit</Icon>
            </IconButton>
          )} */}
        </div>

        <Grid container justify="center" className={styles.avatarContainer}>
          {state.avatarUrl ? (
            <Avatar
              alt="carrier avatar"
              src={state.avatarUrl}
              className={state.editMode ? styles.clickableAvatar : styles.avatar}
              onClick={state.editMode ? onSelectAvatar : () => {}}
            />
          ) : (
            <PersonIcon className={state.editMode ? styles.clickableAvatar : styles.avatar} onClick={state.editMode ? onSelectAvatar : () => {}} />
          )}
        </Grid>
        <ProfileInfo {...profileInfoProps} />
      </Card>
      <Card className={styles.card}>
        <PasswordInfo {...passwordInfoProps} />
      </Card>
    </Fragment>
  )
}

export default CarrierProfile
