// @vendors
import React from 'react'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as AccountCircle } from '../assets/account-circle.svg'

// @components
import RenderMenuOptions from './navigation-bar-options'

// @styles
import styles from '../styles.module.sass'

const DesktopNavigationBar = ({ userLogged, handleProfileMenuOpen, menuId, menuOptionsEl, themingClientData }) => {
  const urlPath = userLogged.profile
  const nascentDefaultLogo = 'nascent-logob.png'
  const logoUrl = themingClientData ? themingClientData.logoUrl : nascentDefaultLogo

  return (
    <div className={styles.desktopOptions} ref={menuOptionsEl}>
      <img alt="Company logo" src={logoUrl} className={styles.menuButton} width="50px" />

      {RenderMenuOptions(urlPath)}

      <Typography className={styles.itemProfile} variant="subtitle1" noWrap>
        <Link to={`/${urlPath}/profile`}>{`${userLogged.fullName}`}</Link>
      </Typography>

      <div className={styles.userData}>
        <IconButton
          edge="end"
          aria-label="account of current driver"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
          className={userLogged.avatar ? styles.avatarWrapper : ''}
        >
          {userLogged.avatar ? <Avatar alt="profile" src={userLogged.avatar} className={styles.avatar} /> : <AccountCircle />}
        </IconButton>
      </div>
    </div>
  )
}

export default DesktopNavigationBar
