export const loadStateFromLocalStorage = () => {
  try {
    const savedStateJson = localStorage.getItem('state')

    if (savedStateJson === null) {
      return undefined
    }

    return JSON.parse(savedStateJson)
  } catch (e) {
    console.log(e)

    return undefined
  }
}

export const saveStateToLocalStorage = state => {
  try {
    const { security, form, driver, carrier, formValues, qr, tickets, carrierTicket, flags } = state
    const stateToSave = {
      security,
      form,
      driver,
      carrier,
      formValues,
      qr,
      tickets,
      carrierTicket,
      flags
    }
    const stateJson = JSON.stringify(stateToSave)

    localStorage.setItem('state', stateJson)
  } catch (e) {
    console.log(e)
  }
}

export const removeStateFromLocalStorage = () => {
  try {
    localStorage.removeItem('state')
  } catch (e) {
    console.log(e)
  }
}

export const clearLocalStorage = () => {
  try {
    localStorage.clear()
  } catch (e) {
    console.log(e)
  }
}
