// @vendors
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

// @styles
import './styles.scss'

const CardForm = ({ classes, children, fetchedTheme, fetchingTheme, header, themingClientData, title }) => {
  const themingDataInfo = themingClientData && themingClientData.client && themingClientData.client.logoUrl
  const nascentLogoUrl = fetchedTheme && !fetchingTheme && themingDataInfo
  const avatarStyles = (classes && classes.avatar) || 'card-form__logo-container'
  const cardFormTitle = title && (
    <Typography className="card-form__title" component="h2" gutterBottom variant="subtitle1">
      {title}
    </Typography>
  )

  return (
    <section className="view-form">
      <Grid className={avatarStyles} container>
        <Avatar alt="Company Logo" className="card-form__logo" imgProps={{ className: 'card-form__logo-properties' }} src={nascentLogoUrl} />
      </Grid>

      <div className="card-form">
        <Paper className="card-form__container">
          <Typography className="card-form__header" component="h4" variant="h5">
            {header}
          </Typography>

          {cardFormTitle}
          {children}
        </Paper>
      </div>
    </section>
  )
}

export default CardForm
