import React from 'react'
import { Redirect } from 'react-router-dom'
import useMount from '../../hooks/use-mount'

const Accept = ({ inviteId, acceptToken, acceptInvitation, email, profile }) => {
  useMount(() => {
    ;(async () => {
      await acceptInvitation(inviteId, acceptToken, email)
    })()
  })

  return <Redirect to={`/${profile.replace(profile.charAt(0), profile.charAt(0).toUpperCase())}/tickets`} />
}

export default Accept
