// @vendors
import React from 'react'
import Grid from '@material-ui/core/Grid'
import ReactPaginate from 'react-paginate'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined'
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined'

// @styles
import styles from './styles.module.sass'

const Pagination = ({ pager, onHandlePageChange, theming }) => {
  const { pageCount, pageSize, pageIndex } = pager

  const onPageChange = page => {
    const selectedPage = page.selected + 1
    onHandlePageChange(selectedPage, pageSize)
  }

  const handlePageSizeChange = event => {
    const newPageSize = event.target.value
    onHandlePageChange(1, newPageSize)
  }

  return (
    <Grid container direction="row" justify="flex-end" alignItems="flex-start" className={styles.paginationContainer}>
      <FormControl className={styles.formControl}>
        <InputLabel>Page size</InputLabel>
        <Select value={pageSize} onChange={handlePageSizeChange}>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </FormControl>

      <ReactPaginate
        initialPage={pageIndex - 1}
        previousLabel={<ArrowBackIosOutlinedIcon />}
        nextLabel={<ArrowForwardIosOutlinedIcon />}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        forcePage={pageIndex - 1}
        previousClassName={styles.previous}
        nextClassName={styles.next}
        containerClassName={styles.pagination}
        disabledClassName={styles.disabled}
        pageLinkClassName={styles.pageLink}
        activeClassName={styles.active}
        disableInitialCallback
      />
    </Grid>
  )
}

export default Pagination
