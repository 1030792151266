// @vendors
import { connect } from 'react-redux'

// @components
import EmailInvite from './invite'

// @actions
import { sendEmailInvite } from './actions'

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  sendEmailInvite
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailInvite)
