// @vendors
import React, { useState } from 'react'
import classnames from 'classnames'
import { Redirect, Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

// @components
import CardForm from '../../../common-components/card-form'

// @constants
import { ERROR_MESSAGE, EMAIL_HELPER_TEXT, EMAIL_INITIAL_STATE, RESET_PASSWORD_DESCRIPTION } from './constant'

// @utils
import { emailFormatValidation } from '../../../utils/field-value-validation'

// @styles
import './styles.scss'

const PasswordRecovery = ({ security, sendResetPasswordLink }) => {
  const [state, setState] = useState(EMAIL_INITIAL_STATE)
  const [helperText, setHelperText] = useState(EMAIL_HELPER_TEXT)

  const showError = () =>
    setState({
      ...EMAIL_INITIAL_STATE,
      pristine: false
    })

  const handleRecoverPassword = event => {
    event.preventDefault()

    if (state.valid) {
      const passwordRecoveryUrl = `${window.location.origin}/password-reset`

      sendResetPasswordLink(state.value, passwordRecoveryUrl)
      setState(EMAIL_INITIAL_STATE)
    } else {
      showError()
    }
  }

  const handleChange = name => event => {
    const value = event.target.value
    let valid = event.target.checkValidity()

    if (name === 'email') {
      valid = emailFormatValidation(value)

      if (!valid) {
        event.target.setCustomValidity(EMAIL_HELPER_TEXT[name])
        setHelperText({
          email: ERROR_MESSAGE
        })
      } else {
        event.target.setCustomValidity('')
        setHelperText({ ...helperText, [name]: null })
      }
    }

    setState({
      ...EMAIL_INITIAL_STATE,
      value,
      valid,
      pristine: false
    })
  }

  if (security) {
    return <Redirect to="/load" />
  }

  const emailValidity = !state.valid && !state.pristine
  const recoverButtonClasses = classnames('password-recovery__reset-button', {
    'password-recovery__reset-button--disabled': emailValidity || !state.value
  })

  return (
    <CardForm className="password-recovery" header="Forgot your password?">
      <Typography className="password-recovery__description" component="h4" variant="h5">
        {RESET_PASSWORD_DESCRIPTION}
      </Typography>

      <section className="password-recovery__container">
        <TextField
          error={emailValidity}
          helperText={helperText.email}
          id="email"
          InputProps={{
            className: 'password-recovery__email-field'
          }}
          label="Email"
          name="email"
          onChange={handleChange('email')}
          required
          value={state.value}
        />

        <div className="password-recovery__reset">
          <Button className={recoverButtonClasses} disabled={emailValidity} onClick={handleRecoverPassword} variant="contained">
            reset password
          </Button>
          <Typography align="center" component="h4" variant="caption">
            <Link className="password-recovery__reset-link" to="/">
              Take me to Login
            </Link>
          </Typography>
        </div>
      </section>
    </CardForm>
  )
}

export default PasswordRecovery
