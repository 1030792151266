// @vendors
import axios from 'axios'

// @utils
import httpConfig from '../../../../http/config'
import { createApiRequestHeaders } from '../../../../http/utils'

const { apiServer } = httpConfig

export const requestInputFetchData = ({
  inputValue,
  url,
  token,
  tokenType,
  ticketId,
  facilityId,
  notifyErrorData,
  isTicketCreated,
  handleSafeForLater,
  controlKey
}) => {
  const config = {
    headers: createApiRequestHeaders(token, tokenType)
  }

  const finalUrl = url.replace('{ticketId}', ticketId).replace('{text}', inputValue)

  return handleSafeForLater(controlKey).then(saved => {
    if (saved && facilityId) {
      return getFacilityData(config, finalUrl, facilityId).catch(err => {
        const message = err.response.data.Message
        notifyErrorData(`${message}`)
        throw message
      })
    } else if (!facilityId && inputValue) {
      const facilityNotSelected = new Promise((resolve, reject) => {
        reject('facility has not been selected')
      })

      return facilityNotSelected.catch(err => {
        notifyErrorData(`please, complete required fields`)
        throw err
      })
    } else if (facilityId && isTicketCreated) {
      return getFacilityData(config, finalUrl, facilityId).catch(err => {
        const message = err.response.data.Message
        notifyErrorData(`${message}`)
        throw err
      })
    }
  })
}

const getFacilityData = (config, finalUrl, facilityId) => {
  return axios.get(`${apiServer.url}${finalUrl}?facilityId=${facilityId}`, config).then(res => {
    return res.data
  })
}
