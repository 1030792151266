// @vendors
import { connect } from 'react-redux'

// @components
import { isGlobalSpinnerBusy } from '../selectors'
import GlobalBusySpinner from './global-busy-spinner'

const mapStateToProps = state => ({
  isBusy: isGlobalSpinnerBusy(state)
})

export default connect(mapStateToProps)(GlobalBusySpinner)
