// @vendors
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { useTheme } from '@material-ui/core/styles'

// @utils
import { setNotificationIcon, setNotificationStyles } from './utils'

// @styles
import styles from './styles.module.sass'

const NotificationContent = ({ type, message, onClose }) => {
  const notificationTheme = useTheme()
  const NotificationIcon = setNotificationIcon(type)
  const notificationStyles = setNotificationStyles(notificationTheme, type)

  const notificationMessage = (
    <span id="client-snackbar" className={styles.message}>
      <NotificationIcon className={`${styles.icon} ${styles.notificationIcon}`} />
      {message}
    </span>
  )
  const notificationCloseIcon = (
    <IconButton key="close" aria-label="close" color="inherit" onClick={onClose} className={styles.icon}>
      <CloseIcon />
    </IconButton>
  )

  return (
    <SnackbarContent style={notificationStyles} aria-describedby="client-snackbar" message={notificationMessage} action={[notificationCloseIcon]} />
  )
}

export default NotificationContent
