// @constants
import * as constants from './constant'
import * as securityConstants from '../../security/constant'

const initialState = []

const securityReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.ADD_NOTIFICATION:
      return [...state, action.payload]
    case constants.DISMISS_NOTIFICATION:
      return state.filter(notification => notification.id !== action.payload)
    case securityConstants.LOG_OUT:
      return initialState
    default:
      return state
  }
}

export default securityReducer
