// @vendors
import axios from 'axios'
// @contants
import httpConfig from '../../../../http/config'
import * as constants from './constant'
// @utils
import { createApiRequestHeaders, standardErrorHandler, standardRequestHandler, standardSuccessHandler } from '../../../../http/utils'

const { apiServer } = httpConfig

export const requestStepDetails = (stepId, ticketId) => (dispatch, getState) => {
  const {
    clientData: { clientId },
    security: { access_token, token_type }
  } = getState()
  const config = {
    headers: createApiRequestHeaders(access_token, token_type),
    params: {
      ticketId
    }
  }

  standardRequestHandler(dispatch, constants.REQUEST_STEP_DETAILS.REQUESTED, stepId)

  return axios
    .get(`${apiServer.url}/api/Clients/${clientId}/steps/${stepId}`, config)
    .then(res =>
      standardSuccessHandler(dispatch, constants.REQUEST_STEP_DETAILS.SUCCESS, {
        data: res.data,
        stepId
      })
    )
    .catch(err => standardErrorHandler(dispatch, err, constants.REQUEST_STEP_DETAILS.ERROR, 'Error Fetching Step Details'))
}

export const resetStepFetchStatus = id => ({
  type: constants.RESET_STEP_FETCH_STATUS,
  payload: id
})
