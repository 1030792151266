// @vendors
import axios from 'axios'

// @constants
import * as constants from './constant'

// @utils
import httpConfig from '../../../../http/config'
import { createApiRequestHeaders, standardRequestHandler, standardSuccessHandler, standardErrorHandler } from '../../../../http/utils'

const { apiServer } = httpConfig

export const requestNewForm = () => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType } = getState().security

  const { clientId } = getState().clientData

  const config = {
    headers: createApiRequestHeaders(token, tokenType)
  }

  standardRequestHandler(dispatch, constants.REQUEST_NEW_FORM.REQUESTED)

  return axios
    .get(`${apiServer.url}/api/Clients/${clientId}/ticket`, config)
    .then(res => standardSuccessHandler(dispatch, constants.REQUEST_NEW_FORM.SUCCESS, res.data))
    .catch(err => standardErrorHandler(dispatch, err, constants.REQUEST_NEW_FORM.ERROR, 'Error Fetching Form Steps'))
}

export const requestForm = ticketId => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType } = getState().security
  const { clientId } = getState().clientData

  const config = {
    headers: createApiRequestHeaders(token, tokenType)
  }

  standardRequestHandler(dispatch, constants.REQUEST_FORM.REQUESTED, {
    ticketId
  })

  return axios
    .get(`${apiServer.url}/api/Clients/${clientId}/ticket/${ticketId}`, config)
    .then(res => standardSuccessHandler(dispatch, constants.REQUEST_FORM.SUCCESS, res.data))
    .catch(err => standardErrorHandler(dispatch, err, constants.REQUEST_FORM.ERROR, 'Error Fetching Form Steps'))
}

export const resetFormFetchStatus = () => ({
  type: constants.RESET_FORM_FETCH_STATUS
})
