// @vendors
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import { Link, Redirect } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import FormControl from '@material-ui/core/FormControl'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'

// @components
import CardForm from '../../../common-components/card-form'

// @utils
import { getValues } from '../../../utils'

// @styles
import styles from './styles.module.sass'

// @utils
import httpConfig from '../../../http/config'
import useMount from '../../../hooks/use-mount'

const { apiServer } = httpConfig

const getCountryCode = () =>
  axios.get(`${apiServer.url}/api/Settings/countryCodes`).then(res =>
    res.data.map(countryCode => ({
      code: countryCode.code,
      label: countryCode.dial_code,
      countryName: countryCode.name
    }))
  )

const SignUp = ({ inviteToken, invitedEmail, createDriver, driver, getJwt, resetDriver, driverCreated, clientId, inviter, setFlag }) => {
  const [state, setState] = useState({
    firstName: { value: '', valid: false, pristine: true },
    lastName: { value: '', valid: false, pristine: true },
    email: {
      value: invitedEmail || '',
      valid: !!invitedEmail,
      pristine: !invitedEmail
    },
    countryCode: {
      value: '',
      valid: true,
      pristine: true
    },
    phoneNumber: { value: '', valid: false, pristine: true },
    password: { value: '', valid: false, pristine: true },
    confirmPassword: { value: '', valid: false, pristine: true },
    regexLowercaseLetterValid: true,
    regexUppercaseLetterValid: true,
    regexNumberValid: true,
    regexSpecialCharacterValid: true,
    regexLengthValid: true,
    form: { valid: false }
  })

  const [helperText, setHelperText] = useState({
    email: null,
    password: null,
    confirmPassword: null
  })

  const showErrors = () => {
    const newState = Object.keys(state).reduce((acc, name) => {
      const prop = { [name]: { ...state[name], pristine: false } }
      return { ...acc, ...prop }
    }, {})
    setState(newState)
  }

  const [countryCode, setCountryCode] = useState({
    data: [],
    label: 'Country Code',
    placeholder: 'Select Country Code'
  })

  const handleClickShowPassword = name => () => {
    setState({ ...state, [name]: { ...state[name], show: !state[name].show } })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleChangePassword = event => {
    const { target } = event
    const { form, name, value } = target
    let valid = event.target.checkValidity()

    if (name === 'password') {
      const confirmPasswordControl = document.querySelector('#confirmPassword')
      let newState = { ...state }

      var regexUppercaseLetter = /[A-Z]/
      var regexLowercaseLetter = /[a-z]/
      var regexNumber = /\d/
      var regexSpecialCharacter = /[!@#$%^&"'*\\()_+=\[{\]};:<>|./?,-]/
      var regexLength = /(?=.{6,32})/

      newState = {
        ...newState,
        regexUppercaseLetterValid: regexUppercaseLetter.test(value),
        regexLowercaseLetterValid: regexLowercaseLetter.test(value),
        regexNumberValid: regexNumber.test(value),
        regexSpecialCharacterValid: regexSpecialCharacter.test(value),
        regexLengthValid: regexLength.test(value),
        password: { value: value }
      }

      if (
        newState.regexLowercaseLetterValid &&
        newState.regexUppercaseLetterValid &&
        newState.regexNumberValid &&
        newState.regexSpecialCharacterValid &&
        newState.regexLengthValid
      ) {
        newState = {
          ...newState,
          [name]: { ...state[name], value, valid, pristine: false },
          password: { value: value, valid: true, pristine: true },
          form: { valid: form.checkValidity() }
        }
      } else {
        newState = {
          ...newState,
          [name]: { ...state[name], value, valid, pristine: false },
          password: { value: value, valid: false, pristine: false },
          form: { valid: false }
        }
      }

      if (value && state.confirmPassword.value && value !== state.confirmPassword.value) {
        confirmPasswordControl.setCustomValidity("Password doesn't match.")
        setHelperText({ ...helperText, confirmPassword: confirmPasswordControl.validationMessage })

        newState = {
          ...newState,
          [name]: { ...state[name], value, valid, pristine: false },
          confirmPassword: { value: state.confirmPassword.value, valid: false, pristine: false },
          form: { valid: false }
        }
      } else if (value && state.confirmPassword.value && value === state.confirmPassword.value) {
        confirmPasswordControl.setCustomValidity('')
        setHelperText({ ...helperText, confirmPassword: null })

        newState = {
          ...newState,
          [name]: { ...state[name], value, valid, pristine: false },
          confirmPassword: { value: state.confirmPassword.value, valid: true, pristine: false },
          form: { valid: form.checkValidity() }
        }
      }

      return setState(newState)
    } else if (name === 'confirmPassword') {
      if (value !== state.password.value) {
        valid = false

        event.target.setCustomValidity("Password doesn't match.")
        setHelperText({ ...helperText, [name]: event.target.validationMessage })
      } else {
        valid = true
        event.target.setCustomValidity('')
        setHelperText({ ...helperText, [name]: null })
      }
    }

    setState({
      ...state,
      [name]: { ...state[name], value, valid, pristine: false },
      form: { valid: form.checkValidity() }
    })
  }

  const handleChange = event => {
    const { target } = event
    const { form, name, value: controlValue } = target

    let value = controlValue
    let valid = event.target.checkValidity()

    if (name === 'firstName' || name === 'lastName') {
      value = value.replace(/\d+/g, '')
    } else if (name === 'phoneNumber') {
      value = value.replace(/[^\w\s]|[a-zA-Z]|\_/gi, '')
    } else if (name === 'email') {
      const emailFormat = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
      valid = emailFormat.test(value)

      if (!valid) {
        const ht = {
          email: 'Email format is incorrect'
        }

        event.target.setCustomValidity(ht[name])
        setHelperText({ ...helperText, [name]: event.target.validationMessage })
      } else {
        event.target.setCustomValidity('')
        setHelperText({ ...helperText, [name]: null })
      }
    }

    setState({
      ...state,
      [name]: { ...state[name], value, valid, pristine: false },
      form: { valid: form.checkValidity() }
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (!state.form.valid) {
      return showErrors()
    }

    let values = getValues(state)

    if (inviteToken) {
      values = { ...values, token: inviteToken, clientId }
    }

    createDriver(values, inviter).then(response => setFlag('justCreatedDriver', true))
  }

  const handleCountryCode = event => {
    setState({
      ...state,
      countryCode: {
        ...state.countryCode,
        value: event.target.value,
        valid: true,
        pristine: false
      }
    })
  }

  useMount(() => {
    getCountryCode().then(countryCode => {
      setCountryCode({
        data: countryCode,
        label: 'Country Code',
        placeholder: 'Select Country Code'
      })
    })
  })

  useEffect(() => {
    if (driverCreated && driver) {
      getJwt(driver.email, driver.password)
      resetDriver()
    }
  }, [driverCreated, driver, getJwt, resetDriver])

  const findeDefaultValue = countryCode.data.find(country => country.code && country.code === 'US')
  const countryCodeValue =
    !state.countryCode.value && findeDefaultValue && findeDefaultValue?.code
      ? setState({
          ...state,
          ...state.countryCode,
          countryCode: { value: findeDefaultValue.code }
        })
      : state.countryCode.value

  return (
    (driverCreated && <Redirect to="/" />) || (
      <CardForm header="Create your account" title="Personal info" classes={{ avatar: styles.avatar }}>
        <form noValidate autoComplete="off" className={styles.form} onSubmit={handleSubmit}>
          <TextField
            required
            error={!state.firstName.valid && !state.firstName.pristine}
            name="firstName"
            label="First name"
            className={styles.textField}
            value={state.firstName.value}
            onChange={handleChange}
          />
          <TextField
            required
            error={!state.lastName.valid && !state.lastName.pristine}
            name="lastName"
            label="Last name"
            className={styles.textField}
            value={state.lastName.value}
            onChange={handleChange}
          />
          <TextField
            required
            error={!state.email.valid && !state.email.pristine}
            name="email"
            label="E-mail"
            className={styles.fullWidth}
            value={state.email.value}
            helperText={helperText.email}
            onChange={handleChange}
            disabled={!!invitedEmail}
          />
          <FormControl className={styles.phoneData}>
            <InputLabel name="license-state-label">Country Code*</InputLabel>
            <Select
              name="country-code"
              className={styles.countryCode}
              label={countryCode.label}
              placeholder={countryCode.placeholder}
              value={countryCodeValue}
              renderValue={value => value}
              onChange={handleCountryCode}
            >
              {countryCode.data.map((country, index) => (
                <MenuItem className={styles.countryCodeOptions} key={`${country.label}-${index}`} value={country.code}>
                  <span className={styles.countryCodeValue}>{country.label}</span>
                  <span className={styles.countryCodeCountry}>{`(${country.code}) - ${country.countryName}`}</span>
                </MenuItem>
              ))}
            </Select>
            <TextField
              required
              error={!state.phoneNumber.valid && !state.phoneNumber.pristine}
              name="phoneNumber"
              label="Phone number"
              className={styles.phoneNumber}
              value={state.phoneNumber.value}
              onChange={handleChange}
              type="text"
            />
          </FormControl>
          <TextField
            required
            error={!state.password.valid && !state.password.pristine}
            name="password"
            id="password"
            label="Password"
            className={styles.fullWidth}
            value={state.password.value}
            onChange={handleChangePassword}
            type={(state.password.show && 'text') || 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword('password')}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {(state.password.show && <Visibility />) || <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Typography
            variant="caption"
            color="textSecondary"
            align="center"
            style={{
              display:
                state.regexLowercaseLetterValid &&
                state.regexUppercaseLetterValid &&
                state.regexNumberValid &&
                state.regexSpecialCharacterValid &&
                state.regexLengthValid
                  ? 'block'
                  : 'none'
            }}
          >
            Passwords must be at least 6-32 characters long containing at least one uppercase letter, one lowercase letter, one number and one special
            character
          </Typography>
          <div>
            <Typography variant="caption" color="error" style={{ display: state.regexLowercaseLetterValid ? 'none' : 'block' }}>
              Password must contain at least one lower case letter
            </Typography>
            <Typography variant="caption" color="error" style={{ display: state.regexUppercaseLetterValid ? 'none' : 'block' }}>
              Password must contain at least one upper case letter
            </Typography>
            <Typography variant="caption" color="error" style={{ display: state.regexNumberValid ? 'none' : 'block' }}>
              Password must contain at least one number
            </Typography>
            <Typography variant="caption" color="error" style={{ display: state.regexSpecialCharacterValid ? 'none' : 'block' }}>
              Password must contain at least one special character
            </Typography>
            <Typography variant="caption" color="error" style={{ display: state.regexLengthValid ? 'none' : 'block' }}>
              Password must be at least 6-32 characters long
            </Typography>
          </div>
          <TextField
            required
            error={!state.confirmPassword.valid && !state.confirmPassword.pristine}
            name="confirmPassword"
            id="confirmPassword"
            label="Confirm Password"
            className={styles.fullWidth}
            value={state.confirmPassword.value}
            onChange={handleChangePassword}
            type={(state.confirmPassword.show && 'text') || 'password'}
            disabled={!state.password.valid}
            helperText={helperText.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword('confirmPassword')}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {(state.confirmPassword.show && <Visibility />) || <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <div className={styles.buttonWrapper}>
            <Button disabled={!state.form.valid} variant="contained" className={styles.button} color="primary" size="large" type="submit">
              SIGN UP
            </Button>
            <Typography variant="caption">
              <Link to="/">Take me to Login</Link>
            </Typography>
          </div>
        </form>
      </CardForm>
    )
  )
}

export default SignUp
