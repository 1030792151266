// @vendors
import { connect } from 'react-redux'

// @components
import ClientLoader from './client-loader'

const mapStateToProps = state => ({
  security: state.security
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ClientLoader)
