import { connect } from 'react-redux'

// @components
import ClientProfile from './client-profile'

// @action
import { updateClientPassword } from './actions'

// @utils
import { getClient } from './selector'

const mapStateToProps = state => ({
  client: getClient(state)
})
const mapDispatchToProps = {
  updateClientPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfile)
