export const getLoginRedirection = profile => {
  switch (profile) {
    case 'Driver':
      return '/driver'
    case 'Client':
      return '/client'
    case 'Carrier':
      return '/carrier'
    default:
      return '/'
  }
}
