import { connect } from 'react-redux'

// @components
import CarrierProfile from './carrier-profile'

// @action
import { requestCarrier, updateCarrier, updateCarrierAvatar, notifyInvalidFileType } from './actions'

// @utils
import { getCarrier } from './selector'

const mapStateToProps = state => ({
  carrier: getCarrier(state)
})
const mapDispatchToProps = {
  requestCarrier,
  updateCarrier,
  updateCarrierAvatar,
  notifyInvalidFileType
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierProfile)
