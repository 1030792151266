// @vendors
import React, { useState, useRef, useEffect } from 'react'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

// @components
import SuggestionInput from '../components/suggestions-input'
import SuggestionsListItem from '../components/suggestions-list-item'

// @utils
import { formatToMultiSelectValue, formatFromMultiSelectValue, getSuggestions, validate } from './utils'

// @styles
import styles from '../styles.module.sass'

const DownshiftMultiple = ({ classes, control, isDisabled, onHandleChange, currentValue }) => {
  const [inputValue, setInputValue] = useState('')
  const [selectedItem, setSelectedItem] = useState(() => {
    const sealNumbersValue = formatFromMultiSelectValue(currentValue)
    setInputValue('')
    onHandleChange(formatToMultiSelectValue(sealNumbersValue))
    return sealNumbersValue
  })

  const inputEl = useRef(null)
  const [errorInput, setErrorInput] = useState(false)
  const [helperText, setHelperText] = useState(null)

  useEffect(() => {
    if (currentValue !== control.currentValue) {
      validate({
        input: inputEl.current,
        min: control.min,
        max: control.max,
        selectedItems: selectedItem,
        setErrorInput,
        setHelperText,
        isRequired: control.required,
        requiredMessage: control.invalidValueMessage
      })
    }
  }, [currentValue])

  const handleKeyDown = event => {
    if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
      const items = selectedItem.slice(0, selectedItem.length - 1)
      setSelectedItem(items)
      validate({
        input: inputEl.current,
        min: control.min,
        max: control.max,
        selectedItems: items,
        setErrorInput,
        setHelperText,
        isRequired: control.required
      })
    }
  }

  const handleClickAdd = event => {
    event.stopPropagation()

    if (inputValue.trim()) {
      handleChange(inputValue)
    }
    setInputValue('')
  }

  const handleInputChange = event => {
    const value = event.target.value
    const formatedValue = value.replace(/[^\w\s]/gi, '')
    setInputValue(formatedValue)
  }

  const handleChange = item => {
    let newSelectedItem = [...selectedItem]

    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item]
    }

    setInputValue('')
    setSelectedItem(newSelectedItem)
    onHandleChange(formatToMultiSelectValue(newSelectedItem))

    validate({
      input: inputEl.current,
      min: control.min,
      max: control.max,
      selectedItems: newSelectedItem,
      setErrorInput,
      setHelperText,
      isRequired: control.required
    })
  }

  const handleDelete = item => () => {
    const newSelectedItem = [...selectedItem]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)

    setSelectedItem(newSelectedItem)
    onHandleChange(formatToMultiSelectValue(newSelectedItem))

    validate({
      input: inputEl.current,
      min: control.min,
      max: control.max,
      selectedItems: newSelectedItem,
      setErrorInput,
      setHelperText,
      isRequired: control.required
    })
  }

  return (
    <Downshift id="downshift-multiple" inputValue={inputValue} onChange={handleChange} selectedItem={selectedItem}>
      {({ getInputProps, getItemProps, getLabelProps, isOpen, inputValue: inputValue2, selectedItem: selectedItem2, highlightedIndex }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          disabled: isDisabled,
          onKeyDown: handleKeyDown,
          placeholder: control.placeholder,
          ref: inputEl
        })

        return (
          <div className={classes.container}>
            {SuggestionInput({
              fullWidth: true,
              classes,
              label: control.label,
              InputLabelProps: getLabelProps(),
              inputAttributes: {
                startAdornment: selectedItem.map((item, index) => (
                  <Chip key={index} tabIndex={-1} label={item} disabled={isDisabled} className={classes.chip} onDelete={handleDelete(item)} />
                )),
                onBlur,
                onChange: event => {
                  handleInputChange(event)
                  onChange(event)
                },
                onFocus
              },
              errorInput,
              helperText,
              inputProps
            })}

            {inputValue2 && (
              <Paper className={classes.paper} square>
                {getSuggestions(inputValue2, {
                  suggestions: control.data ? control.data : []
                }).map((suggestion, index) =>
                  SuggestionsListItem({
                    suggestion,
                    index,
                    itemProps: getItemProps({ item: suggestion.label }),
                    highlightedIndex,
                    selectedItem: selectedItem2
                  })
                )}
                <Button onClick={handleClickAdd} className={styles.button} disabled={selectedItem.length === control.max}>
                  Add
                </Button>
              </Paper>
            )}
          </div>
        )
      }}
    </Downshift>
  )
}

DownshiftMultiple.propTypes = {
  classes: PropTypes.object.isRequired
}

export default DownshiftMultiple
