// @vendors
import { connect } from 'react-redux'

// @components
import Facilities from './facilities'

// @utils
import { getClient } from './selectors'

// @actions
import { getFacilities } from './actions'

const mapStateToProps = state => ({
  facilities: getClient(state).list,
  security: state.security
})

const mapDispatchToProps = {
  getFacilities
}

export default connect(mapStateToProps, mapDispatchToProps)(Facilities)
