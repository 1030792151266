// @utils
import { buildActionType, buildAsyncActionType } from '../../../../store/utils'

export const MODULE_NAME = 'form'

export const REQUEST_NEW_FORM = buildAsyncActionType(MODULE_NAME, 'REQUEST_NEW_FORM')

export const REQUEST_FORM = buildAsyncActionType(MODULE_NAME, 'REQUEST_FORM')

export const RESET_FORM_FETCH_STATUS = buildActionType(MODULE_NAME, 'RESET_FORM_FETCH_STATUS')
