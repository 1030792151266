// @vendors
import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

// @components
import Control from './control'

const Controls = ({ controls, enableToHidden, isDisabled, requiredControls, setAllowContinue }) => {
  const [requiredControlsState, setRequiredControlsState] = useState([...requiredControls])

  useEffect(() => {
    if (requiredControls.length) {
      setRequiredControlsState([...requiredControls])
    } else {
      setRequiredControlsState([])
    }
  }, [requiredControls, requiredControls.length])

  const removeControlFromRequireControlList = key => {
    const newRequiredControls = requiredControlsState.filter(control => control.key !== key)

    setRequiredControlsState([...newRequiredControls])
  }

  const addControlToRequiredControlList = control => {
    setRequiredControlsState([...requiredControlsState, control])
  }

  return (
    <Box>
      <Grid container spacing={0}>
        {controls.map((control, idx) => {
          const isRequired = requiredControlsState.some(requiredControl => control.key === requiredControl.key)
          return (
            <Control
              isDisabled={isDisabled}
              key={idx}
              control={control}
              isRequired={isRequired}
              enableToHidden={enableToHidden}
              removeControlFromRequireControlList={removeControlFromRequireControlList}
              addControlToRequiredControlList={addControlToRequiredControlList}
              setAllowContinue={setAllowContinue}
            />
          )
        })}
      </Grid>
    </Box>
  )
}

export default Controls
