// @vendors
import React from 'react'
import { NavLink } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

// @constants
import { PATH_NAME as driverPathName } from '../../../pages/driver/constant'

// @styles
import styles from '../styles.module.sass'

const DriverAppNavigationOptions = () => (
  <>
    <Typography className={styles.item} variant="subtitle1" noWrap>
      <NavLink activeStyle={{ fontWeight: 900 }} to={`${driverPathName}/tickets`}>
        My Tickets
      </NavLink>
    </Typography>

    <div className={styles.space} />
  </>
)

export default DriverAppNavigationOptions
