// @vendors
import React, { Fragment, useEffect, useState } from 'react'
import Radio from '@material-ui/core/Radio'
import { CardContent, Card, FormControlLabel, RadioGroup } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { Checkbox } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

// @utils
import { getValues, setInitState } from '../../../../../utils'
import withMissingPropsRedirect from '../../../../../utils/redirection/with-missing-props-redirection'

// @components
import NavigationBar from '../../../../../common-components/navigation-bar'

// @constants
import { unauthorizedRedirect } from '../../../../../utils/redirection/constant'

// @styles
import styles from '../../styles.module.sass'

const formFields = [
  { key: 'name', defaultValue: '' },
  { key: 'address', defaultValue: '' },
  { key: 'url', defaultValue: '' },
  { key: 'authType', defaultValue: '1' },
  { key: 'siteName', defaultValue: '' },
  { key: 'latitude', defaultValue: '' },
  { key: 'longitude', defaultValue: '' },
  { key: 'enabled', defaultValue: false }
]

const getInitialState = () => {
  const inititalState = {}

  formFields.forEach(field => {
    inititalState[field.key] = {
      value: field.defaultValue,
      valid: false,
      pristine: true
    }
  })

  inititalState.form = { valid: false }

  return inititalState
}

const FacilityForm = ({ createFacility, updateFacility, getFacility, facility, facilityId }) => {
  const [state, setState] = useState(getInitialState())
  const [helperText, setHelperText] = useState({
    latitude: null,
    longitude: null,
    url: null,
    siteName: null
  })

  const handleChange = name => event => {
    event.stopPropagation()

    const { target } = event
    const { value, type, checked } = target

    let valid = target.checkValidity()

    if (name === 'url') {
      const urlFormat = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g
      valid = urlFormat.test(value)

      if (!valid) {
        const ht = {
          url: 'URL format incorrect'
        }
        event.target.setCustomValidity(ht[name])
        setHelperText({ ...helperText, [name]: event.target.validationMessage })
      } else {
        event.target.setCustomValidity('')
        setHelperText({ ...helperText, [name]: null })
      }
    }

    if (name === 'siteName') {
      const siteNameFormat = /^[a-zA-Z0-9]([a-zA-Z]||[0-9]||\s)+$/g
      valid = siteNameFormat.test(value)

      if (!valid) {
        const ht = {
          siteName: 'Sitename must be alphanumeric'
        }
        event.target.setCustomValidity(ht[name])
        setHelperText({ ...helperText, [name]: event.target.validationMessage })
      } else {
        event.target.setCustomValidity('')
        setHelperText({ ...helperText, [name]: null })
      }
    }

    setState({
      ...state,
      [name]: {
        value: type === 'checkbox' ? checked : value,
        valid,
        pristine: false
      },
      form: { valid: target.form.checkValidity() }
    })
  }

  const handleChangeCoordinates = name => event => {
    event.stopPropagation()

    const decimal = /^[-+]?[0-9]+\.[0-9]+$/
    const ht = {
      latitude: 'Allowed format: 4.667118',
      longitude: 'Allowed format: -74.094750'
    }

    const { value } = event.target

    if (value.match(decimal) || value === '') {
      event.target.setCustomValidity('')
      setHelperText({ ...helperText, [name]: null })
    } else {
      event.target.setCustomValidity(ht[name])
      setHelperText({ ...helperText, [name]: event.target.validationMessage })
    }

    const valid = value ? event.target.checkValidity() : true

    setState({
      ...state,
      [name]: { value, valid, pristine: false },
      form: { valid: event.target.form.checkValidity() }
    })
  }

  const showErrors = () => {
    const newState = {}

    formFields.forEach(field => {
      newState[field.key] = { ...state[field.key], pristine: false }
    })

    newState.form = { valid: false }

    setState(newState)
  }

  const onSave = event => {
    event.preventDefault()

    if (state.form.valid) {
      const values = getValues(state)

      if (facilityId) {
        values.id = facilityId
        updateFacility(values)
      } else {
        createFacility(values)
      }
      return
    }
    showErrors()
  }

  useEffect(() => {
    if (facilityId && !facility) {
      getFacility(facilityId)
    } else if (facility) {
      setState(setInitState(facility))
    }
  }, [facilityId, getFacility, facility])

  return (
    <Fragment>
      <NavigationBar />
      <Card className={styles.card}>
        <CardContent>
          <Typography variant="h5" component="h4" align="center">
            {facilityId ? 'Update ' : 'Create '}Facility
          </Typography>
          <form onSubmit={onSave} noValidate autoComplete="off" className={styles.form}>
            <TextField
              className={styles.textField}
              required
              error={!state.name.valid && !state.name.pristine}
              label="Name"
              margin="normal"
              value={state.name.value}
              onChange={handleChange('name')}
            />
            <TextField
              className={styles.textField}
              required
              error={!state.address.valid && !state.address.pristine}
              label="Address"
              margin="normal"
              value={state.address.value}
              onChange={handleChange('address')}
            />
            <TextField
              className={styles.textField}
              required
              error={!state.url.valid && !state.url.pristine}
              helperText={helperText.url}
              label="Url"
              margin="normal"
              placeholder="https://www.example.com or http://www.example.com"
              value={state.url.value}
              onChange={handleChange('url')}
            />
            <RadioGroup className={styles.RadioControl} required onChange={handleChange('authType')}>
              {'Url Authenticaton Type'}

              <FormControlLabel control={<Radio value={1} name={'radioBasic'} checked={state.authType.value == 1} />} label={'Basic'} />
              <FormControlLabel control={<Radio value={2} name={'radioBearer'} checked={state.authType.value == 2} />} label={'Bearer Token'} />
            </RadioGroup>
            <TextField
              className={styles.textField}
              required
              error={!state.siteName.valid && !state.siteName.pristine}
              helperText={helperText.siteName}
              label="Sitename"
              margin="normal"
              value={state.siteName.value}
              onChange={handleChange('siteName')}
            />
            <div className={styles.textField}>
              <TextField
                className={styles.ControlCoordinates}
                style={{ marginRight: '10%' }}
                error={!state.latitude.valid && !state.latitude.pristine}
                helperText={helperText.latitude}
                label="Latitude"
                margin="normal"
                value={state.latitude.value}
                onChange={handleChangeCoordinates('latitude')}
              />
              <TextField
                className={styles.ControlCoordinates}
                error={!state.longitude.valid && !state.longitude.pristine}
                helperText={helperText.longitude}
                label="Longitude"
                margin="normal"
                value={state.longitude.value}
                onChange={handleChangeCoordinates('longitude')}
              />
            </div>
            <div className={styles.RadioControl}>
              <FormControlLabel
                control={<Checkbox checked={state.enabled.value} onChange={handleChange('enabled')} color="primary" />}
                label="Enabled"
              />
            </div>
            <Button data-testid="handle-submit" variant="contained" color="primary" size="large" type="submit" className={styles.createButton}>
              {facility ? 'UPDATE' : 'CREATE'}
            </Button>
          </form>
        </CardContent>
      </Card>
    </Fragment>
  )
}

export default withMissingPropsRedirect([unauthorizedRedirect], FacilityForm)
