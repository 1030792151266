// @vendors
import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// @components
import AssignDriverModal from '../../../../common-components/tickets-list/components/assign'

// @styles
import styles from './styles.module.scss'

const AssignDriverBar = ({ driver, onHandleSaveForLater, drivers, requestDrivers, assignDriver }) => {
  const [openAssignDriverModal, setOpenAssignDriver] = useState(false)
  const [selectedDriver, setSelectedDriver] = useState(null)

  const handleRedirect = () => {
    onHandleSaveForLater()
      .then(ticketHasBeenSaved => {
        if (ticketHasBeenSaved) {
          return requestDrivers()
        }
        throw new Error('do not redirect')
      })
      .then(() => {
        setOpenAssignDriver(true)
      })
      .catch(err => err)
  }

  const onCloseAssignDriverModal = () => {
    setOpenAssignDriver(false)
  }

  const onSelectDriver = driver => {
    setSelectedDriver(driver)
  }

  const refreshTickets = () => {
    setOpenAssignDriver(false)
    setSelectedDriver(null)
  }

  return (
    <div className={styles.container}>
      {driver && (
        <Typography>
          Your are completing <strong>{`${driver.firstName} ${driver.lastName}'s`}</strong> form
        </Typography>
      )}
      <Button size="large" variant="contained" color="primary" onClick={handleRedirect} className={styles.button}>
        {driver ? 'REASSIGN DRIVER' : 'ASSIGN DRIVER'}
      </Button>
      <AssignDriverModal
        drivers={drivers}
        onClose={onCloseAssignDriverModal}
        isOpen={openAssignDriverModal}
        assignTicketsRequest={assignDriver}
        refreshTickets={refreshTickets}
        onSelectDriver={onSelectDriver}
        selectedDriver={selectedDriver}
      />
    </div>
  )
}

export default AssignDriverBar
