// @vendors
import React from 'react'

// @components
import DriverAppNavigationOptions from './driver-options'
import ClientAppNavigationOptions from './client-options'
import CarrierAppNavigationOptions from './carrier-options'

const RenderMenuOptions = userProfile => {
  switch (userProfile) {
    case 'carrier':
      return <CarrierAppNavigationOptions />
    case 'client':
      return <ClientAppNavigationOptions />
    case 'driver':
      return <DriverAppNavigationOptions />

    default:
      break
  }
}

export default RenderMenuOptions
