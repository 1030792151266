// @vendors
import React, { useState } from 'react'
import classNames from 'classnames'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Checkbox from '@material-ui/core/Checkbox'

// @components
import { QRDetailsModal } from '../../../../pages/driver/components/qr'
import TicketRowCell from './components/ticket-row-cell'
import ConfirmationModal from '../../../confirmation-modal'

// @styles
import styles from './styles.module.sass'
import { Button } from '@material-ui/core'

const TicketRow = ({
  head,
  ticket,
  isTicketSelected,
  handleRowClick,
  onHandleDelete,
  onHandleEdit,
  ticketId,
  ticketNumber,
  ticketStatus,
  security,
  onCheckTicket,
  ticketChecked
}) => {
  const { Profile: profile } = security
  const ticketQr = ticket.find(cell => cell.key === 'QRCodeUrl').label
  const showDeleteIcon = ticketStatus === 'Incomplete' || ticketStatus === 'Ready'
  const showViewIcon = ticketStatus === 'Done' || ticketStatus === 'InProgress'
  const showCheckBox = ticketStatus === 'Incomplete' || ticketStatus === 'Ready'

  const [showConfirmationDialog, showDialogValue] = useState(false)
  const [checked, setChecked] = useState(false)
  const handleShowDialog = () => showDialogValue(!showConfirmationDialog)

  const onChangeHandler = event => {
    const {
      target: { checked, value }
    } = event

    setChecked(checked)
    onCheckTicket(value, ticketNumber)
  }

  const renderCells = () => {
    let cells = []

    if (profile.toLowerCase() === 'carrier') {
      cells = [
        ...cells,

        <TableCell key={ticketId}>
          {showCheckBox && (
            <Checkbox
              key={ticketId}
              checked={ticketChecked || checked}
              value={ticketId}
              onChange={onChangeHandler}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          )}
        </TableCell>
      ]
    }

    if (profile.toLowerCase() === 'driver' || 'carrier') {
      cells = [
        ...cells,
        <TableCell key="selection" className={`${styles.bodyCell} ${styles.hideInMobile}`}>
          <div className={styles.iconConatiner}>
            {showDeleteIcon && (
              <Button size="small" variant="contained" color="primary" className={styles.marginButton} onClick={handleShowDialog}>
                Delete
              </Button>
            )}
            {showViewIcon ? (
              <IconButton aria-label="edit" onClick={event => onHandleEdit(event, ticketId)}>
                <VisibilityIcon />
              </IconButton>
            ) : (
              <Button
                size="small"
                variant="contained"
                color="primary"
                className={styles.marginButton}
                onClick={event => onHandleEdit(event, ticketId)}
              >
                Edit
              </Button>
            )}
          </div>

          <ConfirmationModal
            handleConfirmation={onHandleDelete}
            onClose={handleShowDialog}
            showDialog={showConfirmationDialog}
            ticketNumber={ticketNumber}
            ticketStatus={ticketStatus}
          />
        </TableCell>
      ]
    }

    cells = [
      ...cells,
      ...head
        .filter(header => header.label)
        .map(header => {
          return (
            <TableCell key={header.key} className={styles.bodyCell}>
              <TicketRowCell ticketCell={ticket.find(cell => cell.key === header.key)} ticketId={ticketId} security={security} />
            </TableCell>
          )
        })
    ]

    if (profile.toLowerCase() === 'driver' || 'carrier') {
      cells = [
        ...cells,
        <TableCell key="qr" className={styles.bodyCell}>
          {ticketQr && (ticketStatus === 'Ready' || ticketStatus === 'InProgress') ? (
            <QRDetailsModal qrCodeUrl={ticketQr} ticketId={ticketId} />
          ) : null}
        </TableCell>
      ]
    }

    return cells
  }

  return (
    <>
      <TableRow className={styles.row}>{[...renderCells(head)]}</TableRow>
      <TableRow
        className={classNames(
          {
            [styles.selected]: isTicketSelected
          },
          [styles.rowInMobile]
        )}
      >
        <TableCell className={styles.bodyTicket} onClick={() => handleRowClick(ticketId)}>
          <div className={styles.cellInMobile}>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={styles.primaryColor}>
              {[
                ...head
                  .filter(header => header.label)
                  .map(header => (
                    <Grid key={header.key} item xs={6}>
                      <Box my={1}>
                        <Typography variant="caption" component="p" color="primary">
                          {header.label}
                        </Typography>
                        <TicketRowCell ticketCell={ticket.find(cell => cell.key === header.key)} ticketId={ticketId} security={security} />
                      </Box>
                    </Grid>
                  )),
                <Grid key="qr" item xs={6}>
                  {ticketStatus === 'Ready' && (
                    <Box my={0.25}>{ticketQr ? <QRDetailsModal qrCodeUrl={ticketQr} ticketId={ticketId} /> : 'Unavailable'}</Box>
                  )}
                </Grid>
              ]}
            </Grid>
          </div>
        </TableCell>
        <TableCell className={styles.mobileEditButton}>
          {showDeleteIcon && (
            <IconButton aria-label="delete" onClick={handleShowDialog}>
              <DeleteIcon className={styles.mobileIcon} />
            </IconButton>
          )}
          {showViewIcon ? (
            <IconButton aria-label="edit" onClick={event => onHandleEdit(event, ticketId)}>
              <VisibilityIcon className={styles.mobileIcon} />
            </IconButton>
          ) : (
            <IconButton aria-label="edit" onClick={event => onHandleEdit(event, ticketId)}>
              <EditIcon className={styles.mobileIcon} />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </>
  )
}

export default TicketRow
