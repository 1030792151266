// @vendors
import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'

// @components
import InputFetch from '../../input-fetch'
import BasicSelect from '../../basic-select'
import SelectRadio from '../../radio-select'
import InputSelect from '../../input-select'
import ImageSelection from '../../image-selection'
import InputMultiSelect from '../../input-select/input-multi-select'
import CustomRadioControl from '../../custom-radio-control'

// @styles
import styles from './styles.module.sass'

const getControl = controlType => {
  switch (controlType) {
    case 'select|text':
      return InputSelect
    case 'select|image':
      return ImageSelection
    case 'text|service':
      return InputFetch
    case 'select|multiple':
      return InputMultiSelect
    case 'select|radio':
      return SelectRadio
    case 'select|dropDown':
      return BasicSelect
    case 'custom|radio':
      return CustomRadioControl
    default:
      return null
  }
}

const getControlSizing = controlType => {
  switch (controlType) {
    case 'select|text':
      return 6
    case 'select|image':
      return 12
    default:
      return 12
  }
}

const Control = ({
  control,
  enableToHidden,
  formValues,
  updateFormValue,
  isRequired,
  isDisabled,
  removeControlFromRequireControlList,
  addControlToRequiredControlList,
  setAllowContinue,
  setFlag
}) => {
  const InnerControl = getControl(control.type)
  const sizing = getControlSizing(control.type)
  const key = control.key || control.label

  const onHandleChange = value => {
    if (!value && !Object.keys(formValues).find(fkey => fkey === key)) return

    if (control.required && (!value || value === control.invalidValue)) {
      addControlToRequiredControlList(control)
    } else if (value && isRequired) {
      removeControlFromRequireControlList(key)
    }

    setFlag('lastControlUpdated', key)
    updateFormValue(key, value)
  }

  const currentValue = formValues[key] || control.currentValue

  return (
    <Grid item xs={12} sm={12} md={sizing}>
      <Box
        height="auto"
        className={classnames({
          [styles.controlWrapper]: !enableToHidden || !control.hidden,
          [styles.controlHidden]: control.hidden && enableToHidden
        })}
      >
        {InnerControl ? (
          <>
            <InnerControl
              control={control}
              isDisabled={isDisabled}
              setAllowContinue={setAllowContinue}
              onHandleChange={onHandleChange}
              currentValue={currentValue}
            />
            {isRequired && (
              <Typography variant="overline" display="block" color="error">
                {control.invalidValueMessage || 'This field is required.'}
              </Typography>
            )}
          </>
        ) : (
          <Typography variant="body1">Unsupported Control</Typography>
        )}
      </Box>
    </Grid>
  )
}

export default Control
