// @vendors
import React from 'react'
import Grid from '@material-ui/core/Grid'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AccountCircle from '@material-ui/icons/AccountCircle'

// @styles
import styles from './styles.module.sass'
import { Button } from '@material-ui/core'

const CarrierListItem = ({ carrier, onRemoveDriver }) => {
  return (
    <>
      <TableRow className={styles.tableRow}>
        <TableCell align="justify">
          <Grid container direction="row" justify="center">
            {carrier.avatar ? (
              <Avatar alt="carrier avatar" src={carrier.avatar} variant="circle" className={styles.avatar} />
            ) : (
              <AccountCircle className={styles.avatar} />
            )}
          </Grid>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">{carrier.carrierCode}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">{carrier.carrierName}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">{carrier.email}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">{carrier.phoneNumber}</Typography>
        </TableCell>
        <TableCell>
          <Button className={styles.removeButton} data-testid="removeCarrier#1" onClick={() => onRemoveDriver(carrier.idRelationship)}>
            <span>REMOVE</span>
          </Button>
        </TableCell>
      </TableRow>
      <TableRow className={styles.rowInMobile}>
        <Grid container direction="column" alignItems="center">
          <TableCell className={styles.noBorder} align="center">
            {carrier.avatar ? (
              <Avatar alt="carrier avatar" src={carrier.avatar} variant="circle" className={styles.avatarInMobile} />
            ) : (
              <AccountCircle className={styles.avatarInMobile} />
            )}
          </TableCell>
          <TableCell align="center" className={styles.mobileCell}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Typography variant="caption">Code:</Typography>
              <Typography variant="body2">{carrier.carrierCode}</Typography>
            </Grid>
          </TableCell>
          <TableCell align="center" className={styles.mobileCell}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Typography variant="caption">Name:</Typography>
              <Typography variant="body2">{carrier.carrierName}</Typography>
            </Grid>
          </TableCell>
          <TableCell align="center" className={styles.mobileCell}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Typography variant="caption">Email:</Typography>
              <Typography variant="body2">{carrier.email}</Typography>
            </Grid>
          </TableCell>
          <TableCell align="center" className={styles.mobileCell}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Typography variant="caption">Phone Number:</Typography>
              <Typography variant="body2">{carrier.phoneNumber}</Typography>
            </Grid>
          </TableCell>
          <TableCell className={styles.mobileCell}>
            <Button className={styles.removeButton} id="removeCarrier#2" onClick={() => onRemoveDriver(carrier.idRelationship)}>
              <span>REMOVE</span>
            </Button>
          </TableCell>
          <TableCell className={styles.mobileCell} />
        </Grid>
      </TableRow>
    </>
  )
}

export default CarrierListItem
