// @vendors
import React, { useState } from 'react'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import EditIcon from '@material-ui/icons/Edit'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'

// @utils
import httpConfig from '../../../../../http/config'
import useMount from '../../../../../hooks/use-mount'

// @styles
import styles from './styles.module.scss'

// @utils
import { getValues } from '../../../../../utils'

const { apiServer } = httpConfig

const getCountryCode = () =>
  axios.get(`${apiServer.url}/api/Settings/countryCodes`).then(res =>
    res.data.map(countryCode => ({
      label: countryCode.dial_code,
      countryName: countryCode.name,
      code: countryCode.code
    }))
  )

const CarrierProfile = ({ editMode, carrier, updateCarrier, onEditProfile }) => {
  const formFields = ['carrierCode', 'carrierName', 'countryCode', 'email', 'phoneNumber']

  const buildstate = (carrier = null) => {
    let state = {}

    formFields.forEach(key => {
      state[key] = { value: carrier[key], valid: true, pristine: false }
    })

    state = {
      ...state,
      form: { valid: true },
      avatar: carrier.avatar,
      avatarUrl: null,
      editMode: false
    }

    return state
  }

  const [helperText, setHelperText] = useState({
    email: null,
    carrierCode: null
  })

  const [countryCode, setCountryCode] = useState({
    data: [],
    label: 'Country Code',
    placeholder: 'Select Country Code'
  })

  const [state, setState] = useState(buildstate(carrier))
  const [showConfirmationDialog, showDialogValue] = useState(false)

  const handleShowDialog = () => showDialogValue(!showConfirmationDialog)

  const handleCancel = () => {
    setState({
      ...state,
      carrierCode: {
        value: carrier.carrierCode,
        valid: true,
        pristine: false
      }
    })
    showDialogValue(!showConfirmationDialog)
  }

  const handleChange = name => event => {
    event.stopPropagation()

    const { form, value: controlValue } = event.target

    let value = controlValue
    let valid = event.target.checkValidity()

    if (name === 'email') {
      const emailFormat = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
      valid = emailFormat.test(value)

      if (!valid) {
        const ht = {
          email: 'Email format is incorrect'
        }

        event.target.setCustomValidity(ht[name])
        setHelperText({ ...helperText, [name]: event.target.validationMessage })
      } else {
        event.target.setCustomValidity('')
        setHelperText({ ...helperText, [name]: null })
      }
    } else if (name === 'carrierCode') {
      valid = value.length <= 6 && value.length >= 2

      if (!valid) {
        const ht = {
          carrierCode: 'The code selected should have 2 to 6 characters'
        }

        event.target.setCustomValidity(ht[name])
        setHelperText({ ...helperText, [name]: event.target.validationMessage })
      } else {
        event.target.setCustomValidity('')
        setHelperText({ ...helperText, [name]: null })
      }
    } else if (name === 'phoneNumber') {
      value = value.replace(/[^\w\s]|[a-zA-Z]|\_/gi, '')
    }

    setState({
      ...state,
      [name]: { value, valid, pristine: false },
      form: { valid: form.checkValidity() }
    })
  }

  const onSave = event => {
    event.preventDefault()

    const carrierCodeValidation = carrier.carrierCode !== state.carrierCode.value
    const carrierNameValidation = carrier.carrierName !== state.carrierName.value
    const carrierEmailValidation = carrier.email !== state.email.value
    const carrierCountryCodeValidation = carrier.countryCode !== state.countryCode.value
    const carrierPhoneNumberValidation = carrier.phoneNumber !== state.phoneNumber.value

    if (carrierCodeValidation || carrierNameValidation || carrierEmailValidation || carrierCountryCodeValidation || carrierPhoneNumberValidation) {
      if (state.form.valid) {
        const { form, avatar, avatarUrl, editMode, ...formFields } = state

        updateCarrier(getValues({ ...formFields }))
      }

      if (showConfirmationDialog) {
        handleShowDialog()
      }
    }
  }

  const onCancel = event => {
    event.stopPropagation()
    onEditProfile(false)
    setState(buildstate(carrier))
  }

  useMount(() => {
    getCountryCode().then(countryCode => {
      setCountryCode({
        data: countryCode,
        label: 'Country Code',
        placeholder: 'Select Country Code'
      })
    })
  })

  const handleCountryCode = event => {
    setState({
      ...state,
      countryCode: {
        ...state.countryCode,
        value: event.target.value,
        valid: true,
        pristine: false
      }
    })
  }

  return (
    <CardContent className={styles.cardContent}>
      {!editMode && (
        <div className={styles.editButtonWrapper}>
          <Button variant="contained" color="primary" onClick={() => onEditProfile(!editMode)}>
            Edit
          </Button>
        </div>
      )}
      {editMode ? (
        <>
          <Typography variant="subtitle1" component="h2" className={styles.title}>
            Edit Carrier Info
          </Typography>
          <form noValidate autoComplete="off" onSubmit={onSave} className={styles.form}>
            <FormControl className={styles.carrierCodeItem}>
              <InputLabel htmlFor="password">Code</InputLabel>
              <Input
                required
                error={!state.carrierCode.valid && !state.carrierCode.pristine}
                value={state.carrierCode.value}
                onChange={handleChange('carrierCode')}
                inputProps={{
                  className: styles.textField,
                  readOnly: true
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="Edit Carrier Code" onClick={handleShowDialog}>
                      <EditIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <TextField
              required
              error={!state.carrierName.valid && !state.carrierName.pristine}
              label="Name"
              className={styles.textField}
              value={state.carrierName.value}
              onChange={handleChange('carrierName')}
              margin="normal"
            />
            <div className={styles.countryCodeGroup}>
              <FormControl className={styles.countryCodeItem}>
                <InputLabel name="license-state-label">Country Code*</InputLabel>
                <Select
                  name="country-code"
                  label={countryCode.label}
                  placeholder={countryCode.placeholder}
                  value={state.countryCode.value}
                  renderValue={value => value}
                  onChange={handleCountryCode}
                >
                  {countryCode.data.map((country, index) => (
                    <MenuItem className={styles.countryCodeOptions} key={`${country.label}-${index}`} value={country.code}>
                      <span className={styles.countryCodeValue}>{country.label}</span>
                      <span className={styles.countryCodeCountry}>{`(${country.code}) - ${country.countryName}`}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                required
                error={!state.phoneNumber.valid && !state.phoneNumber.pristine}
                name="phoneNumber"
                label="Phone Number"
                className={styles.phoneNumber}
                value={state.phoneNumber.value}
                onChange={handleChange('phoneNumber')}
                type="text"
              />
            </div>
            <TextField
              required
              error={!state.email.valid && !state.email.pristine}
              label="Email"
              className={styles.email}
              value={state.email.value}
              onChange={handleChange('email')}
              helperText={helperText.email}
              margin="normal"
            />
            <Dialog
              disableEscapeKeyDown
              open={showConfirmationDialog}
              onClose={handleShowDialog}
              maxWidth="sm"
              fullWidth
              className={styles.modalContainer}
            >
              <DialogTitle>Edit Carrier Code</DialogTitle>
              <DialogContent dividers>
                <form noValidate autoComplete="off" onSubmit={onSave} className={styles.form}>
                  <TextField
                    required
                    error={!state.carrierCode.valid && !state.carrierCode.pristine}
                    label="Code"
                    className={styles.textFieldModal}
                    value={state.carrierCode.value}
                    onChange={handleChange('carrierCode')}
                    helperText={helperText.carrierCode}
                    margin="normal"
                  />
                </form>
              </DialogContent>
              <DialogActions>
                <Button disabled={!state.carrierCode.valid} onClick={onSave}>
                  Save
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </DialogActions>
            </Dialog>
            <Grid container justify="flex-end" alignItems="center" className={styles.buttonsContainer}>
              <Button size="medium" onClick={onCancel}>
                CANCEL
              </Button>
              <Button type="submit" size="medium" disabled={!state.form.valid}>
                SAVE
              </Button>
            </Grid>
          </form>
        </>
      ) : (
        <Grid container justify="space-around" wrap="wrap" className={styles.profileContainer}>
          <div className={styles.wraper}>
            <Typography variant="subtitle1">Code</Typography>
            <Typography variant="body1">{state.carrierCode.value}</Typography>
          </div>
          <div className={styles.wraper}>
            <Typography variant="subtitle1">Name</Typography>
            <Typography variant="body1">{state.carrierName.value}</Typography>
          </div>
          <div className={styles.wraper}>
            <Typography variant="subtitle1">Country Code</Typography>
            <Typography variant="body1">{state.countryCode.value}</Typography>
          </div>
          <div className={styles.wraper}>
            <Typography variant="subtitle1">Phone Number</Typography>
            <Typography variant="body1">{state.phoneNumber.value}</Typography>
          </div>

          <div className={styles.emailWraper}>
            <Typography variant="subtitle1">Email</Typography>
            <Typography variant="body1">{state.email.value}</Typography>
          </div>
        </Grid>
      )}
    </CardContent>
  )
}

export default CarrierProfile
