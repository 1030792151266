// @vendors
import axios from 'axios'
import querystring from 'querystring'

// @constants
import * as constants from './constant'

// @storage
import { removeStateFromLocalStorage } from '../store/local-storage'

// @utils
import httpConfig from '../http/config'
import { standardRequestHandler, standardSuccessHandler, standardErrorHandler } from '../http/utils'
import { notifySuccess } from '../common-components/notifications/actions'

const { securityServer } = httpConfig

export const getJwt = (username, password) => (dispatch, getState) => {
  const { clientId, clientSecret } = getState().clientData
  const params = {
    grant_type: 'password',
    client_id: clientId,
    client_secret: clientSecret,
    scope: securityServer.scope,
    username,
    password
  }

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json'
    }
  }

  standardRequestHandler(dispatch, constants.GET_JWT_REQUEST)

  return axios
    .post(`${securityServer.url}/connect/token`, querystring.stringify(params), config)
    .then(res => standardSuccessHandler(dispatch, constants.GET_JWT_SUCCESS, res.data))
    .catch(err => standardErrorHandler(dispatch, err, constants.GET_JWT_ERROR, constants.COULD_NOT_LOGGED_IN))
}

export const sendResetPasswordLink = (email, url) => (dispatch, getState) => {
  const { clientId } = getState().clientData
  const values = {
    email,
    url,
    clientId: clientId
  }

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  }

  standardRequestHandler(dispatch, constants.SEND_RESET_PASSWORD_LINK_REQUEST)

  return axios
    .post(`${securityServer.url}/api/User/RequestPassword`, values, config)
    .then(res => {
      standardSuccessHandler(dispatch, constants.SEND_RESET_PASSWORD_LINK_SUCCESS, null, constants.RECOVERY_EMAIL_SENT_MESSAGE)
    })
    .catch(err => standardErrorHandler(dispatch, err, constants.SEND_RESET_PASSWORD_LINK_ERROR, constants.RECOVERY_EMAIL_SENT_MESSAGE_ERROR))
}

export const resetPassword = (token, password) => dispatch => {
  const params = {
    token: decodeURIComponent(token),
    password
  }

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json'
    }
  }

  standardRequestHandler(dispatch, constants.RESET_PASSWORD_REQUEST)

  return axios
    .post(`${securityServer.url}/api/User/ResetPassword`, querystring.stringify(params), config)
    .then(res => standardSuccessHandler(dispatch, constants.RESET_PASSWORD_SUCCESS, res.data, constants.RESET_PASSWORD_SUCCESS_MESSAGE))
    .catch(err => standardErrorHandler(dispatch, err, constants.RESET_PASSWORD_ERROR, constants.RESET_PASSWORD_ERROR_MESSAGE))
}

export const notifyLoginToAcceptInvite = () => dispatch => {
  dispatch(notifySuccess(constants.ACCEPT_INVITATION_MESSAGE))
}

export const logout = () => {
  removeStateFromLocalStorage()
  return { type: constants.LOG_OUT }
}
