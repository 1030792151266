export const getMuiThemeData = themeData => {
  if (!themeData) {
    return {}
  }

  const { theme } = themeData

  return {
    palette: {
      primary: {
        main: theme.primaryColor
      },
      secondary: {
        main: theme.secondaryColor
      },
      error: {
        main: theme.warningColor
      }
    }
  }
}
