// @vendors
import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { Redirect } from 'react-router-dom'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'

// @components
import CardForm from '../../../common-components/card-form'
import PasswordInputIcon from './components/password-input-icon'

// @constants
import { RESET_PASSWORD_INITIAL_STATE } from './constant'

// @styles
import './styles.scss'

const PasswordReset = ({ driver, getJwt, location, resetPassword, security }) => {
  const [state, setState] = useState(RESET_PASSWORD_INITIAL_STATE)

  const token = location.search ? location.search.split('=')[1] : null

  const handleChange = name => event => {
    const value = event.target.value
    const valid = event.target.checkValidity()

    setState({
      ...state,
      [name]: { value, valid, pristine: false }
    })

    if (name === 'password') {
      var regexUppercaseLetter = /[A-Z]/
      var regexLowercaseLetter = /[a-z]/
      var regexNumber = /\d/
      var regexSpecialCharacter = /[!@#$%^&"'*\\()_+=\[{\]};:<>|./?,-]/
      var regexLength = /(?=.{6,32})/

      let newState = { ...state }

      newState = {
        ...newState,
        regexUppercaseLetterValid: regexUppercaseLetter.test(value),
        regexLowercaseLetterValid: regexLowercaseLetter.test(value),
        regexNumberValid: regexNumber.test(value),
        regexSpecialCharacterValid: regexSpecialCharacter.test(value),
        regexLengthValid: regexLength.test(value),
        password: { value: value }
      }

      if (
        newState.regexUppercaseLetterValid &&
        newState.regexLowercaseLetterValid &&
        newState.regexNumberValid &&
        newState.regexSpecialCharacterValid &&
        newState.regexLengthValid
      ) {
        newState = {
          ...newState,
          [name]: { ...state[name], value, valid, pristine: false },
          password: { value: value, valid: true, pristine: false }
        }
      }
      return setState(newState)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    resetPassword(token, state.password.value)
  }

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleClickShowConfirmPassword = () => {
    setState({ ...state, showConfirmPassword: !state.showConfirmPassword })
  }

  const handleMouseDownConfirmPassword = event => {
    event.preventDefault()
  }

  const validateConfirmPassword = () => {
    const { confirmPassword, password } = state
    const passwordsError = <span className="password-reset__message--error">Passwords do not match.</span>

    return confirmPassword.value && confirmPassword.value !== password.value ? passwordsError : null
  }

  useEffect(() => {
    if (driver) {
      getJwt(driver.email, state.password.value)
    }
  })

  if (!token || (driver && security)) {
    return <Redirect to="/" />
  }

  const passwordValid = state.password.valid && state.password.value
  const confirmPasswordValid = state.confirmPassword.valid && state.confirmPassword.value
  const resetButtonClasses = classnames('password-reset__button', {
    'password-reset__button--disabled': !passwordValid || !confirmPasswordValid
  })

  return (
    <CardForm header="Set your new password">
      <section className="password-reset">
        <FormControl className="password-reset__password">
          <InputLabel error={!state.password.valid && !state.password.pristine} htmlFor="password">
            Password *
          </InputLabel>
          <Input
            autoComplete="off"
            error={!state.password.valid && !state.password.pristine}
            endAdornment={
              <PasswordInputIcon
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownShowPassword={handleMouseDownPassword}
                showPassword={state.showPassword}
              />
            }
            id="password"
            inputProps={{
              className: 'password-reset__password-field'
            }}
            onChange={handleChange('password')}
            required
            type={state.showPassword ? 'text' : 'password'}
            value={state.password.value}
          />
        </FormControl>
        <Typography
          variant="caption"
          color="currentcolor"
          style={{
            display:
              state.regexLowercaseLetterValid &&
              state.regexUppercaseLetterValid &&
              state.regexNumberValid &&
              state.regexSpecialCharacterValid &&
              state.regexLengthValid
                ? 'block'
                : 'none'
          }}
        >
          Passwords must be at least 6-32 characters long containing at least one uppercase letter, one lowercase letter, one number and one special
          character
        </Typography>
        <Typography variant="caption" color="error" style={{ display: state.regexLowercaseLetterValid ? 'none' : 'block' }}>
          Password must contain at least one lower case letter
        </Typography>
        <Typography variant="caption" color="error" style={{ display: state.regexUppercaseLetterValid ? 'none' : 'block' }}>
          Password must contain at least one upper case letter
        </Typography>
        <Typography variant="caption" color="error" style={{ display: state.regexNumberValid ? 'none' : 'block' }}>
          Password must contain at least one number
        </Typography>
        <Typography variant="caption" color="error" style={{ display: state.regexSpecialCharacterValid ? 'none' : 'block' }}>
          Password must contain at least one special character
        </Typography>
        <Typography variant="caption" color="error" style={{ display: state.regexLengthValid ? 'none' : 'block' }}>
          Password must be at least 6-32 characters long
        </Typography>

        <FormControl className="password-reset__password">
          <InputLabel
            error={
              (state.confirmPassword.value && state.confirmPassword.value !== state.password.value) ||
              (!state.confirmPassword.valid && !state.confirmPassword.pristine)
            }
            htmlFor="confirmPassword"
          >
            Confirm Password *
          </InputLabel>
          <Input
            autoComplete="off"
            error={
              (state.confirmPassword.value && state.confirmPassword.value !== state.password.value) ||
              (!state.confirmPassword.valid && !state.confirmPassword.pristine)
            }
            endAdornment={
              <PasswordInputIcon
                handleClickShowPassword={handleClickShowConfirmPassword}
                handleMouseDownShowPassword={handleMouseDownConfirmPassword}
                showPassword={state.showConfirmPassword}
              />
            }
            id="confirmPassword"
            inputProps={{
              className: 'password-reset__password-field'
            }}
            onChange={handleChange('confirmPassword')}
            required
            type={state.showConfirmPassword ? 'text' : 'password'}
            value={state.confirmPassword.value}
            disabled={!state.password.valid}
          />
          <FormHelperText children={validateConfirmPassword()} />
        </FormControl>

        <Button
          className={resetButtonClasses}
          disabled={!state.confirmPassword.valid && !state.password.valid}
          onClick={handleSubmit}
          size="large"
          variant="outlined"
        >
          reset password
        </Button>
      </section>
    </CardForm>
  )
}

export default PasswordReset
