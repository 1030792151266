export const GET_NEW_CARRIER_TICKET_REQUEST = 'GET_NEW_CARRIER_TICKET_REQUEST'
export const GET_NEW_CARRIER_TICKET_SUCCESS = 'GET_NEW_CARRIER_TICKET_SUCCESS'
export const GET_NEW_CARRIER_TICKET_ERROR = 'GET_NEW_CARRIER_TICKET_ERROR'
export const GET_NEW_CARRIER_TICKET_ERROR_MESSAGE = 'Error fetching ticket steps.'

export const GET_CARRIER_TICKET_REQUEST = 'GET_CARRIER_TICKET_REQUEST'
export const GET_CARRIER_TICKET_SUCCESS = 'GET_CARRIER_TICKET_SUCCESS'
export const GET_CARRIER_TICKET_ERROR = 'GET_CARRIER_TICKET_ERROR'
export const GET_CARRIER_TICKET_ERROR_MESSAGE = 'Error fetching ticket steps.'

export const GET_CARRIER_TICKET_DETAILS_REQUEST = 'GET_CARRIER_TICKET_DETAILS_REQUEST'
export const GET_CARRIER_TICKET_DETAILS_SUCCESS = 'GET_CARRIER_TICKET_DETAILS_SUCCESS'
export const GET_CARRIER_TICKET_DETAILS_ERROR = 'GET_CARRIER_TICKET_DETAILS_ERROR'
export const GET_CARRIER_TICKET_DETAILS_ERROR_MESSAGE = 'Error fetching ticket steps.'

export const GET_QR_CODE_REQUEST = 'GET_QR_CODE_REQUEST'
export const GET_QR_CODE_SUCCESS = 'GET_QR_CODE_SUCCESS'
export const GET_QR_CODE_ERROR = 'GET_QR_CODE_ERROR'
export const GET_QR_CODE_ERROR_MESSAGE = 'Could not get the QR code.'

export const PATH_NAME = '/carrier'
export const IS_DISABLED = false
export const ACTIVE_STEP = 1
export const IS_FIRST_STEP = true
