// @action types
export const RESET_CARRIER = 'RESET_CARRIER'

export const GET_CARRIER_REQUEST = 'GET_CARRIER_REQUEST'
export const GET_CARRIER_SUCCESS = 'GET_CARRIER_SUCCESS'
export const GET_CARRIER_ERROR = 'GET_CARRIER_ERROR'

export const CREATE_CARRIER_REQUEST = 'CREATE_CARRIER_REQUEST'
export const CREATE_CARRIER_SUCCESS = 'CREATE_CARRIER_SUCCESS'
export const CREATE_CARRIER_ERROR = 'CREATE_CARRIER_ERROR'

export const UPDATE_CARRIER_REQUEST = 'UPDATE_CARRIER_REQUEST'
export const UPDATE_CARRIER_SUCCESS = 'UPDATE_CARRIER_SUCCESS'
export const UPDATE_CARRIER_ERROR = 'UPDATE_CARRIER_ERROR'

export const UPDATE_CARRIER_AVATAR_REQUEST = 'UPDATE_CARRIER_AVATAR_REQUEST'
export const UPDATE_CARRIER_AVATAR_SUCCESS = 'UPDATE_CARRIER_AVATAR_SUCCESS'
export const UPDATE_CARRIER_AVATAR_ERROR = 'UPDATE_CARRIER_AVATAR_ERROR'

export const GET_CARRIER_DRIVERS_REQUEST = 'GET_CARRIER_DRIVERS_REQUEST'
export const GET_CARRIER_DRIVERS_SUCCESS = 'GET_CARRIER_DRIVERS_SUCCESS'
export const GET_CARRIER_DRIVERS_ERROR = 'GET_CARRIER_DRIVERS_ERROR'

// @action messages
export const GET_CARRIER_ERROR_MESSAGE = 'Error getting the carrier.'

export const CREATE_CARRIER_SUCCESS_MESSAGE = 'Carrier created successfully.'
export const CREATE_CARRIER_ERROR_MESSAGE = 'Error creating the carrier.'

export const UPDATE_CARRIER_SUCCESS_MESSAGE = 'Carrier info updated successfully.'
export const UPDATE_CARRIER_ERROR_MESSAGE = 'Error updating the carrier info.'

export const UPDATE_CARRIER_AVATAR_SUCCESS_MESSAGE = 'Carrier avatar updated successfully.'
export const UPDATE_CARRIER_AVATAR_ERROR_MESSAGE = 'Error updating the carrier avatar.'
