// @vendors
import axios from 'axios'

// @constants
import * as constants from './constant'

// @utils
import { createApiRequestHeaders, standardErrorHandler, standardRequestHandler, standardSuccessHandler } from '../../../../http/utils'
import httpConfig from '../../../../http/config'

const { apiServer } = httpConfig

export const assignDriver = (driver, selectedTickets) => (dispatch, getState) => {
  const {
    security: { access_token, token_type },
    form: { ticketId }
  } = getState()

  const tickets = selectedTickets || [ticketId]

  const config = {
    headers: createApiRequestHeaders(access_token, token_type)
  }

  standardRequestHandler(dispatch, constants.ASSIGN_TICKET_TO_DRIVER_REQUEST)

  return axios
    .post(`${apiServer.url}/api/Drivers/${driver.id}/assignTickets`, tickets, config)
    .then(() => {
      standardSuccessHandler(
        dispatch,
        constants.ASSIGN_TICKET_TO_DRIVER_SUCCESS,
        {
          driver
        },
        `You have assigned ${selectedTickets && selectedTickets.length > 1 ? 'one or more tickets' : 'the ticket'} successfully.`
      )
    })
    .catch(err => {
      standardErrorHandler(dispatch, err, constants.ASSIGN_TICKET_TO_DRIVER_ERROR, constants.ASSIGN_TICKET_TO_DRIVER_ERROR_MESSAGE)
    })
}
