// @vendors
import React from 'react'

// @components
import StringTicketCell from './string-ticket-cell'
import AttatchmentTicketCell from './attachment-ticket-cell'

const TicketRowCell = props => {
  const { ticketCell } = props

  switch (ticketCell.type) {
    case 'attachment':
      return <AttatchmentTicketCell {...props} />
    default:
      return <StringTicketCell {...props} />
  }
}

export default TicketRowCell
