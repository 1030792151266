import * as constants from './constant'

const initialState = {
  data: null,
  fetching: false,
  fetched: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.REQUEST_THEME.REQUESTED: {
      return {
        ...state,
        fetching: true
      }
    }
    case constants.REQUEST_THEME.SUCCESS: {
      return {
        ...state,
        fetched: true,
        fetching: false,
        data: action.payload
      }
    }
    case constants.REQUEST_THEME.ERROR: {
      return {
        ...state,
        fetched: true,
        fetching: false
      }
    }
    case constants.RESET_THEME_FETCH_STATUS: {
      return {
        ...state,
        fetched: false,
        fetching: false
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
