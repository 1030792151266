import { SET_FLAG } from '../constants/flags'
import { LOG_OUT } from '../../security/constant'

const initialState = {
  outboundShipmentNumberValidationCompleted: false,
  validOutboundShipmentNumber: false,
  validOutboundLiveLoad: false,
  validOutboundReefer: false,
  inboundShipmentNumberValidationCompleted: false,
  validInboundShipmentNumber: false,
  lastControlUpdated: null,
  justCreatedDriver: false
}

const flagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLAG: {
      const { flag, value } = action.payload

      return { ...state, [flag]: value }
    }
    case LOG_OUT: {
      return initialState
    }
    case 'GET_TICKET_LIST_SUCCESS': {
      return initialState
    }
    default:
      return state
  }
}

export default flagsReducer
