// @vendors
import { connect } from 'react-redux'

// @components
import CarrierSignUp from './carrier-signup'

// @actions
import { getJwt } from '../../../security/actions'
import { resetCarrier, createCarrier } from '../carrier-profile/actions'

// @utils
import { isCarrierCreated, getCarrier } from '../carrier-profile/selector'

const mapStateToProps = (state, ownProps) => {
  const { search } = ownProps.location
  const params = search.split('&')
  const inviteToken = search ? params[0].split('=')[1] : ''
  const email = search ? params[1].split('=')[1] : ''
  const clientId = search ? params[2].split('=')[1] : ''

  return {
    carrierCreated: isCarrierCreated(state),
    carrier: getCarrier(state),
    inviteToken,
    email,
    clientId
  }
}

const mapDispatchToProps = {
  resetCarrier,
  createCarrier,
  getJwt
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierSignUp)
