// @constants
import * as carrierConstants from './constant'
import { LOG_OUT } from '../../../security/constant'

const initialState = null

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case carrierConstants.CREATE_CARRIER_SUCCESS: {
      return { ...action.payload }
    }
    case carrierConstants.UPDATE_CARRIER_SUCCESS: {
      return { ...state, ...action.payload }
    }
    case carrierConstants.UPDATE_CARRIER_AVATAR_SUCCESS: {
      return { ...state, avatar: action.payload.avatar }
    }
    case carrierConstants.GET_CARRIER_SUCCESS: {
      return { ...action.payload }
    }
    case carrierConstants.RESET_CARRIER: {
      return initialState
    }
    case carrierConstants.GET_CARRIER_DRIVERS_SUCCESS: {
      return { ...state, ...action.payload }
    }
    case LOG_OUT:
      return initialState
    default: {
      return state
    }
  }
}

export default reducer
