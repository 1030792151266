// @actions constants
export const SET_FILTERS = 'SET_FILTERS'

export const DELETE_TICKET_ERROR = 'DELETE_TICKET_ERROR'
export const DELETE_TICKET_REQUEST = 'DELETE_TICKET_REQUEST'
export const DELETE_TICKET_SUCCESS = 'DELETE_TICKET_SUCCESS'

export const GET_TICKET_LIST_ERROR = 'GET_TICKET_LIST_ERROR'
export const GET_TICKET_LIST_REQUEST = 'GET_TICKET_LIST_REQUEST'
export const GET_TICKET_LIST_SUCCESS = 'GET_TICKET_LIST_SUCCESS'

// @messages constants
export const ERROR_DELETING_TICKET = 'Error Deleting Ticket'
export const ERROR_FETCHING_TICKETS = 'Error Fetching Tickets'
export const TICKET_DELETED_SUCCESSFULLY = 'Ticket Deleted Successfully'
