// @vendors
import thunk from 'redux-thunk'
import throttle from 'lodash/throttle'
import { createStore, compose, applyMiddleware } from 'redux'
import immutableInvariant from 'redux-immutable-state-invariant'

// @index
import reducer from './reducer'

// @utilities
import { loadStateFromLocalStorage, saveStateToLocalStorage } from './local-storage'

const middleware = process.env.NODE_ENV === 'production' ? [thunk] : [immutableInvariant(), thunk]

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose

const enhancer = composeEnhancers(applyMiddleware(...middleware))

const store = createStore(reducer, loadStateFromLocalStorage(), enhancer)

store.subscribe(
  throttle(() => {
    saveStateToLocalStorage(store.getState())
  }, 5000)
)

export default store
