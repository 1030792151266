// @vendors
import React, { useState, useEffect } from 'react'
import Downshift from 'downshift'
import Paper from '@material-ui/core/Paper'

// @components
import SuggestionInput from './components/suggestions-input'
import SuggestionsListItem from './components/suggestions-list-item'

// @utils
import { getSuggestions } from './input-multi-select/utils'
import usePrevious from '../../../../hooks/use-previous'

// @styles
import styles from './styles.module.sass'

const InputSelect = ({ isDisabled, control, onHandleChange, currentValue, classes }) => {
  const [state, setState] = useState(() => {
    const inputSelectValue = { selectedItem: control.currentValue || currentValue || '', inputValue: control.currentValue || currentValue || '' }
    onHandleChange(inputSelectValue.inputValue)
    return inputSelectValue
  })

  const prevState = usePrevious(state)
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  const handleChange = event => {
    setState({ selectedItem: event, inputValue: event })
  }

  const handleInputChange = event => {
    setState({
      selectedItem: event.target.value,
      inputValue: event.target.value
    })
  }

  const handleStateChange = (...args) => {
    if (!args[0].selectedItem && prevState.inputValue !== state.inputValue) {
      onHandleChange(args[0].inputValue)
    } else if (args[0].selectedItem) {
      onHandleChange(args[0].selectedItem)
    }
  }

  useEffect(() => {
    if (currentValue !== control.currentValue) {
      setState({ selectedItem: currentValue, inputValue: currentValue })
      onHandleChange(currentValue)
    }
  }, [currentValue])

  return (
    <div className={styles.root}>
      <Downshift
        id="downshift-simple"
        selectedItem={state.selectedItem}
        onChange={handleChange}
        onStateChange={handleStateChange}
        inputValue={state.inputValue}
      >
        {({ getInputProps, getItemProps, getLabelProps, getMenuProps, highlightedIndex, inputValue, isOpen, selectedItem: selectedItem2 }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            disabled: isDisabled,
            onKeyDown: handleKeyDown,
            onChange: handleInputChange,
            placeholder: control.placeholder
          })

          return (
            <div className={`${classes && classes.inputSelect} ${styles.container}`}>
              {SuggestionInput({
                fullWidth: true,
                styles,
                label: control.label,
                InputLabelProps: getLabelProps({ shrink: true }),
                inputAttributes: { onBlur, onFocus },
                inputProps
              })}

              <div {...getMenuProps()}>
                {isOpen && (
                  <Paper className={styles.paper} square>
                    {getSuggestions(inputValue, {
                      suggestions: control.data
                    }).map((suggestion, index) =>
                      SuggestionsListItem({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion.label }),
                        highlightedIndex,
                        selectedItem2
                      })
                    )}
                  </Paper>
                )}
              </div>
            </div>
          )
        }}
      </Downshift>
    </div>
  )
}

export default InputSelect
