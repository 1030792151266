// @vendors
import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

// @components
import Controls from '../../../pages/driver/components/controls'

const Section = ({ section, isDisabled, requiredControls, setAllowContinue, enableToHidden }) => {
  return (
    <Box p={2} pb={0} style={{ display: section.hidden ? 'none' : 'inherit' }}>
      <Typography variant="h6" component="h2" color="primary">
        {section.label}
      </Typography>
      <Controls
        isDisabled={isDisabled}
        controls={section.controls || []}
        requiredControls={requiredControls}
        enableToHidden={enableToHidden}
        setAllowContinue={setAllowContinue}
      />
    </Box>
  )
}

export default Section
