// @vendors
import { connect } from 'react-redux'

// @components
import QR from './qr'

// @actions
import { requestQrCode } from './actions'

// @utils
import { getQrCode } from './selectors'
import { getFormSteps } from '../steps/selectors'
import { getForm } from '../../driver-tickets/ticket/selectors'
import { getSecurity } from '../../../../security'

const mapStateToProps = state => (state, ownProps) => {
  const ticket = getForm(state)
  const { Profile: profile } = getSecurity(state)

  return {
    profile,
    ticketId: ticket.ticketId,
    qrCode: getQrCode(state),
    steps: getFormSteps(state),
    ticketStatus: ticket.ticketStatus
  }
}

const mapDispatchToProps = { requestQrCode }

export default connect(mapStateToProps, mapDispatchToProps)(QR)
