// @vendors
import { connect } from 'react-redux'

// @components
import Ticket from './ticket'

// @utils
import { getSecurity } from '../../../../security'
import { getForm } from './selectors'

// @actions
import { requestNewForm, resetFormFetchStatus, requestForm } from './actions'
import { resetFormValues } from '../ticket-values/actions'

const mapStateToProps = state => ({
  security: getSecurity(state),
  form: getForm(state)
})

const mapDispatchToProps = {
  requestNewForm,
  requestForm,
  resetFormFetchStatus,
  resetFormValues
}

export default connect(mapStateToProps, mapDispatchToProps)(Ticket)
