// @vendors
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Radio from '@material-ui/core/Radio'

// @styles
import styles from './styles.module.sass'

const ListItem = ({ driver, onRemoveDriver, selectMode, onSelectDriver, selectedDriver }) => {
  const renderCells = (driver, isMobile) => {
    return (
      <>
        <TableCell align="center" className={isMobile && styles.mobileCell}>
          <Grid container direction="row" justify={isMobile ? 'flex-start' : 'center'} alignItems="center">
            {isMobile && <Typography variant="caption">First Name:</Typography>}
            <Typography variant="body2">{driver.firstName}</Typography>
          </Grid>
        </TableCell>
        <TableCell align="center" className={isMobile && styles.mobileCell}>
          <Grid container direction="row" justify={isMobile ? 'flex-start' : 'center'} alignItems="center">
            {isMobile && <Typography variant="caption">Last Name:</Typography>}
            <Typography variant="body2">{driver.lastName}</Typography>
          </Grid>
        </TableCell>
        {!selectMode && (
          <TableCell align="center" className={isMobile && styles.mobileCell}>
            <Grid container direction="row" justify={isMobile ? 'flex-start' : 'center'} alignItems="center">
              {isMobile && <Typography variant="caption">Email:</Typography>}
              <Typography variant="body2">{driver.email}</Typography>
            </Grid>
          </TableCell>
        )}
        {!selectMode && (
          <TableCell align="center" className={isMobile && styles.mobileCell}>
            <Grid container direction="row" justify={isMobile ? 'flex-start' : 'center'} alignItems="center">
              {isMobile && <Typography variant="caption">Phone Number:</Typography>}
              <Typography variant="body2">{driver.phoneNumber}</Typography>
            </Grid>
          </TableCell>
        )}

        <TableCell align="center" className={isMobile && styles.mobileCell}>
          <Grid container direction="row" justify={isMobile ? 'flex-start' : 'center'} alignItems="center">
            {isMobile && <Typography variant="caption">Current Tickets:</Typography>}
            <Typography variant="body2" title="Current Tickets">
              {`${selectMode ? 'Current Tickets: ' : ''}${driver.currentTickets}`}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell align="center" className={isMobile && styles.mobileCell}>
          <Grid container direction="row" justify={isMobile ? 'flex-start' : 'center'} alignItems="center">
            {isMobile && <Typography variant="caption">Completed Trips:</Typography>}
            <Typography variant="body2" title="Completed Trips">
              {`${selectMode ? 'Completed Trips: ' : ''}${driver.completedTrips}`}
            </Typography>
          </Grid>
        </TableCell>
        {!selectMode && (
          <TableCell className={isMobile && styles.mobileCell}>
            <Button className={styles.removeButton} onClick={() => onRemoveDriver(driver.idRelationship)}>
              <span>REMOVE</span>
            </Button>
          </TableCell>
        )}
        <TableCell className={isMobile && styles.mobileCell} />
      </>
    )
  }

  return (
    <>
      <TableRow className={styles.tableRow}>
        {selectMode && (
          <TableCell align="center">
            <Radio
              checked={(selectedDriver && selectedDriver.id) === driver.id}
              onChange={() => onSelectDriver(driver)}
              value={driver}
              name={driver.id}
              inputProps={{ 'aria-label': 'A' }}
            />
          </TableCell>
        )}
        {!selectMode && (
          <TableCell align="justify">
            <Grid container direction="row" justify="center">
              {driver.avatar ? (
                <Avatar alt="driver avatar" src={driver.avatar} variant="circle" className={styles.avatar} />
              ) : (
                <AccountCircle className={styles.avatar} />
              )}
            </Grid>
          </TableCell>
        )}

        {renderCells(driver)}
      </TableRow>
      <TableRow className={styles.rowInMobile}>
        {selectMode && (
          <TableCell align="center">
            <Radio
              checked={(selectedDriver && selectedDriver.id) === driver.id}
              onChange={() => onSelectDriver(driver)}
              value={driver}
              name={driver.id}
              inputProps={{ 'aria-label': 'A' }}
            />
          </TableCell>
        )}
        <TableCell className={styles.cellInMobile}>
          <Grid container direction="column" alignItems="center">
            {!selectMode && (
              <div className={styles.noBorder}>
                {driver.avatar ? (
                  <Avatar alt="driver avatar" src={driver.avatar} variant="circle" className={styles.avatarInMobile} />
                ) : (
                  <AccountCircle className={styles.avatarInMobile} />
                )}
              </div>
            )}
            {renderCells(driver, true)}
          </Grid>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ListItem
