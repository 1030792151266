// @actions
import { logout } from '../security/actions'
import { addToGlobalSpinner, subtractFromGlobalSpinner } from '../common-components/busy-spinner/actions'
import { notifyError, notifySuccess } from '../common-components/notifications/actions'

export const createApiRequestHeaders = (token, tokenType) => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `${tokenType} ${token}`
})

export const standardRequestHandler = (dispatch, requestType, payload) => {
  dispatch({ type: requestType, payload })
  dispatch(addToGlobalSpinner())
}

export const standardSuccessHandler = (dispatch, successType, payload, successMessage) => {
  dispatch({ type: successType, payload })
  dispatch(subtractFromGlobalSpinner())

  if (successMessage) {
    dispatch(notifySuccess(successMessage))
  }
}

export const standardErrorHandler = (dispatch, err, errorType, errorMessage, payload) => {
  // if the status code is 401 or 403 (unauthorized http response codes), we will want to log the user out right away.
  if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch(logout())
      dispatch(subtractFromGlobalSpinner())
    return
  }

  if (!errorMessage && err && err.response) {
    const { data, status } = err.response

    dispatch({ type: errorType, payload: { ...payload, data, status } })
    dispatch(subtractFromGlobalSpinner())

    const finalMessage = (data && (data.error_description || data.Message)) || err.message
    dispatch(notifyError(finalMessage))
  } else {
    dispatch({ type: errorType, payload })
    dispatch(subtractFromGlobalSpinner())
    dispatch(notifyError(errorMessage))
  }
}

export const standardErrorMessageHandler = (dispatch, errorMessage) => {
  dispatch(subtractFromGlobalSpinner())
  dispatch(notifyError(errorMessage))
}
