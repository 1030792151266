import axios from 'axios'

// @utils
import httpConfig from '../../../http/config'
import { standardRequestHandler, standardSuccessHandler, standardErrorHandler } from '../../../http/utils'
import { createApiRequestHeaders } from '../../../http/utils'

// @constants
import * as constants from '../constant'

const { apiServer } = httpConfig

export const acceptInvitation = (inviteId, acceptToken, email) => (dispatch, getState) => {
  const { access_token: token, token_type: tokenType, Email: loggedEmail } = getState().security

  if (email !== loggedEmail) {
    return standardErrorHandler(dispatch, null, constants.ACCEPT_INVITATION_ERROR, constants.EMAIL_VALIDATION_ERROR)
  }

  const params = {
    token: acceptToken
  }

  const config = {
    headers: {
      ...createApiRequestHeaders(token, tokenType)
    },
    params
  }

  standardRequestHandler(dispatch, constants.ACCEPT_INVITATION_REQUEST)

  axios
    .get(`${apiServer.url}/api/Invite/${inviteId}/accept?`, config)
    .then(res => {
      standardSuccessHandler(dispatch, constants.ACCEPT_INVITATION_SUCCESS, null, constants.INVITATION_ACCEPTED)

      return res.data
    })
    .catch(err => standardErrorHandler(dispatch, null, constants.ACCEPT_INVITATION_ERROR, constants.COLD_NOT_ACCEPT_INVITATION))
}
