// @vendors
import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DialogActions } from '@material-ui/core'

// @components
import DriverTable from '../../../driver-list'
import AssignmentConfirmDialog from './confirm-dialog'

// @styles
import styles from './styles.module.sass'

const AssignDriver = ({ isOpen, onClose, selectedTickets, assignTicketsRequest, drivers, refreshTickets, onSelectDriver, selectedDriver }) => {
  const [open, setOpen] = useState(isOpen)
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const handleOnClose = () => {
    onSelectDriver(null)
    onClose()
  }

  const assignTickets = e => {
    setConfirmDialogIsOpen(false)
    assignTicketsRequest(selectedDriver, selectedTickets && selectedTickets.ticketIds).then(refreshTickets)
  }

  const onCloseConfirmDialog = () => {
    setConfirmDialogIsOpen(false)
  }

  return (
    <>
      <Dialog disableEscapeKeyDown open={open} onClose={handleOnClose} className={styles.modalContainer} maxWidth="md" fullWidth>
        <DialogTitle>Select a Driver</DialogTitle>
        <DialogContent dividers>
          <DriverTable selectMode showToolbar={false} drivers={drivers} selectedDriver={selectedDriver} onSelectDriver={onSelectDriver} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button disabled={!selectedDriver} onClick={() => setConfirmDialogIsOpen(true)}>
            Assign
          </Button>
        </DialogActions>
      </Dialog>

      {confirmDialogIsOpen && (
        <AssignmentConfirmDialog
          driver={selectedDriver}
          ticketNumbers={selectedTickets && selectedTickets.ticketNumbers}
          isOpen={confirmDialogIsOpen}
          onClose={onCloseConfirmDialog}
          AssignTcketsHandler={assignTickets}
        />
      )}
    </>
  )
}

export default AssignDriver
