// @constants
import * as constants from './constant'

export const initialState = {
  list: [],
  fetched: false,
  fetching: false,
  theming: {
    data: '443'
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.REQUEST_FACILITIES.SUCCESS: {
      return {
        ...state,
        list: action.payload,
        selected: null
      }
    }
    case constants.CREATE_FACILITY.SUCCESS: {
      const list = state.list ? [...state.list, action.payload] : [action.payload]

      return {
        ...state,
        list,
        selected: null
      }
    }
    case constants.GET_FACILITY.SUCCESS: {
      return {
        ...state,
        selected: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
