// @vendors
import { connect } from 'react-redux'

// @components
import Pagination from './pagination'

// @utils
import { getTheming } from '../../utils/theming/selectors'

const mapStateToProps = state => ({
  theming: getTheming(state)
})

export default connect(mapStateToProps)(Pagination)
