// @constants
import * as constants from './constant'
import { LOG_OUT } from '../../security/constant'

const ticketsListReducer = (state = null, action) => {
  switch (action.type) {
    case constants.GET_TICKET_LIST_SUCCESS: {
      const filters = state && state.filters ? state.filters : { type: [], status: [] }

      const { profile, ...payload } = action.payload

      return { ...payload, filters }
    }
    case constants.DELETE_TICKET_SUCCESS: {
      const filters = state && state.filters ? state.filters : { type: [], status: [] }

      const { ticketNumber } = action.payload

      const cellIndex = state.body[0].cells.findIndex(cell => cell.key === 'Number')

      const body = state.body.filter(obj => obj.cells[cellIndex].label !== ticketNumber)

      const newState = { ...state, body, filters }

      return newState
    }
    case constants.SET_FILTERS: {
      const filters = state && state.filters ? state.filters : { type: [], status: [] }

      const { type = filters.type, status = filters.status } = action.payload

      const newFilters = {
        type,
        status
      }

      return { ...state, filters: newFilters }
    }
    case LOG_OUT:
      return null
    default:
      return state
  }
}

export default ticketsListReducer
