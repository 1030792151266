// @vendors
import { connect } from 'react-redux'

// @actions
import { requestClientData } from '../http/actions'
import { fetchTheme, resetThemeFetchStatus } from '../utils/theming/actions'

// @components
import Routes from './routes'

const mapStateToProps = state => ({
  theming: state.theming,
  security: state.security,
  clientData: state.clientData
})

const mapDispatchToProps = {
  fetchTheme,
  resetThemeFetchStatus,
  requestClientData
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
