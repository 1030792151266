// @vendors
import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'

// @components
import EmailInvite from '../../../../common-components/email-invite'

// @styles
import styles from './styles.module.sass'

const CarriersToolbar = ({ handleSuggestions }) => {
  const handleChange = event => {
    event.stopPropagation()
    handleSuggestions(event.target.value)
  }

  const [openCarrierInviteModal, setCarrierInviteModal] = useState(false)

  const onCloseCarrierInviteModal = () => {
    setCarrierInviteModal(false)
  }

  return (
    <>
      <Toolbar className={styles.toolbar}>
        <div className={styles.toolbarWrapper}>
          <div className={styles.search}>
            <div className={styles.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              classes={{
                root: styles.inputRoot,
                input: styles.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleChange}
            />
          </div>
          <Button variant="outlined" onClick={() => setCarrierInviteModal(true)} className={styles.createTicket}>
            +<LocalShippingIcon className={styles.truckIcon} />
            <span className={styles.inviteDriverButtonLabel}> INVITE CARRIER</span>
          </Button>
        </div>
      </Toolbar>
      <EmailInvite
        isOpen={openCarrierInviteModal}
        location={window.location.origin}
        onClose={onCloseCarrierInviteModal}
        url={`${window.location.origin}`}
        title="invite carrier"
        inviteType="carrier"
      />
    </>
  )
}

export default CarriersToolbar
