// @vendors
import React, { useState, useEffect, useCallback } from 'react'

// @components
import NavigationBar from '../../../common-components/navigation-bar'
import DriverTable from '../../../common-components/driver-list'

const DriverList = ({ requestDrivers, removeDriver }) => {
  const [drivers, setDrivers] = useState(null)
  const [suggestions, setSuggestions] = useState(null)

  const handleSuggestions = inputValue => {
    const newSuggestions = drivers.filter(driver => driver.firstName.toLowerCase().includes(inputValue.toLowerCase()))

    setSuggestions(newSuggestions)
  }

  const getDrivers = useCallback(async () => setDrivers(await requestDrivers()), [requestDrivers])

  useEffect(() => {
    getDrivers()
  }, [getDrivers])

  return (
    <>
      <NavigationBar />
      <DriverTable drivers={suggestions || drivers} handleSuggestions={handleSuggestions} onRemoveDriver={removeDriver} refresDrivers={getDrivers} />
    </>
  )
}

export default DriverList
