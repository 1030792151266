// @vendors
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import { Link, Redirect } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import FormControl from '@material-ui/core/FormControl'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'

// @components
import CardForm from '../../../common-components/card-form'

// @utils
import { getValues } from '../../../utils'
import httpConfig from '../../../http/config'
import useMount from '../../../hooks/use-mount'

// @styles
import styles from './styles.module.scss'

const { apiServer } = httpConfig

const getCountryCode = () =>
  axios.get(`${apiServer.url}/api/Settings/countryCodes`).then(res =>
    res.data.map(countryCode => ({
      code: countryCode.code,
      label: countryCode.dial_code,
      countryName: countryCode.name
    }))
  )

const CarrierSignUp = ({ createCarrier, carrier, getJwt, resetCarrier, carrierCreated, inviteToken, email, clientId }) => {
  const formFields = [
    { key: 'carrierCode', defaultValue: '' },
    { key: 'carrierName', defaultValue: '' },
    { key: 'countryCode', defaultValue: '' },
    { key: 'email', defaultValue: email || '' },
    { key: 'phoneNumber', defaultValue: '' },
    { key: 'password', defaultValue: '' },
    { key: 'confirmPassword', defaultValue: '' }
  ]
  const [state, setState] = useState(() => {
    const fields = {}

    formFields.forEach(field => {
      fields[field.key] = {
        value: field.defaultValue,
        valid: !!field.defaultValue,
        pristine: !field.defaultValue
      }
    })

    return {
      ...fields,
      form: { valid: false }
    }
  })

  const [countryCode, setCountryCode] = useState({
    data: [],
    label: 'Country Code',
    placeholder: 'Select Country Code'
  })

  useEffect(() => {
    if (carrierCreated && carrier) {
      getJwt(carrier.email, carrier.password)
      resetCarrier()
    }
  }, [carrierCreated, carrier, getJwt, resetCarrier])

  const showErrors = () => {
    const fields = {}

    formFields.forEach(field => {
      fields[field.key] = { ...state[field.key], pristine: false }
    })

    setState({
      ...state,
      ...fields
    })
  }

  const onShowPassword = name => () => {
    setState({ ...state, [name]: { ...state[name], show: !state[name].show } })
  }

  const handleOnMouseDownPassword = event => {
    event.preventDefault()
  }

  const [helperText, setHelperText] = useState({
    email: null,
    confirmPassword: null
  })

  const handleChangePassword = event => {
    const { target } = event
    const { form, name, value } = target
    let valid = event.target.checkValidity()

    if (name === 'password') {
      const confirmPasswordControl = document.querySelector('#confirmPassword')

      if (value && state.confirmPassword.value && value !== state.confirmPassword.value) {
        confirmPasswordControl.setCustomValidity("Password doesn't match.")
        setHelperText({ ...helperText, confirmPassword: confirmPasswordControl.validationMessage })

        return setState({
          ...state,
          [name]: { ...state[name], value, valid, pristine: false },
          confirmPassword: { value: state.confirmPassword.value, valid: false, pristine: false },
          form: { valid: false }
        })
      } else if (value && state.confirmPassword.value && value === state.confirmPassword.value) {
        confirmPasswordControl.setCustomValidity('')
        setHelperText({ ...helperText, confirmPassword: null })

        return setState({
          ...state,
          [name]: { ...state[name], value, valid, pristine: false },
          confirmPassword: { value: state.confirmPassword.value, valid: true, pristine: false },
          form: { valid: form.checkValidity() }
        })
      }
    } else if (name === 'confirmPassword') {
      if (value !== state.password.value) {
        valid = false

        event.target.setCustomValidity("Password doesn't match.")
        setHelperText({ ...helperText, [name]: event.target.validationMessage })
      } else {
        valid = true
        event.target.setCustomValidity('')
        setHelperText({ ...helperText, [name]: null })
      }
    }

    setState({
      ...state,
      [name]: { ...state[name], value, valid, pristine: false },
      form: { valid: form.checkValidity() }
    })
  }

  const handleOnChange = event => {
    const {
      target: { form, name, value: controlValue }
    } = event

    let valid = event.target.checkValidity()
    let value = controlValue

    if (name === 'carrierName') {
      value = value.replace(/\d+/g, '')
    } else if (name === 'phoneNumber') {
      value = value.replace(/[^\w\s]|[a-zA-Z]|\_/gi, '')
    } else if (name === 'email') {
      const emailFormat = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
      valid = emailFormat.test(value)

      if (!valid) {
        const ht = {
          email: 'Email format is incorrect'
        }

        event.target.setCustomValidity(ht[name])
        setHelperText({ ...helperText, [name]: event.target.validationMessage })
      } else {
        event.target.setCustomValidity('')
        setHelperText({ ...helperText, [name]: null })
      }
    }

    setState({
      ...state,
      [name]: { ...state[name], value, valid, pristine: false },
      form: { valid: form.checkValidity() }
    })
  }

  const handleCountryCode = event => {
    setState({
      ...state,
      countryCode: {
        ...state.countryCode,
        value: event.target.value,
        valid: true,
        pristine: false
      }
    })
  }

  useMount(() => {
    getCountryCode().then(countryCode => {
      setCountryCode({
        data: countryCode,
        label: 'Country Code',
        placeholder: 'Select Country Code'
      })
    })
  })

  const handleOnSubmit = event => {
    event.preventDefault()

    if (!state.form.valid) {
      return showErrors()
    }

    const values = getValues(state)

    if (inviteToken) {
      values.token = inviteToken
      values.clientId = clientId
    }

    createCarrier(values)
  }

  const findeDefaultValue = countryCode.data.find(country => country.code && country.code === 'US')
  const countryCodeValue =
    !state.countryCode.value && findeDefaultValue && findeDefaultValue.code
      ? setState({
          ...state,
          ...state.countryCode,
          countryCode: { value: findeDefaultValue.code }
        })
      : state.countryCode.value

  return (
    ((carrierCreated || !inviteToken) && <Redirect to="/" />) || (
      <CardForm header="Create your account" title="Personal info" classes={{ avatar: styles.avatar }}>
        <form noValidate autoComplete="off" className={styles.form} onSubmit={handleOnSubmit}>
          <TextField
            required
            error={!state.carrierCode.valid && !state.carrierCode.pristine}
            name="carrierCode"
            label="Code"
            className={styles.textField}
            value={state.carrierCode.value}
            onChange={handleOnChange}
            margin="normal"
          />
          <TextField
            required
            error={!state.carrierName.valid && !state.carrierName.pristine}
            name="carrierName"
            label="Name"
            className={styles.textField}
            value={state.carrierName.value}
            onChange={handleOnChange}
            margin="normal"
          />
          <TextField
            required
            error={!state.email.valid && !state.email.pristine}
            name="email"
            label="E-mail"
            className={styles.fullWidth}
            value={state.email.value}
            onChange={handleOnChange}
            helperText={helperText.email}
            disabled={!!email}
            margin="normal"
          />
          <FormControl className={styles.phoneData}>
            <InputLabel name="license-state-label">Country Code*</InputLabel>
            <Select
              name="country-code"
              className={styles.countryCode}
              label={countryCode.label}
              placeholder={countryCode.placeholder}
              value={countryCodeValue}
              renderValue={value => value}
              onChange={handleCountryCode}
            >
              {countryCode.data.map((country, index) => (
                <MenuItem className={styles.countryCodeOptions} key={`${country.label}-${index}`} value={country.code}>
                  <span className={styles.countryCodeValue}>{country.label}</span>
                  <span className={styles.countryCodeCountry}>{`(${country.code}) - ${country.countryName}`}</span>
                </MenuItem>
              ))}
            </Select>
            <TextField
              required
              id
              error={!state.phoneNumber.valid && !state.phoneNumber.pristine}
              name="phoneNumber"
              label="Phone number"
              className={styles.phoneNumber}
              value={state.phoneNumber.value}
              onChange={handleOnChange}
              type="text"
            />
          </FormControl>
          <TextField
            required
            error={!state.password.valid && !state.password.pristine}
            name="password"
            label="Password"
            className={styles.fullWidth}
            value={state.password.value}
            onChange={handleChangePassword}
            type={(state.password.show && 'text') || 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={onShowPassword('password')} onMouseDown={handleOnMouseDownPassword}>
                    {(state.password.show && <Visibility />) || <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            required
            error={!state.confirmPassword.valid && !state.confirmPassword.pristine}
            name="confirmPassword"
            id="confirmPassword"
            label="Confirm Password"
            className={styles.fullWidth}
            value={state.confirmPassword.value}
            onChange={handleChangePassword}
            type={(state.confirmPassword.show && 'text') || 'password'}
            disabled={!state.password.valid}
            helperText={helperText.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onShowPassword('confirmPassword')}
                    onMouseDown={handleOnMouseDownPassword}
                  >
                    {(state.confirmPassword.show && <Visibility />) || <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <div className={styles.buttonWrapper}>
            <Button variant="contained" className={styles.button} color="primary" size="large" type="submit">
              SIGN UP
            </Button>
            <Typography variant="caption">
              <Link to="/">Take me to Login</Link>
            </Typography>
          </div>
        </form>
      </CardForm>
    )
  )
}

export default CarrierSignUp
