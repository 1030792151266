export const RESET_PASSWORD_INITIAL_STATE = {
  confirmPassword: {
    value: '',
    valid: false,
    pristine: true
  },
  password: {
    value: '',
    valid: false,
    pristine: true
  },
  showConfirmPassword: false,
  showPassword: false,
  regexUppercaseLetterValid: true,
  regexLowercaseLetterValid: true,
  regexNumberValid: true,
  regexSpecialCharacterValid: true,
  regexLengthValid: true,
  form: { valid: false }
}
