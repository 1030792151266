// @vendors
import axios from 'axios'

// @constants
import * as constants from './constant'

// @utils
import httpConfig from './config'
import { standardRequestHandler, standardSuccessHandler, standardErrorHandler } from './utils'

export const requestClientData = () => dispatch => {
  const { apiServer } = httpConfig

  const params = {
    url: window.location.origin
  }

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json'
    },
    params
  }

  standardRequestHandler(dispatch, constants.GET_CLIENT_DATA_REQUEST)

  return axios
    .get(`${apiServer.url}/api/Clients/resolve`, config)
    .then(res => standardSuccessHandler(dispatch, constants.GET_CLIENT_DATA_SUCCESS, res.data))
    .catch(err => standardErrorHandler(dispatch, err, constants.GET_CLIENT_DATA_ERROR, constants.GET_CLIENT_ERROR_MESSAGE))
}
