// @vendors
import React from 'react'

// @components
import DownshiftMultiple from './downshift-multiple-select'

// @styles
import styles from '../styles.module.sass'

const InputMultiSelect = ({ control, isDisabled, onHandleChange, currentValue = [] }) => (
  <DownshiftMultiple classes={styles} control={control} isDisabled={isDisabled} onHandleChange={onHandleChange} currentValue={currentValue} />
)

export default InputMultiSelect
